var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "component-upload-image" },
    [
      _c(
        "el-upload",
        {
          staticStyle: { display: "inline-block", "vertical-align": "top" },
          attrs: {
            action: _vm.uploadImgUrl,
            "list-type": "picture-card",
            "on-success": _vm.handleUploadSuccess,
            "before-upload": _vm.handleBeforeUpload,
            "on-error": _vm.handleUploadError,
            name: "file",
            "show-file-list": false,
            headers: _vm.headers,
          },
        },
        [
          !_vm.value
            ? _c("el-image", { attrs: { src: _vm.value } }, [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
              ])
            : _c(
                "div",
                { staticClass: "image" },
                [
                  _c("el-image", {
                    style: `width:150px;height:150px;`,
                    attrs: { src: _vm.value, fit: "fill" },
                  }),
                  _c("div", { staticClass: "mask" }, [
                    _c("div", { staticClass: "actions" }, [
                      _c(
                        "span",
                        {
                          attrs: { title: "预览" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.dialogVisible = true
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-zoom-in" })]
                      ),
                      _c(
                        "span",
                        {
                          attrs: { title: "移除" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.removeImage.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "预览",
            width: "800",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              display: "block",
              "max-width": "100%",
              margin: "0 auto",
            },
            attrs: { src: _vm.value },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }