<template>
    <div>
        <el-upload
            v-loading="loading"
            v-bind="{...item}"
            :action="item.action"
            :before-upload="beforeUpload"
            :file-list="changeValue"
            :on-progress="handleProgress"
            :on-exceed="handleExceed"
            :on-success="uploadSuccess"
            list-type="picture-card"
        >
            <i slot="default" class="el-icon-plus" />
            <div slot="file" slot-scope="{file}">
                <el-image
                    :preview-src-list="[file.url]"
                    :src="file.url"
                    class="el-upload-list__item-thumbnail"
                />
                <div class="actions">
                    <span
                        v-if="!$parent.form.disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                    >
                        <i class="el-icon-delete" />
                    </span>
                </div>
            </div>
        </el-upload>
    </div>
</template>

<script>
import mixin from '../mixin'
import uploadMixin from '../uploadMixin'
import _ from 'lodash'
import {jsonSimpleClone} from '../../../utils'

export default {
    name: 'TImageUpload',
    mixins: [mixin, uploadMixin],
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            loading: false
        }
    },
    methods: {
        // 上传成功
        uploadSuccess(response, file, fileList) {
            let oldFileList = jsonSimpleClone(this.changeValue)
            if (!oldFileList) {
                oldFileList = []
            }
            oldFileList.push({
                name: file.name,
                url: response.data
            })
            this.changeValue = oldFileList
            this.loading = false
        },
        handleError(err, file, fileList) {
            console.log(err)
        },
        handleProgress() {
            this.loading = true
        },
        handleExceed() {
            this.$message.error(`最多上传${this.item.limit}个文件`)
        },
        handleRemove(file) {
            console.log(file)
            let tempList = jsonSimpleClone(this.changeValue)
            _.remove(tempList, function(item) {
                return item.url === file.url
            })
            console.log(tempList)
            this.changeValue = tempList
        }
    }
}
</script>

<style lang="scss" scoped>
.el-upload-list__item {
  position: relative;
}

.actions {
  position: absolute;
  height: 20px;
  width: 100%;
  bottom: 10px;

  .el-upload-list__item-delete {
    display: inline-block;
    font-size: 17px;
  }
}

// 避免上传文件后出现抖动
::v-deep .el-upload-list__item.is-ready,
::v-deep .el-upload-list__item.is-uploading {
  display: none !important;
}
</style>
