var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-index-container" },
    [
      _c(
        "el-card",
        { staticClass: "header-container" },
        [
          _c(
            "el-row",
            {
              staticStyle: { height: "50px" },
              attrs: { align: "middle", type: "flex", gutter: 5 },
            },
            [
              _c("i", {
                staticClass: "el-icon-back",
                on: {
                  click: function ($event) {
                    return _vm.$router.back(-1)
                  },
                },
              }),
              _c("img", {
                staticClass: "header-logo",
                attrs: { src: require("@/assets/images/logo.png") },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ path: "/home" })
                  },
                },
              }),
              _c("el-col"),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-view" },
                  on: {
                    click: function ($event) {
                      _vm.previewDialogVisible = true
                    },
                  },
                },
                [_vm._v(" 预览 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", icon: "el-icon-folder-add" },
                  on: { click: _vm.saveProjectAsTemplateHandle },
                },
                [_vm._v(" 保存至模板中心 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "main-container" }, [
        _c(
          "div",
          { staticClass: "left-menu-container" },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-vertical",
                attrs: {
                  collapse: _vm.isCollapse,
                  "default-active": _vm.defaultActiveMenu,
                },
                on: { select: _vm.menuSelectHandle },
              },
              _vm._l(_vm.menuItemList, function (menuItem) {
                return _c(
                  "el-menu-item",
                  { key: menuItem.route, attrs: { index: menuItem.route } },
                  [
                    _c("i", { class: menuItem.icon }),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(menuItem.title)),
                    ]),
                  ]
                )
              }),
              1
            ),
            !_vm.isCollapse
              ? _c("i", {
                  staticClass: "el-icon-d-arrow-left",
                  on: { click: _vm.collapseHandle },
                })
              : _c("i", {
                  staticClass: "el-icon-d-arrow-right",
                  on: { click: _vm.collapseHandle },
                }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-content-container" },
          [_c("router-view")],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.previewDialogVisible,
            "destroy-on-close": "",
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewDialogVisible = $event
            },
          },
        },
        [
          _c("pre-view", {
            key: _vm.previewKey,
            attrs: { "preview-qrcode": true },
          }),
        ],
        1
      ),
      _c("template-create", {
        ref: "templateCreate",
        attrs: { "form-key": _vm.projectKey },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }