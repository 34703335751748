var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "horizontal-input" },
    _vm._l(_vm.inputArray, function (item, index) {
      return _c(
        "span",
        { key: index },
        [
          _vm._v(" " + _vm._s(item.text) + " "),
          item.haveInput
            ? _c("el-input", {
                staticStyle: { width: "150px" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.value[item.index],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.index, $$v)
                  },
                  expression: "value[item.index]",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }