var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rc-bin-container" },
    [
      _vm.projectList && _vm.projectList.length > 0
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.projectList,
                border: "",
                "empty-text": "暂无数据",
                "highlight-current-row": "",
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "标题",
                  prop: "name",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "收集数",
                  prop: "resultCount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "删除时间",
                  prop: "updateTime",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.restoreProject(scope.row.key, 1)
                                },
                              },
                            },
                            [_vm._v(" 恢复 ")]
                          ),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "确定删除该项目吗？" },
                              on: {
                                confirm: function ($event) {
                                  return _vm.deleteProject(scope.row.key)
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "pink-text-btn",
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2361917891
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "project-page-view" },
        [
          _vm.total > 10
            ? _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryParams.current,
                  "page-size": _vm.queryParams.size,
                  total: _vm.total,
                  background: "",
                  layout: "total, prev, pager, next",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryParams, "current", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryParams, "current", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryParams, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryParams, "size", $event)
                  },
                  "current-change": _vm.queryRecycleProjectPage,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.projectList || _vm.projectList.length == 0
        ? _c("el-empty", {
            attrs: { slot: "empty", description: "暂无数据" },
            slot: "empty",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }