var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.config.tag === "el-select"
        ? _c(
            "el-form-item",
            { attrs: { label: "能否搜索" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.localActiveData.filterable,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "filterable", $$v)
                  },
                  expression: "localActiveData.filterable",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.config.tag === "el-select"
        ? _c(
            "el-form-item",
            { attrs: { label: "是否多选" } },
            [
              _c("el-switch", {
                on: { change: _vm.multipleChange },
                model: {
                  value: _vm.localActiveData.multiple,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "multiple", $$v)
                  },
                  expression: "localActiveData.multiple",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("config-item-option", {
        attrs: { "active-data": _vm.localActiveData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }