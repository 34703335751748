<template>
    <div>
        <el-form-item v-if="localActiveData.accept !== undefined" label="文件类型">
            <el-select
                v-model="localActiveData.accept"
                :style="{ width: '100%' }"
                clearable
                placeholder="请选择文件类型"
            >
                <el-option label="图片" value="image/*" />
                <el-option label="视频" value="video/*" />
                <el-option label="音频" value="audio/*" />
                <el-option label="excel" value=".xls,.xlsx" />
                <el-option label="word" value=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                <el-option label="pdf" value=".pdf" />
                <el-option label="txt" value=".txt" />
            </el-select>
        </el-form-item>
        <el-form-item
            v-if="localActiveData.config.fileSize !== undefined"
            label="文件大小"
        >
            <el-input
                v-model.number="localActiveData.config.fileSize"
                placeholder="请输入文件大小"
            >
                <el-select
                    slot="append"
                    v-model="localActiveData.config.sizeUnit"
                    :style="{ width: '66px' }"
                >
                    <el-option label="KB" value="KB" />
                    <el-option label="MB" value="MB" />
                    <el-option label="GB" value="GB" />
                </el-select>
            </el-input>
        </el-form-item>
        <el-form-item
            v-if="localActiveData['list-type'] !== undefined"
            label="列表类型"
        >
            <el-radio-group v-model="localActiveData['list-type']" size="small">
                <el-radio-button label="text">
                    text
                </el-radio-button>
                <el-radio-button label="picture">
                    picture
                </el-radio-button>
                <el-radio-button label="picture-card">
                    picture-card
                </el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item
            v-if="localActiveData.config.showTip !== undefined"
            label="显示提示"
        >
            <el-switch v-model="localActiveData.config.showTip" />
        </el-form-item>
        <el-form-item
            label="多选文件"
        >
            <el-switch v-model="localActiveData.multiple" />
        </el-form-item>
        <el-form-item
            label="文件个数"
        >
            <el-input-number v-model="localActiveData.limit" />
        </el-form-item>
    </div>
</template>

<script>

export default {
    name: 'ConfigItemUpload',
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style scoped>

</style>
