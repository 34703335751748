<template>
    <el-input-number
        v-model="changeValue"
        v-bind="{...item}"
        @change="handleChange"
    />
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TNumber',
    mixins: [mixin],
    data() {
        return {
            oldValue: null
        }
    },
    methods: {
        handleChange(val) {

        }
    }
}
</script>

<style scoped>

</style>
