var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-setting-view" },
    [
      _c("p", { staticClass: "project-setting-title" }, [_vm._v(" 分享设置 ")]),
      _c(
        "el-form",
        { ref: "shareSettingForm", attrs: { model: _vm.shareSettingForm } },
        [
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 自定义分享图标 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.shareSettingForm.shareImg,
                  callback: function ($$v) {
                    _vm.$set(_vm.shareSettingForm, "shareImg", $$v)
                  },
                  expression: "shareSettingForm.shareImg",
                },
              }),
            ],
            1
          ),
          _vm.shareSettingForm.shareImg
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "project-setting-label" }, [
                    _vm._v(" 请上传分享图片 * 建议尺寸500px*500px "),
                  ]),
                  _c(
                    "el-upload",
                    {
                      ref: "logoUpload",
                      attrs: {
                        action: _vm.getUploadUrl,
                        headers: _vm.getUploadHeader,
                        "on-success": _vm.uploadShareImgHandle,
                        "show-file-list": false,
                        accept:
                          ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "block" },
                        [
                          _c(
                            "el-image",
                            {
                              staticClass: "share-img",
                              attrs: {
                                src: _vm.shareSettingForm.shareImgUrl,
                                fit: "cover ",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 自定义分享标题 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.shareSettingForm.shareTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.shareSettingForm, "shareTitle", $$v)
                  },
                  expression: "shareSettingForm.shareTitle",
                },
              }),
            ],
            1
          ),
          _vm.shareSettingForm.shareTitle
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    rules: [
                      {
                        required: true,
                        message: "请输入标题",
                        trigger: "blur",
                      },
                    ],
                    prop: "shareTitleContent",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.shareSettingForm.shareTitleContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.shareSettingForm, "shareTitleContent", $$v)
                      },
                      expression: "shareSettingForm.shareTitleContent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 自定义分享描述 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.shareSettingForm.shareDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.shareSettingForm, "shareDesc", $$v)
                  },
                  expression: "shareSettingForm.shareDesc",
                },
              }),
            ],
            1
          ),
          _vm.shareSettingForm.shareDesc
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    rules: [
                      {
                        required: true,
                        message: "请输入标题",
                        trigger: "blur",
                      },
                    ],
                    prop: "shareDescContent",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入描述" },
                    model: {
                      value: _vm.shareSettingForm.shareDescContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.shareSettingForm, "shareDescContent", $$v)
                      },
                      expression: "shareSettingForm.shareDescContent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "submit-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveSettingHandle },
                },
                [_vm._v(" 保存设置 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.shareSettingForm.shareDesc ||
      _vm.shareSettingForm.shareTitle ||
      _vm.shareSettingForm.shareImg
        ? _c("div", [
            _c("div", { staticClass: "share-preview" }, [
              _c("div", { staticClass: "share-preview-msg" }, [
                _c("div", [
                  _c("p", { staticClass: "share-preview-msg-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.shareSettingForm.shareTitleContent) + " "
                    ),
                  ]),
                  _c("p", { staticClass: "share-preview-msg-desc" }, [
                    _vm._v(
                      " " + _vm._s(_vm.shareSettingForm.shareDescContent) + " "
                    ),
                  ]),
                ]),
                _c("div", [
                  _vm.shareSettingForm.shareImgUrl
                    ? _c("img", {
                        staticClass: "share-preview-img",
                        attrs: { src: _vm.shareSettingForm.shareImgUrl },
                      })
                    : _vm._e(),
                ]),
              ]),
              _c("div"),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }