var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.startParser && _vm.flag
    ? _c("generate-form", {
        attrs: {
          "question-mode": false,
          "form-conf": _vm.formConf,
          "b-form-model": _vm.formModel,
        },
        on: { changeForm: _vm.changeForm, submit: _vm.submitForm },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }