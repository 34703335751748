/**
 * 全局组件自动注册
 *
 * 全局组件各个组件按文件夹区分，文件夹名称与组件名无关联，但建议与组件名保持一致
 * 自动导入 目录下 index mobile组件
 * 不自动注册 则名字为其他的
 */

import Vue from 'vue'

const componentsContext = require.context('./', true, /(index|mobile).(vue|js)$/)
componentsContext.keys().forEach(fileName => {
    // 获取文件中的 default 模块
    const componentConfig = componentsContext(fileName).default
    if (/.vue$/.test(fileName)) {
        Vue.component(componentConfig.name, componentConfig)
    } else if (/.js/.test(fileName)) {
        Vue.use(componentConfig)
    }
})
