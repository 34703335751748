var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-container" }, [
    _c("div", [
      !_vm.publishStatus
        ? _c(
            "div",
            { staticClass: "publish-btn-view" },
            [
              _c(
                "el-button",
                {
                  staticClass: "publish-btn",
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.publishProject },
                },
                [
                  _c(
                    "i",
                    { staticClass: "el-icon-document-checked el-icon--right" },
                    [_vm._v("开始发布")]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.publishStatus
        ? _c(
            "div",
            { staticClass: "publish-finish-view" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10, align: "middle", type: "flex" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      [
                        _vm.writeLink
                          ? _c("vue-qr", {
                              attrs: {
                                callback: _vm.qrCodeGenSuccess,
                                size: 194,
                                text: _vm.writeLink,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: () => {
                                this.downloadFile("qrcode.png", this.qrCodeUrl)
                              },
                            },
                          },
                          [_vm._v(" 下载分享二维码 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "75px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("div", { staticClass: "icon-view" }, [
                            _c("i", {
                              staticClass: "el-icon-check success-icon",
                            }),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c("p", { staticClass: "success-title" }, [
                          _vm._v("恭喜您，发布成功！"),
                        ]),
                      ]),
                      _c("div", [
                        _c("p", { staticClass: "link-text" }, [
                          _vm._v(" " + _vm._s(_vm.writeLink)),
                        ]),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { offset: 3, span: 6 } },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.writeLink,
                                      expression: "writeLink",
                                      arg: "copy",
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:error",
                                      value: () => {
                                        this.msgError("复制失败")
                                      },
                                      expression:
                                        "()=>{this.msgError('复制失败')}",
                                      arg: "error",
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: () => {
                                        this.msgSuccess("复制成功")
                                      },
                                      expression:
                                        "()=>{this.msgSuccess('复制成功')}",
                                      arg: "success",
                                    },
                                  ],
                                  attrs: { type: "primary" },
                                },
                                [_vm._v(" 复制链接 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "danger" },
                                  on: { click: _vm.stopPublishProject },
                                },
                                [_vm._v(" 停止发布 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "warning" },
                                  on: { click: _vm.toFeedbackPageHandle },
                                },
                                [_vm._v(" 查看反馈 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }