<template>
    <div>
        <el-input v-model="showValue" readonly @click.native="dialogVisible=true" />
        <el-dialog
            :visible.sync="dialogVisible"
            title="部门选择"
            width="30%"
            append-to-body
        >
            <el-tree ref="deptTree" :data="data" :props="defaultProps" default-expand-all show-checkbox />
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import {getDeptTreeRequest} from '../../../api/indexForm'
import _ from 'lodash'
import mixin from '../mixin'

export default {
    name: 'TDeptSelect',
    mixins: [mixin],
    data() {
        return {
            data: [],
            showValue: '',
            dialogVisible: false,
            defaultProps: {
                children: 'children',
                label: 'name',
                disabled: this.isDisabled
            }
        }
    },
    created() {
        getDeptTreeRequest().then(res => {
            this.data = res.data
        })
        this.showValue = this.value.map(item => item.name)
    },
    methods: {
        isDisabled(data, node) {
            return false
        },
        handleSubmit() {
            this.changeValue = this.$refs.deptTree.getCheckedNodes(true).map(item => {
                return {name: item.name, id: item.id}
            })
            this.dialogVisible = false
            if (this.changeValue) {
                this.showValue = this.changeValue.map(item => item.name)
            }
        }
    }
}
</script>

<style scoped>

</style>
