var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.table.rows
        ? _c(
            "div",
            [
              _c("el-divider", [_vm._v("题目")]),
              _c(
                "draggable",
                {
                  attrs: {
                    animation: 340,
                    list: _vm.localActiveData.table.rows,
                    group: "selectItem",
                    handle: ".option-drag",
                  },
                },
                _vm._l(_vm.localActiveData.table.rows, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "select-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "select-line-icon option-drag" },
                        [_c("i", { staticClass: "el-icon-s-operation" })]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "选项名", size: "small" },
                        model: {
                          value: item.label,
                          callback: function ($$v) {
                            _vm.$set(item, "label", $$v)
                          },
                          expression: "item.label",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "close-btn select-line-icon",
                          on: {
                            click: function ($event) {
                              return _vm.localActiveData.table.rows.splice(
                                index,
                                1
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-remove-outline" })]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addSelectItem(
                            _vm.localActiveData.table.rows
                          )
                        },
                      },
                    },
                    [_vm._v(" 添加选项 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: { icon: "el-icon-document-copy", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.openBatchEditDialogHandle("rows")
                        },
                      },
                    },
                    [_vm._v(" 批量编辑 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.table.columns
        ? _c(
            "div",
            [
              _c("el-divider", [_vm._v("选项")]),
              _c(
                "draggable",
                {
                  attrs: {
                    animation: 340,
                    list: _vm.localActiveData.table.columns,
                    group: "selectItem",
                    handle: ".option-drag",
                  },
                },
                _vm._l(
                  _vm.localActiveData.table.columns,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "select-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "select-line-icon option-drag" },
                          [_c("i", { staticClass: "el-icon-s-operation" })]
                        ),
                        _c("el-input", {
                          attrs: { placeholder: "选项名", size: "small" },
                          model: {
                            value: item.label,
                            callback: function ($$v) {
                              _vm.$set(item, "label", $$v)
                            },
                            expression: "item.label",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "close-btn select-line-icon",
                            on: {
                              click: function ($event) {
                                return _vm.localActiveData.table.columns.splice(
                                  index,
                                  1
                                )
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-remove-outline" })]
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addSelectItem(
                            _vm.localActiveData.table.columns
                          )
                        },
                      },
                    },
                    [_vm._v(" 添加选项 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: { icon: "el-icon-document-copy", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.openBatchEditDialogHandle("columns")
                        },
                      },
                    },
                    [_vm._v(" 批量编辑 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量编辑",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", { staticClass: "desc-text" }, [
            _vm._v("每行对应一个选项"),
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 20, maxRows: 5000 },
              placeholder: "选项1 选项2  选项3 ",
            },
            model: {
              value: _vm.batchItemValue,
              callback: function ($$v) {
                _vm.batchItemValue = $$v
              },
              expression: "batchItemValue",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.batchEditSelectItems },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }