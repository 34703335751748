var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      attrs: {
        label: _vm.config.showLabel ? _vm.config.label : "",
        "label-width": _vm.labelWidth,
        required: _vm.config.required,
      },
    },
    [
      _vm.componentList.includes(_vm.currentItem.typeId)
        ? [
            _c(_vm.currentItem.config.tag, {
              tag: "component",
              attrs: { item: _vm.currentItem },
              on: {
                input: (val) => {
                  _vm.$set(_vm.config, "defaultValue", val)
                },
              },
              model: {
                value: _vm.currentItem.config.defaultValue,
                callback: function ($$v) {
                  _vm.$set(_vm.currentItem.config, "defaultValue", $$v)
                },
                expression: "currentItem.config.defaultValue",
              },
            }),
          ]
        : [
            _c(
              _vm.currentItem.config.tag,
              _vm._b(
                {
                  tag: "component",
                  on: {
                    input: (val) => {
                      _vm.$set(_vm.config, "defaultValue", val)
                    },
                  },
                  model: {
                    value: _vm.currentItem.config.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentItem.config, "defaultValue", $$v)
                    },
                    expression: "currentItem.config.defaultValue",
                  },
                },
                "component",
                { ..._vm.currentItem },
                false
              )
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }