<template>
    <div>
        <el-form-item
            label="是否多选"
        >
            <el-switch v-model="localActiveData.multiple" @change="handleChange" />
        </el-form-item>
        <matrix-option :active-data="localActiveData" />
    </div>
</template>
<script>
import MatrixOption from './matrix'

export default {
    name: 'ConfigItemMatrixSelect',
    components: {
        MatrixOption
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    },
    methods: {
        handleChange() {
            this.$set(this.localActiveData.config, 'defaultValue', {})
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
