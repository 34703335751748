// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/appearset_bgc_big.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".preview-container[data-v-74e7a95a] {\n  margin: 0;\n  padding-top: 30px;\n  height: 100vh;\n  overflow: hidden !important;\n  background-color: var(--color-bg);\n}\n[data-v-74e7a95a] .el-tabs--card > .el-tabs__header .el-tabs__nav {\n  border: 1px solid #E4E7ED !important;\n}\n[data-v-74e7a95a] .el-tabs__header {\n  width: 300px;\n  margin: 0 auto;\n  border: none;\n}\n[data-v-74e7a95a] .el-dialog__body {\n  max-height: calc(100vh - 200px) !important;\n}\n[data-v-74e7a95a] .el-tabs--card > .el-tabs__header .el-tabs__item {\n  background-color: white;\n  border: 1px solid white;\n}\n[data-v-74e7a95a] .project-form {\n  margin: 40px auto 0px;\n}\ndiv.preview-layer[data-v-74e7a95a] {\n  width: 500px;\n  height: 100%;\n  margin: 10px auto;\n  right: 0;\n  text-align: center;\n}\ndiv.preview-layer .preview-bg[data-v-74e7a95a] {\n  width: 500px;\n  height: 100%;\n  margin: 20px auto;\n  z-index: 999;\n  opacity: 0.7;\n}\ndiv.preview-layer .preview-phone[data-v-74e7a95a] {\n  width: 372px;\n  height: 744px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 372px 744px;\n  z-index: 1000;\n}\n.qrcode-view[data-v-74e7a95a] {\n  position: absolute;\n  top: 20px;\n  right: 260px;\n}\n.qrcode-view p[data-v-74e7a95a] {\n  text-align: center;\n  font-size: 12px;\n  color: #303133;\n}\n.preview-html[data-v-74e7a95a] {\n  width: 345px !important;\n  height: 568px !important;\n  margin: 74px 0 0;\n  border-radius: 5px;\n  outline: none;\n  background-color: #fff;\n  border-width: 2px;\n  border-style: inset;\n  border-color: initial;\n  -o-border-image: initial;\n     border-image: initial;\n  border-top-width: 0;\n  border-right-width: 0;\n  border-bottom-width: 0;\n  border-left-width: 0;\n}", ""]);
// Exports
module.exports = exports;
