var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "el-tabs",
        { staticClass: "setting-tabs", attrs: { "tab-position": "left" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "提交设置" } },
            [_c("submit-setting")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "回收设置" } },
            [_c("write-setting")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "通知设置" } },
            [_c("notify-setting")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "分享设置" } },
            [_c("share-setting")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }