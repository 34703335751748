<template>
    <div>
        <draggable
            :animation="340"
            :list="localActiveData.sortList"
            group="selectItem"
            handle=".option-drag"
        >
            <div
                v-for="(item, index) in localActiveData.sortList"
                :key="index"
                class="select-item"
            >
                <div class="select-line-icon option-drag">
                    <i class="el-icon-s-operation" />
                </div>
                <el-input
                    v-model="item.label"
                    placeholder="选项名"
                    size="small"
                />
                <div
                    class="close-btn select-line-icon"
                    @click="localActiveData.sortList.splice(index, 1)"
                >
                    <i class="el-icon-remove-outline" />
                </div>
            </div>
        </draggable>
        <div style="margin-left: 20px;margin-bottom: 10px">
            <el-button
                icon="el-icon-circle-plus-outline"
                style="padding-bottom: 0"
                type="text"
                @click="addSelectItem"
            >
                添加选项
            </el-button>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'

export default {
    name: 'ConfigItemSort',
    components: {
        draggable
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    },
    methods: {
        addSelectItem() {
            const lastItem = _.last(this.localActiveData.sortList)
            const value = lastItem.value === 0 ? _.max(_.values(this.localActiveData.sortList.map(item => item.value))) + 1 : lastItem.value + 1
            this.localActiveData.sortList.push({
                label: '',
                value: lastItem ? value : 1
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  align-items: center;

  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }

  & .el-input + .el-input {
    margin-left: 4px;
  }
}

.select-item + .select-item {
  margin-top: 4px;
}

.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}

.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}

.option-drag {
  cursor: move;
}
</style>
