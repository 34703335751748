import axios from 'axios'
import { Message, MessageBox } from 'element-ui'

const request = axios.create({
    timeout: 30000,
    responseType: 'json',
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json'
    }
})

// request 拦截器
request.interceptors.request.use(config => {
    const { headers } = config
    // 动态baseURL
    const baseUrl = getBaseUrl()
    config.url = baseUrl + config.url
    // 获取并设置token
    const token = localStorage.getItem(ACCESS_TOKEN) || TEST_ACCESS_TOKEN
    if (token) headers[localStorage.getItem(ACCESS_TOKEN_NAME) || DEFAULT_ACCESS_TOKEN_NAME] = token
    return config
}, error => Promise.reject(error))

// response 拦截器
// eslint-disable-next-line consistent-return
request.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    const msg = res.data.msg || 'error'
    // 获取错误信息
    if (code === 401) {
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {
                location.href = '/index'
            })
            .catch(() => {
            })
    } else if (code === 500) {
        Message({
            message: msg,
            type: 'error'
        })
        return Promise.reject(new Error(msg))
    }
    return Promise.resolve(res.data)
}, error => {
    console.log(error)
    Promise.reject(error)
})

const BASE_URL = 'BASE_URL'
const ACCESS_TOKEN = 'ACCESS_TOKEN'
const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN_NAME'

const TEST_ACCESS_TOKEN = process.env.NODE_ENV === 'development' ? 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImRjMGE4Nzg3LTIwMzAtNDE4ZC1iMzBhLWU3MmQ3ZTQ0MGQ4NiJ9.355nDN-8Xwbw8pZy4eTD3Bd7gunXeXXLnxmY4DYuBhhn3PGD0IFg_QbNMgOErlseuiWhONYXDXrtsvmYs1PKfA\n' : null
const DEFAULT_ACCESS_TOKEN_NAME = process.env.NODE_ENV === 'development' ? 'Authorization' : null

function getBaseUrl() {
    return process.env.VUE_APP_API_ROOT || '/tduck-api'
}

export {
    getBaseUrl,
    request,
    BASE_URL,
    ACCESS_TOKEN_NAME,
    ACCESS_TOKEN
}
