var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [_vm.isRouterAlive ? _c("RouterView") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }