var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-divider", [_vm._v("选项")]),
      _c(
        "el-radio-group",
        {
          model: {
            value: _vm.localActiveData.config.optionsType,
            callback: function ($$v) {
              _vm.$set(_vm.localActiveData.config, "optionsType", $$v)
            },
            expression: "localActiveData.config.optionsType",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: 0 } }, [
            _vm._v(" 静态数据 "),
          ]),
          _c("el-radio-button", { attrs: { label: 1 } }, [
            _vm._v(" 动态数据 "),
          ]),
        ],
        1
      ),
      _vm.localActiveData.config.optionsType === 1
        ? _c(
            "div",
            [
              _c(
                "el-input",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "https://api.tduckcloud.com/xxx.json",
                  },
                  model: {
                    value: _vm.localActiveData.config.dynamicOptions.url,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.localActiveData.config.dynamicOptions,
                        "url",
                        $$v
                      )
                    },
                    expression: "localActiveData.config.dynamicOptions.url",
                  },
                },
                [_c("template", { slot: "prepend" }, [_vm._v(" 接口url ")])],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { size: "mini", placeholder: "data" },
                  model: {
                    value: _vm.localActiveData.config.dynamicOptions.dataPath,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.localActiveData.config.dynamicOptions,
                        "dataPath",
                        $$v
                      )
                    },
                    expression:
                      "localActiveData.config.dynamicOptions.dataPath",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _vm._v(" 列表数据jsonPath "),
                  ]),
                ],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { size: "mini", placeholder: "value" },
                  model: {
                    value: _vm.localActiveData.config.dynamicOptions.valueField,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.localActiveData.config.dynamicOptions,
                        "valueField",
                        $$v
                      )
                    },
                    expression:
                      "localActiveData.config.dynamicOptions.valueField",
                  },
                },
                [_c("template", { slot: "prepend" }, [_vm._v(" 值字段 ")])],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { size: "mini", placeholder: "label" },
                  model: {
                    value: _vm.localActiveData.config.dynamicOptions.labelField,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.localActiveData.config.dynamicOptions,
                        "labelField",
                        $$v
                      )
                    },
                    expression:
                      "localActiveData.config.dynamicOptions.labelField",
                  },
                },
                [_c("template", { slot: "prepend" }, [_vm._v(" 标签字段 ")])],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.config.optionsType === 0
        ? _c(
            "div",
            [
              _c(
                "draggable",
                {
                  attrs: {
                    animation: 340,
                    list: _vm.localActiveData.config.options,
                    group: "selectItem",
                    handle: ".option-drag",
                  },
                },
                _vm._l(
                  _vm.localActiveData.config.options,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "select-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "select-line-icon option-drag" },
                          [_c("i", { staticClass: "el-icon-s-operation" })]
                        ),
                        _c("el-input", {
                          attrs: { placeholder: "选项名", size: "small" },
                          model: {
                            value: item.label,
                            callback: function ($$v) {
                              _vm.$set(item, "label", $$v)
                            },
                            expression: "item.label",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "close-btn select-line-icon",
                            on: {
                              click: function ($event) {
                                return _vm.localActiveData.config.options.splice(
                                  index,
                                  1
                                )
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-remove-outline" })]
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "text",
                      },
                      on: { click: _vm.addSelectItem },
                    },
                    [_vm._v(" 添加选项 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: { icon: "el-icon-document-copy", type: "text" },
                      on: { click: _vm.openBatchEditDialogHandle },
                    },
                    [_vm._v(" 批量编辑 ")]
                  ),
                  ["CHECKBOX", "RADIO"].indexOf(_vm.localActiveData.typeId) > -1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "padding-bottom": "0" },
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "text",
                          },
                          on: { click: _vm.addSelectOtherItem },
                        },
                        [_vm._v(" 添加其他 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "批量编辑",
                    visible: _vm.dialogVisible,
                    width: "30%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("span", { staticClass: "desc-text" }, [
                    _vm._v("每行对应一个选项"),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 20, maxRows: 5000 },
                      placeholder: "选项1 选项2  选项3 ",
                    },
                    model: {
                      value: _vm.batchItemValue,
                      callback: function ($$v) {
                        _vm.batchItemValue = $$v
                      },
                      expression: "batchItemValue",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.batchEditSelectItems },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.config.optionsType === 2
        ? _c(
            "div",
            [
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "字典选择" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择字典" },
                      model: {
                        value: _vm.localActiveData.config.dictOptionType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localActiveData.config,
                            "dictOptionType",
                            $$v
                          )
                        },
                        expression: "localActiveData.config.dictOptionType",
                      },
                    },
                    _vm._l(_vm.dictTypeList, function (item) {
                      return _c("el-option", {
                        key: item.dictType,
                        attrs: { label: item.dictName, value: item.dictType },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }