var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-table-index",
      attrs: { id: _vm.value, name: _vm.value },
    },
    [
      _c(
        "el-table",
        {
          staticClass: "form-table",
          attrs: { "row-key": (record) => record.key, data: _vm.value },
        },
        [
          _vm.isVisible
            ? [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    align: "center",
                    type: "index",
                    width: "50",
                  },
                }),
                _vm._l(_vm.item.childList, function (option, index) {
                  return [
                    _c("el-table-column", {
                      key: index,
                      attrs: { label: _vm.item.label, align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showText(scope.row, option.vModel)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center", width: "300" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateDomain(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-edit" }),
                                _vm._v("修改 "),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyDomain(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-copy-document",
                                }),
                                _vm._v("复制 "),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeDomain(scope.$index)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-delete" }),
                                _vm._v("删除 "),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2769383713
                  ),
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-button",
        {
          attrs: { type: "dashed", disabled: _vm.disabled },
          on: { click: _vm.addDomain },
        },
        [
          _c("i", { staticClass: "el-icon-circle-plus-outline" }),
          _vm._v("增加 "),
        ]
      ),
      _vm.addOrUpdateVisible
        ? _c("AddOrUpdate", {
            ref: "addOrUpdate",
            attrs: { fields: _vm.item.childList },
            on: { submit: _vm.handleFormSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }