<template>
    <div class="right-board">
        <el-tabs v-model="currentTab" class="center-tabs">
            <el-tab-pane label="组件属性" name="field">
                <div class="field-box">
                    <el-scrollbar class="right-scrollbar">
                        <form-item-config v-if="showField&&activeData" :active-data="activeData" :form-conf="formConf"
                                          @add-fields="handleAddFields"
                                          @data-change="dataChange"
                        />
                        <el-empty v-else description="暂无选中组件" />
                    </el-scrollbar>
                </div>
            </el-tab-pane>
            <!--      <el-tab-pane label="表单属性" name="FormItem" />-->
        </el-tabs>
    </div>
</template>

<script>
import FormItemConfig from './FormItemConfig'

export default {
    components: {
        FormItemConfig
    },
    props: ['showField', 'activeData', 'formConf'],
    data() {
        return {
            currentTab: 'field'
        }
    },
    methods: {
        dataChange(val) {
            this.$emit('data-change', val)
        },
        handleAddFields(val) {
            this.$emit('add-fields', val)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/index";
.right-board {
  width: 350px;
  position: absolute;
  right: 17px;
  top: 0;
  padding-top: 3px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .field-box {
    position: relative;
    height: calc(100vh - 20px);
    box-sizing: border-box;
    overflow: hidden;
  }
  .el-scrollbar {
    height: 100%;
    padding-bottom: 50px;
  }
}
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  align-items: center;
  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }
  & .el-input + .el-input {
    margin-left: 4px;
  }
}
.select-item + .select-item {
  margin-top: 4px;
}
.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}
.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}
.option-drag {
  cursor: move;
}
.time-range {
  .el-date-editor {
    width: 227px;
  }
  ::v-deep .el-icon-time {
    display: none;
  }
}
.node-label {
  font-size: 17px;
}
.node-icon {
  color: #bebfc3;
}
</style>
