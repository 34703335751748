var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "write-container" }, [
    _c("h1", {
      staticStyle: { display: "none" },
      attrs: { id: "inActiveTime" },
    }),
    _vm.writeStatus == 0
      ? _c(
          "div",
          {},
          [
            _c("el-result", {
              attrs: {
                icon: "error",
                title: _vm.writeNotStartPrompt,
                "sub-title": _vm.writeNotStartPrompt,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.writeStatus == 1
      ? _c(
          "div",
          { staticClass: "form-container" },
          [
            _vm.formConfig.formKey
              ? _c("biz-project-form", {
                  attrs: { "form-config": _vm.formConfig },
                  on: { changeForm: _vm.changeForm, submit: _vm.submitForm },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.writeStatus == 2
      ? _c(
          "div",
          { staticClass: "title-icon-view" },
          [
            _c("el-result", {
              attrs: {
                icon: "success",
                title: _vm.userFormSetting.promptText
                  ? _vm.userFormSetting.promptTextContent
                  : "提交成功！",
              },
            }),
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _vm.userFormSetting.promptImg
                  ? _c("el-image", {
                      attrs: {
                        src: _vm.userFormSetting.promptImgUrl,
                        fit: "cover",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.writeStatus == 3
      ? _c(
          "div",
          { staticClass: "write-status-3" },
          [
            _c(
              "div",
              { staticClass: "image-div" },
              [
                _c("el-image", {
                  staticStyle: { width: "109px", height: "125px" },
                  attrs: { src: _vm.successUrl, fit: "cover" },
                }),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "el-button",
              { staticClass: "sub-button", on: { click: _vm.navTo } },
              [_vm._v(" 确认 ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-div" }, [
      _c("span", [_vm._v("1~3个工作日内会有我们的工作人员与您核实资料，")]),
      _c("br"),
      _c("span", [_vm._v("请保持手机畅通。")]),
      _c("br"),
      _c("span", [_vm._v("如有其他问题，可联系客服。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }