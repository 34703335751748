var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "logo-content" },
      [
        _c("span", { staticClass: "hello" }, [_vm._v("您好 ，")]),
        _c("span", { staticClass: "tips" }, [_vm._v("欢迎使用问卷系统！")]),
        _vm.formType == "login"
          ? _c(
              "el-tabs",
              {
                staticClass: "login-form-tab",
                model: {
                  value: _vm.loginType,
                  callback: function ($$v) {
                    _vm.loginType = $$v
                  },
                  expression: "loginType",
                },
              },
              [
                _vm.enableWx
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "微信扫码登录", name: "wx" } },
                      [
                        _c("div", { staticClass: "wx-login" }, [
                          _c(
                            "div",
                            { staticClass: "flex-center" },
                            [
                              _c("el-image", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.wxQrCodeLoading,
                                    expression: "wxQrCodeLoading",
                                  },
                                ],
                                staticClass: "wx-login-qrcode",
                                attrs: { src: _vm.wxLoginQrCode, fit: "fill" },
                                on: {
                                  load: (e) => {
                                    this.wxQrCodeLoading = false
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    underline: false,
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.getLoginWxQrCode },
                                },
                                [_vm._v(" 刷新二维码 ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "账号密码登录", name: "account" } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "accountLoginForm",
                        staticClass: "account-login-form",
                        attrs: {
                          model: _vm.accountForm,
                          rules: _vm.accountLoginRules,
                          "hide-required-asterisk": "",
                          "label-position": "top",
                          size: "small",
                          "status-icon": "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.loginHandle.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "account" } },
                          [
                            _c("el-input", {
                              attrs: {
                                autocomplete: "off",
                                placeholder: "请输入手机号/邮箱",
                                "prefix-icon": "el-icon-user-solid",
                              },
                              model: {
                                value: _vm.accountForm.account,
                                callback: function ($$v) {
                                  _vm.$set(_vm.accountForm, "account", $$v)
                                },
                                expression: "accountForm.account",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "password" } },
                          [
                            _c("el-input", {
                              attrs: {
                                autocomplete: "off",
                                placeholder: "请输入密码",
                                "prefix-icon": "el-icon-lock",
                                "show-password": "",
                              },
                              model: {
                                value: _vm.accountForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.accountForm, "password", $$v)
                                },
                                expression: "accountForm.password",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.loginHandle },
                              },
                              [_vm._v(" 登录 ")]
                            ),
                            false
                              ? _c(
                                  "el-link",
                                  {
                                    staticClass: "ml-20 link-btn",
                                    on: { click: _vm.toForgetPwdHandle },
                                  },
                                  [_vm._v(" 忘记密码 ")]
                                )
                              : _vm._e(),
                            false
                              ? _c(
                                  "el-link",
                                  {
                                    staticClass: "ml-20 link-btn",
                                    on: {
                                      click: function ($event) {
                                        _vm.formType = "reg"
                                      },
                                    },
                                  },
                                  [_vm._v(" 立即注册 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        false
                          ? _c("div", { staticClass: "other-login" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectUrl(
                                        _vm.qqLoginAuthorizeUrl
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "other-login-icon",
                                    attrs: { name: "loginQQ" },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("register", { on: { success: _vm.registerSuccessHandle } }),
        false
          ? _c("p", { staticClass: "desc" }, [_vm._v("关于TDuckApp登录")])
          : _vm._e(),
        false
          ? _c("p", { staticClass: "desc" }, [
              _vm._v(
                " 若微信扫码失败，请打开 微信授权页面 登录 若QQ登录填鸭云异常， 可查阅 帮助文档 若因微信、QQ、公众号冻结或账号密码找回失败等 无法登录，可 自助申请 登录账号 "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo-banner" }, [
      _c("img", { attrs: { src: require("@/assets/images/logo_banner.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }