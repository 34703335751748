var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t-gen-form" },
    [
      _c(
        "el-row",
        { attrs: { gutter: _vm.formConfCopy.gutter } },
        [
          _c(
            "el-form",
            {
              ref: _vm.formConfCopy.formRef,
              attrs: {
                disabled: _vm.formConf.disabled,
                "label-position": _vm.formConfCopy.labelPosition,
                "label-width": _vm.formConfCopy.labelWidth + "px",
                model: _vm.formModel,
                rules: _vm.formRules,
                size: _vm.formConfCopy.size,
              },
            },
            [
              _vm._l(_vm.formConfCopy.fields, function (item, index) {
                return [
                  _c("generate-form-item", {
                    key: item.key,
                    style: _vm.isFieldShow(item),
                    attrs: {
                      cid: item.vModel,
                      index: index,
                      item: item,
                      models: _vm.formModel,
                      rules: _vm.formRules,
                      "page-text-show": _vm.formConfCopy.pageTextShow,
                      "seq-no": _vm.getSeqNo(index, item),
                    },
                    on: {
                      "update:models": function ($event) {
                        _vm.formModel = $event
                      },
                      next: _vm.nextPage,
                      prev: _vm.prevPage,
                      "input-change": _vm.onInputChange,
                      "ocr-success": _vm.handleOcrSuccess,
                    },
                  }),
                ]
              }),
              _vm.formConfCopy.formBtns
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "submit-btn-form-item",
                          staticStyle: { "margin-top": "30px" },
                          attrs: { size: "large" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitForm },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formConfCopy.submitBtnText
                                      ? _vm.formConfCopy.submitBtnText
                                      : "提交"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.formConfCopy.resetBtn
                            ? _c(
                                "el-button",
                                { on: { click: _vm.resetForm } },
                                [_vm._v(" 重置 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }