<template>
    <div>
        <el-upload
            :action="action"
            accept="image/*"
            :before-remove="beforeRemove"
            :before-upload="handleBeforeUpload"
            :file-list="fileList"
            :on-error="handleError"
            :on-exceed="handleExceed"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :show-file-list="false"
        >
            <el-button icon="el-icon-search" :loading="loading" size="small" type="primary">
                识别文字
            </el-button>
            <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过10Mb
            </div>
        </el-upload>
    </div>
</template>

<script>
import {getBaseUrl} from '../../../api/request'
import mixin from '../mixin'

export default {
    name: 'TOcr',
    mixins: [mixin],
    props: ['models'],
    data() {
        return {
            fileList: [],
            action: '',
            loading: false
        }
    },
    created() {
        this.action = getBaseUrl() + '/form/common/ocr'
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList)
        },
        handlePreview(file) {
            console.log(file)
        },
        handleExceed(files, fileList) {
        },
        beforeRemove(file, fileList) {
        },
        handleSuccess(res, file) {
            this.loading = false
            this.$message.success('识别完成')
            if (!Object.keys(this.item.fieldMapping).length) {
                this.$message.error('未配置识别映射规则，无法正常使用')
                return
            }
            let values = {}
            if (res.data) {
                Object.keys(res.data).forEach(key => {
                    let modelKey = this.item.fieldMapping[key]
                    values[modelKey] = res.data[key]
                })
            }
            this.$emit('ocr-success', values)
            // this.models[`${this.item.vModel}`] = val
        },
        handleError(err, file, fileList) {
            this.$message.error(err)
            this.loading = false
        },
        handleBeforeUpload(file) {
            this.loading = true
        }
    }
}
</script>

<style scoped>

</style>
