var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.item &&
      _vm.item.config.dataType &&
      ["integer", "float", "number"].includes(_vm.item.config.dataType.type)
        ? _c(
            "el-input-number",
            _vm._b(
              {
                model: {
                  value: _vm.changeValue,
                  callback: function ($$v) {
                    _vm.changeValue = _vm._n($$v)
                  },
                  expression: "changeValue",
                },
              },
              "el-input-number",
              { ..._vm.item },
              false
            )
          )
        : _c(
            "el-input",
            _vm._b(
              {
                model: {
                  value: _vm.changeValue,
                  callback: function ($$v) {
                    _vm.changeValue = $$v
                  },
                  expression: "changeValue",
                },
              },
              "el-input",
              { ..._vm.item },
              false
            ),
            [
              _vm.item.prepend
                ? _c("template", { slot: "prepend" }, [
                    _vm._v(" " + _vm._s(_vm.item.prepend) + " "),
                  ])
                : _vm._e(),
              _vm.item.append
                ? _c("template", { slot: "append" }, [
                    _vm._v(" " + _vm._s(_vm.item.append) + " "),
                  ])
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }