var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "量级设置" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 100, label: "描述文字" },
            model: {
              value: _vm.localActiveData.table.level,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData.table, "level", $$v)
              },
              expression: "localActiveData.table.level",
            },
          }),
        ],
        1
      ),
      _vm.localActiveData.table.copyWriting
        ? _c(
            "el-form-item",
            { attrs: { label: "最低分文案" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入最低分文案" },
                model: {
                  value: _vm.localActiveData.table.copyWriting.min,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData.table.copyWriting, "min", $$v)
                  },
                  expression: "localActiveData.table.copyWriting.min",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.table.copyWriting
        ? _c(
            "el-form-item",
            { attrs: { label: "最高分文案" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入最高分文案" },
                model: {
                  value: _vm.localActiveData.table.copyWriting.max,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData.table.copyWriting, "max", $$v)
                  },
                  expression: "localActiveData.table.copyWriting.max",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "图标" } },
        _vm._l(_vm.iconList, function (icon) {
          return _c("span", {
            key: icon,
            class: [icon, icon === _vm.localActiveData.icon ? "selected" : ""],
            on: {
              click: function ($event) {
                return _vm.handleSelectIcon(icon)
              },
            },
          })
        }),
        0
      ),
      _c("matrix-option", { attrs: { "active-data": _vm.localActiveData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }