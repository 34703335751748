<template>
    <div>
        <el-form-item
            label="是否多选"
        >
            <el-switch v-model="localActiveData.multiple" @change="multipleChange" />
        </el-form-item>
        <el-divider>选项</el-divider>
        <draggable
            :animation="340"
            :list="localActiveData.config.options"
            group="selectItem"
            handle=".option-drag"
        >
            <div
                v-for="(item, index) in localActiveData.config.options"
                :key="index"
                class="select-item"
            >
                <div class="select-line-icon option-drag">
                    <i class="el-icon-s-operation" />
                </div>
                <div class="width-full">
                    <div class="flex-row">
                        <el-input
                            v-model="item.label"
                            placeholder="选项名"
                            size="small"
                        />
                        <div
                            class="close-btn select-line-icon"
                            @click="localActiveData.config.options.splice(index, 1)"
                        >
                            <i class="el-icon-remove-outline" />
                        </div>
                    </div>
                    <div class="flex-row">
                        <el-input
                            v-model="item.image"
                            placeholder="图片"
                            size="small"
                        />
                        <el-upload
                            ref="logoUpload"
                            :action="getUploadUrl"
                            :headers="getUploadHeader"
                            :on-progress="uploadProgressHandle"
                            :on-success="
                                (response, file, fileList) => {
                                    item.image = response.data;
                                    $set(localActiveData.config.options, index, item);
                                    closeUploadProgressHandle();
                                }
                            "
                            :show-file-list="false"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                            style="text-align: center"
                        >
                            <div slot="trigger" class="select-line-icon">
                                <i class="el-icon-upload" />
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div>
        </draggable>
        <div style="margin-left: 20px">
            <el-button
                icon="el-icon-circle-plus-outline"
                style="padding-bottom: 0"
                type="text"
                @click="addImageSelectItem"
            >
                添加选项
            </el-button>
        </div>
        <el-divider />
    </div>
</template>

<script>

import mixin from './mixin'
import draggable from 'vuedraggable'
import _ from 'lodash'

export default {
    name: 'ConfigItemImageSelect',
    components: {
        draggable
    },
    mixins: [mixin],
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    },
    methods: {
        addImageSelectItem() {
            const lastItem = _.last(this.localActiveData.config.options)
            this.localActiveData.config.options.push({
                label: '',
                image: '',
                value: lastItem ? lastItem.value + 1 : 1
            })
        },
        multipleChange(val) {
            this.$set(this.localActiveData.config, 'defaultValue', val ? [] : '')
        }
    }
}
</script>

<style lang="scss" scoped>
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  align-items: center;
  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }
  & .el-input + .el-input {
    margin-left: 4px;
  }
}
.select-item + .select-item {
  margin-top: 4px;
}
.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}
.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}
.option-drag {
  cursor: move;
}
</style>
