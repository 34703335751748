var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-container" },
    [
      _vm.formConfig.formKey
        ? _c(
            "el-tabs",
            { attrs: { type: "card" } },
            [
              _c("el-tab-pane", [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _c("i", { staticClass: "el-icon-mobile" }),
                  _vm._v(" 手机 "),
                ]),
                _c("div", { staticClass: "preview-layer" }, [
                  _c("div", { staticClass: "preview-bg" }),
                  _c("div", { staticClass: "preview-phone" }, [
                    _c("iframe", {
                      staticClass: "preview-html",
                      attrs: {
                        id: "preview-html",
                        src: _vm.mobilePreviewUrl,
                        frameborder: "0",
                        name: "preview-html",
                        scrolling: "auto",
                      },
                    }),
                  ]),
                ]),
                _vm.mobilePreviewUrl && _vm.previewQrcode
                  ? _c(
                      "div",
                      { staticClass: "qrcode-view" },
                      [
                        _c("p", [_vm._v("手机扫码查看效果")]),
                        _c("p", { staticClass: "text-danger" }, [
                          _vm._v(" * 预览只能查看效果，无法提交数据 "),
                        ]),
                        _vm.mobilePreviewUrl && _vm.previewQrcode
                          ? _c("vue-qr", {
                              attrs: { size: 194, text: _vm.mobilePreviewUrl },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "el-tab-pane",
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "el-icon-monitor" }),
                    _vm._v(" 电脑 "),
                  ]),
                  _c(
                    "el-scrollbar",
                    {
                      staticStyle: {
                        height: "77vh",
                        "overflow-x": "hidden!important",
                      },
                    },
                    [
                      _vm.formConfig.formKey
                        ? _c("biz-project-form", {
                            attrs: { "form-config": _vm.formConfig },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }