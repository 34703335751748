<template>
    <div class="write-container">
        <h1 id="inActiveTime" style="display: none" />
        <div v-if="writeStatus == 0" v-cloak>
            <el-result
                icon="error"
                :title="writeNotStartPrompt"
                :sub-title="writeNotStartPrompt"
            />
        </div>
        <div v-if="writeStatus == 1" class="form-container">
            <biz-project-form
                v-if="formConfig.formKey"
                :form-config="formConfig"
                @changeForm="changeForm"
                @submit="submitForm"
            />
        </div>
        <div v-if="writeStatus == 2" v-cloak class="title-icon-view">
            <el-result
                icon="success"
                :title="
                    userFormSetting.promptText
                        ? userFormSetting.promptTextContent
                        : '提交成功！'
                "
            />
            <div style="text-align: center">
                <el-image
                    v-if="userFormSetting.promptImg"
                    :src="userFormSetting.promptImgUrl"
                    fit="cover"
                />
                <!--                <el-button v-if="userFormSetting.publicResult" type="primary" @click="openPublicResultHandle">-->
                <!--                    查看数据-->
                <!--                </el-button>-->
            </div>
        </div>
        <div v-if="writeStatus == 3" v-cloak class="write-status-3">
            <div class="image-div">
                <el-image
                    style="width: 109px; height: 125px"
                    :src="successUrl"
                    fit="cover"
                />
            </div>
            <div class="text-div">
                <span>1~3个工作日内会有我们的工作人员与您核实资料，</span><br>
                <span>请保持手机畅通。</span><br>
                <span>如有其他问题，可联系客服。</span>
            </div>
            <el-button class="sub-button" @click="navTo">
                确认
            </el-button>
        </div>
    </div>
</template>

<script>
import { BizProjectForm } from '@/index'
import { getQueryString, geturlparam } from '@/utils'
import constants from '@/utils/constants'
import {
    getAuthorizationUrl,
    getAuthorizationUserInfo,
    getWxSignature
} from '@/api/project/wxmp'
import { setWxConfig } from './wx'
import _ from 'lodash'
import mixin from '../TduckFormMixin'
import {
    createPublicFormResultRequest,
    viewFormResultRequest
} from '@/api/project/data'
import {
    getShareSettingRequest,
    getSubmitSettingRequest,
    getWriteSettingRequest,
    getWriteSettingStatusRequest
} from '@/api/project/setting'
import wx from 'weixin-js-sdk'
window.wx = wx

const uaParser = require('ua-parser-js')
const ua = uaParser(navigator.userAgent)

require('@/utils/ut')

export default {
    name: 'WriteView',
    components: {
        BizProjectForm
    },
    mixins: [mixin],
    props: {},
    data() {
        return {
            inActiveTime: 0,
            formConfig: {
                formKey: '',
                preview: false,
                showBtns: true
            },
            writeStatus: 1,
            // 不允许填写的提示信息
            writeNotStartPrompt: '',
            userFormSetting: {},
            // 微信授权地址
            wxAuthorizationUrl: '',
            successUrl: 'https://oss.aliyun.tangyigroup.com/tyhztesting-wx-mini-program/program/signUp_success.png',
            wxAuthorizationCode: '',
            wxUserInfo: {},
            wxSignature: {}
        }
    },
    async created() {
        let key = this.$route.query.key || this.$route.params.key
        this.formConfig.formKey = key
        // 微信授权重定向到该页 会携带code参数
        let wxCode = getQueryString('code')
        if (wxCode) {
            this.wxAuthorizationCode = wxCode
            await this.getWxAuthorizationUserInfo()
        }
        // 微信逻辑授权结束
        this.getWxAuthorizationUrl()
        // 检查是否能进入填写
        this.queryProjectSettingStatus()
        this.queryProjectSetting()
        if (constants.enableWx) {
            // 加载微信相关 获取签名
            getWxSignature({ url: window.location.href }).then(res => {
                this.wxSignature = res.data
                getShareSettingRequest(this.formConfig.formKey).then(res => {
                    setWxConfig(this.wxSignature, res.data)
                })
            })
        }
    },
    mounted() {
        viewFormResultRequest(this.formConfig.formKey).then(() => {})
    },
    methods: {
        navTo() {
            wx.miniProgram.navigateTo({
                url: '/pages/index/index'
            })

        },
        queryProjectSettingStatus() {
            // 是否能进入填写
            getWriteSettingStatusRequest({
                formKey: this.formConfig.formKey,
                wxOpenId: this.wxUserInfo ? this.wxUserInfo.openid : ''
            }).then(res => {
                if (res.msg) {
                    this.writeNotStartPrompt = res.msg
                    this.writeStatus = 0
                }
            })
        },
        getWxAuthorizationUserInfo() {
            // 根据code 获取用户授权信息
            let wxAuthorizationCode = this.wxAuthorizationCode
            return getAuthorizationUserInfo({
                code: wxAuthorizationCode
            }).then(res => {
                if (res.data) {
                    this.wxUserInfo = res.data
                }
            })
        },
        getWxAuthorizationUrl() {
            // 获取微信授权url地址
            getAuthorizationUrl({ url: window.location.href }).then(res => {
                if (res.data) {
                    this.wxAuthorizationUrl = res.data
                }
            })
        },
        queryProjectSetting() {
            // 提交设置
            getSubmitSettingRequest(this.formConfig.formKey).then(res => {
                if (res.data) {
                    this.userFormSetting = this.userFormSetting
                        ? _.assign(this.userFormSetting, res.data)
                        : res.data
                }
            })
            // 填写设置
            getWriteSettingRequest(this.formConfig.formKey).then(res => {
                if (res.data) {
                    this.userFormSetting = this.userFormSetting
                        ? _.assign(this.userFormSetting, res.data)
                        : res.data
                    // 仅在微信环境打开
                    if (res.data && res.data.wxWrite) {
                        // 记录微信用户信息
                        if (res.data.recordWxUser && !this.wxAuthorizationCode) {
                            location.href = this.wxAuthorizationUrl
                        } else {
                            this.onlyWxOpenHandle()
                        }
                    }
                }
            })
        },
        openPublicResultHandle() {
            let formKey = this.formConfig.formKey
            this.$router.replace({
                path: '/project/public/result',
                query: { formKey }
            })
        },
        changeForm(data) {
            // url携带参数
            const businessJson = JSON.stringify(geturlparam())
            // 完成时间
            let inActiveTime = document.getElementById('inActiveTime').innerText
            createPublicFormResultRequest({
                hasFinish: 0,
                completeTime: inActiveTime,
                formKey: this.formConfig.formKey,
                submitOs: ua.os.name,
                submitBrowser: ua.browser.name,
                submitUa: ua,
                wxUserInfo: this.wxUserInfo,
                wxOpenId: this.wxUserInfo ? this.wxUserInfo.openid : '',
                originalData: data.formModel,
                businessJson: businessJson
            }).then(() => {
            })
        },
        submitForm(data) {
            // url携带参数
            const businessJson = JSON.stringify(geturlparam())
            console.info('跳转信息', businessJson)

            // 完成时间
            let inActiveTime = document.getElementById('inActiveTime').innerText
            createPublicFormResultRequest({
                hasFinish: 1,
                completeTime: inActiveTime,
                formKey: this.formConfig.formKey,
                submitOs: ua.os.name,
                submitBrowser: ua.browser.name,
                submitUa: ua,
                wxUserInfo: this.wxUserInfo,
                wxOpenId: this.wxUserInfo ? this.wxUserInfo.openid : '',
                originalData: data.formModel,
                businessJson: businessJson
            }).then(() => {
                this.writeStatus = 2
                if (geturlparam().success == '1') {
                    console.info('跳转信息', businessJson)
                    this.writeStatus = 3
                }
                if (this.userFormSetting.submitJump) {
                    setTimeout(() => {
                        window.location.replace(this.userFormSetting.submitJumpUrl)
                    }, 1000)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
  .write-status-3 {
      height: 94vh;
      .image-div {
          text-align: center;
          margin-top: 24px;
      }
      .text-div {
          text-align: center;
          margin: 0 auto;
          margin-top: 40px;
          font-size: 14px;
          color: #4D4F51;
      }
      .sub-button {
          margin-top: 94%;
          width: 90%;
          margin-left: 5%;
          font-size: 18px;
          background-color: #369DD6;
          color: #ffffff;
          border-radius: 8px 8px 8px 8px;
          height: 6.6vh;
      }
  }
.write-container {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.project-form-wrapper {
    background-image: url("~@/assets/images/back-image.png");
    background-color: #DAF6F2;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
}

.title-icon-view {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.form-container {
  height: 100%;
}

.icon-view {
  width: 59px;
  height: 59px;
  border-radius: 100px;
  background-color: #0076ff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.success-icon {
  text-align: center;
  color: white;
  font-size: 30px;
}
</style>

<style lang="scss">
@import "../../../assets/styles/elementui-mobile.scss";

.project-form-wrapper {
  width: 100%;
  min-height: 100%;
  padding: 20px 0px;
  background-color: rgba(158, 207, 250, 0.3);
}

.project-form {
  margin: 0px auto;
  width: 800px;
  padding: 15px;
  background-repeat: repeat;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.project-form .describe-html img {
  max-width: 780px;
  margin: 0px;
}

.project-body::-webkit-scrollbar {
  width: 0 !important;
  background: transparent;
}

.project-body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.logo-img {
  max-height: 120px;
}

.submit-btn-form-item button {
  width: 20%;
}

.support-text {
  color: #545454;
  text-shadow: 0 1px 1px #e9e9e9;
  margin-top: 20px;
}

@media screen and (max-width: 750px) {
    .form-description-text {
        font-size: 14px;
    }
    .el-form-item__label {
        font-size: 17px;
    }
    .el-checkbox__label {
        font-size: 17px;
    }
    .el-radio__label {
        font-size: 17px;
    }
    .el-input--medium {
        font-size: 17px;
    }
    .el-form-item__content {
        font-size: 17px;
    }
  .project-form {
      width: 90% !important;
      border-radius: 8px;
  }
  .logo-img {
    max-height: 2.94rem;
  }
  .submit-btn-form-item {
    text-align: center;
  }
  .submit-btn-form-item button {
    width: 80%;
  }
  .project-form .describe-html img {
    width: 95vw !important;
  }
}
</style>
