var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "最大值" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, placeholder: "最大值" },
            model: {
              value: _vm.localActiveData.max,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "max", $$v)
              },
              expression: "localActiveData.max",
            },
          }),
        ],
        1
      ),
      _vm.localActiveData["allow-half"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "允许半选" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.localActiveData["allow-half"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "allow-half", $$v)
                  },
                  expression: "localActiveData['allow-half']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["show-text"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "辅助文字" } },
            [
              _c("el-switch", {
                on: { change: _vm.rateTextChange },
                model: {
                  value: _vm.localActiveData["show-text"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "show-text", $$v)
                  },
                  expression: "localActiveData['show-text']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["show-score"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "显示分数" } },
            [
              _c("el-switch", {
                on: { change: _vm.rateScoreChange },
                model: {
                  value: _vm.localActiveData["show-score"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "show-score", $$v)
                  },
                  expression: "localActiveData['show-score']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["show-stops"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "显示间断点" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.localActiveData["show-stops"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "show-stops", $$v)
                  },
                  expression: "localActiveData['show-stops']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }