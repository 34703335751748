// 处理label值 便于显示
import _ from 'lodash'

/**
 * 是否是具有label值得组件
 */
export function isLabelTag(key) {
    if (_.startsWith(key, 'radio') || _.startsWith(key, 'checkbox')
    || _.startsWith(key, 'select') || _.startsWith(key, 'image_select')
    || _.startsWith(key, 'cascader')) {
        return true
    }
    return false
}

export function handleChangeLabel(models, item, value) {
    const key = item.vModel
    if (isLabelTag(key)) {
        models[`${key}label`] = getCheckedLabel(value, item.config.options, item, models)
    }
}

// 获取选中的值
function getCheckedLabel(val, options, item, formModel) {
    if (options) {
    // 结果是数据 checkbox 级联等
        if (Array.isArray(val)) {
            const labelArr = []
            val.forEach(v => {
                // 特殊处理checkBox 其他输入框输入的情况
                if (item.typeId === 'CHECKBOX' && v === 0) {
                    labelArr.push(formModel[`${item.vModel}other`])
                } else {
                    const obj = getObject(options, 'value', v)
                    labelArr.push(obj ? obj.label : '')
                }
            })
            return labelArr
        }
        // 特殊处理checkBox 其他输入框输入的情况
        if (item.typeId === 'RADIO' && val === 0) {
            return formModel[`${item.vModel}other`]
        }
        const obj = getObject(options, 'value', val)
        return obj ? obj.label : ''
    }
    return ''
}

/**
 * 从数组递归查找符合条件的数据
 * @param array
 * @param key
 * @param value
 * @returns {*}
 */
function getObject(array, key, value) {
    let o
    array.some(function iter(a) {
        if (a[key] === value) {
            o = a
            return true
        }
        return Array.isArray(a.children) && a.children.some(iter)
    })
    return o
}
