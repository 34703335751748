var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.prepend !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "前缀" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入前缀" },
                model: {
                  value: _vm.localActiveData.prepend,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "prepend", $$v)
                  },
                  expression: "localActiveData.prepend",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.__slot__ && _vm.localActiveData.append !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "后缀" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入后缀" },
                model: {
                  value: _vm.localActiveData.append,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "append", $$v)
                  },
                  expression: "localActiveData.append",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["prefix-icon"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "前图标" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入前图标名称" },
                  model: {
                    value: _vm.localActiveData["prefix-icon"],
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "prefix-icon", $$v)
                    },
                    expression: "localActiveData['prefix-icon']",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append", icon: "el-icon-thumb" },
                      on: {
                        click: function ($event) {
                          return _vm.openIconsDialog("prefix-icon")
                        },
                      },
                      slot: "append",
                    },
                    [_vm._v(" 选择 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["suffix-icon"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "后图标" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入后图标名称" },
                  model: {
                    value: _vm.localActiveData["suffix-icon"],
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "suffix-icon", $$v)
                    },
                    expression: "localActiveData['suffix-icon']",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append", icon: "el-icon-thumb" },
                      on: {
                        click: function ($event) {
                          return _vm.openIconsDialog("suffix-icon")
                        },
                      },
                      slot: "append",
                    },
                    [_vm._v(" 选择 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.autosize !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "最小行数" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, placeholder: "最小行数" },
                model: {
                  value: _vm.localActiveData.autosize.minRows,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData.autosize, "minRows", $$v)
                  },
                  expression: "localActiveData.autosize.minRows",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.autosize !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "最大行数" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, placeholder: "最大行数" },
                model: {
                  value: _vm.localActiveData.autosize.maxRows,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData.autosize, "maxRows", $$v)
                  },
                  expression: "localActiveData.autosize.maxRows",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.maxlength !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "最多输入" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入字符长度" },
                  model: {
                    value: _vm.localActiveData.maxlength,
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "maxlength", $$v)
                    },
                    expression: "localActiveData.maxlength",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v(" 个字符 ")])],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["show-word-limit"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "输入统计" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.localActiveData["show-word-limit"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "show-word-limit", $$v)
                  },
                  expression: "localActiveData['show-word-limit']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.config.dataType
        ? _c(
            "div",
            { staticClass: "reg-item" },
            [
              _vm.localActiveData.config.dataType
                ? _c(
                    "el-form-item",
                    { attrs: { label: "反馈类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.dataTypeChange },
                          model: {
                            value: _vm.localActiveData.config.dataType.type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localActiveData.config.dataType,
                                "type",
                                $$v
                              )
                            },
                            expression: "localActiveData.config.dataType.type",
                          },
                        },
                        _vm._l(_vm.dataTypeList, function (option) {
                          return _c("el-option", {
                            key: option.value,
                            attrs: { value: option.value, label: option.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.localActiveData.config.dataType.type
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "错误提示" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入错误提示" },
                        model: {
                          value: _vm.localActiveData.config.dataType.message,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.localActiveData.config.dataType,
                              "message",
                              $$v
                            )
                          },
                          expression: "localActiveData.config.dataType.message",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("icons-dialog", {
        attrs: {
          current: _vm.localActiveData[_vm.currentIconModel],
          visible: _vm.iconsVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.iconsVisible = $event
          },
          select: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }