var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-divider", [_vm._v("填空内容")]),
      _c("el-input", {
        ref: "inputText",
        attrs: {
          id: "inputText",
          type: "textarea",
          rows: 2,
          placeholder: "请输入填空内容",
        },
        model: {
          value: _vm.activeData.input,
          callback: function ($$v) {
            _vm.$set(_vm.activeData, "input", $$v)
          },
          expression: "activeData.input",
        },
      }),
      _c(
        "div",
        { staticStyle: { "margin-left": "20px", "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              nativeOn: {
                mousedown: function ($event) {
                  return _vm.handleInsertInput.apply(null, arguments)
                },
              },
            },
            [_vm._v(" 插入填空 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }