var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "draggable",
        {
          attrs: {
            animation: 340,
            list: _vm.localActiveData.sortList,
            group: "selectItem",
            handle: ".option-drag",
          },
        },
        _vm._l(_vm.localActiveData.sortList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "select-item" },
            [
              _c("div", { staticClass: "select-line-icon option-drag" }, [
                _c("i", { staticClass: "el-icon-s-operation" }),
              ]),
              _c("el-input", {
                attrs: { placeholder: "选项名", size: "small" },
                model: {
                  value: item.label,
                  callback: function ($$v) {
                    _vm.$set(item, "label", $$v)
                  },
                  expression: "item.label",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "close-btn select-line-icon",
                  on: {
                    click: function ($event) {
                      return _vm.localActiveData.sortList.splice(index, 1)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-remove-outline" })]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticStyle: { "margin-left": "20px", "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.addSelectItem },
            },
            [_vm._v(" 添加选项 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }