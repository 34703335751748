<template>
    <div class="write-container">
        <h1 id="inActiveTime" style="display: none" />
        <div v-if="writeStatus == 0" v-cloak>
            <el-result
                icon="error"
                :title="writeNotStartPrompt"
                :sub-title="writeNotStartPrompt"
            />
        </div>
        <div v-if="writeStatus == 1" class="form-container">
            <div class="write-container">
                <div class="form-container">
                    <div class="project-form-wrapper">
                        <div class="project-form">
                            <h4 class="form-name-text" style="text-align: center" v-html="questionTitle" />
                            <div class="form-description-text" v-html="description" />
                            <generate-form
                                v-if="logic && formConfig.fields.length && formModel"
                                :key="formParseKey"
                                ref="generateForm"
                                :form-conf="formConfig"
                                :page-form-model="formModel"
                                :start-parser="false"
                                style="margin-bottom: 40px"
                                @on-change="bindFormChange"
                            />
                            <div class="submit-btn-form">
                                <el-button type="primary" @click="bindSubmitBtn">
                                    提交
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="writeStatus == 2" v-cloak class="title-icon-view">
            <el-result
                icon="success"
                :title="
                    userFormSetting.promptText
                        ? userFormSetting.promptTextContent
                        : '提交成功！'
                "
            />
            <div style="text-align: center">
                <el-image
                    v-if="userFormSetting.promptImg"
                    :src="userFormSetting.promptImgUrl"
                    fit="cover"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {BizProjectForm, GenerateForm} from '@/index'
import { getQueryString, geturlparam } from '@/utils'
import {
    viewFormListResultRequest
} from '@/api/project/data'
import {
    listFormDetailsRequest
} from '@/api/project/form'
import constants from '@/utils/constants'
import {
    getAuthorizationUrl,
    getAuthorizationUserInfo,
    getWxSignature
} from '@/api/project/wxmp'
import { setWxConfig } from '../../write/wx'
import _ from 'lodash'
import mixin from '../../TduckFormMixin'
import { createPublicFormResultRequest } from '@/api/project/data'
import {
    getShareSettingRequest,
    getSubmitSettingRequest,
    getWriteSettingRequest,
    getWriteSettingStatusRequest
} from '@/api/project/setting'

const uaParser = require('ua-parser-js')
const ua = uaParser(navigator.userAgent)

require('@/utils/ut')

export default {
    name: 'Submit',
    components: {
        GenerateForm
    },
    mixins: [mixin],
    props: {},
    data() {
        return {
            logic: true,
            formParseKey: new Date().getTime(),
            inActiveTime: 0,
            writeStatus: 1,
            // 不允许填写的提示信息
            writeNotStartPrompt: '',
            userFormSetting: {},
            // 微信授权地址
            wxAuthorizationUrl: '',
            wxAuthorizationCode: '',
            wxUserInfo: {},
            wxSignature: {},
            formConfig: {
                fields: [],
                logicShowRule: null,
                // 逻辑显示规则
                formKey: '',
                formKind: 1,
                formRef: 'elForm',
                formModel: 'formData',
                labelFormModel: 'labelFormData',
                size: 'small',
                labelPosition: 'top',
                labelWidth: 100,
                formRules: 'rules',
                gutter: 15,
                disabled: false,
                span: 24,
                formBtns: false,
                resetBtn: false,
                submitBtnText: '提交',
                submitBtnColor: '#409EFF',
                showNumber: false,
                unFocusedComponentBorder: true,
                // 每页数据
                perPageFields: {},
                // 设置内容
                setting: {},
                theme: {
                    backgroundImg: '',
                    showTitle: true,
                    showDescribe: true,
                    showNumber: false,
                    btnsColor: null
                }
            },
            formModel: {},
            questionTitle: '问卷名',
            description: '为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位 用户的宝贵意见，期待您的参与！现在我们就马上开始吧！'
        }
    },
    async created() {
        this.setFields()
        // 微信授权重定向到该页 会携带code参数
        let wxCode = getQueryString('code')
        if (wxCode) {
            this.wxAuthorizationCode = wxCode
            await this.getWxAuthorizationUserInfo()
        }
        // 微信逻辑授权结束
        this.getWxAuthorizationUrl()
        // 检查是否能进入填写
        this.queryProjectSettingStatus()
        this.queryProjectSetting()
        if (constants.enableWx) {
            // 加载微信相关 获取签名
            getWxSignature({ url: window.location.href }).then(res => {
                this.wxSignature = res.data
                getShareSettingRequest(this.formConfig.formKey).then(res => {
                    setWxConfig(this.wxSignature, res.data)
                })
            })
        }
    },
    methods: {
        setFields() {
            let key = this.$route.query.key || this.$route.params.key
            console.info(key)
            this.formConfig.formKey = key
            // url携带参数
            const businessJson = JSON.stringify(geturlparam())
            console.info(this.formConfig.formKey)
            console.info(businessJson)

            // url携带参数
            const businessJsonObject = geturlparam()
            console.info(businessJsonObject)
            // 问卷反馈不查询结果
            if (businessJsonObject.questionnaireType && businessJsonObject.questionnaireType === 'Feedback') {
                console.info('Feedback')
            } else {
                viewFormListResultRequest(this.formConfig.formKey, businessJson).then(response => {
                    console.info(response)
                    if (response.code == 200) {
                        let data = response.data
                        if (data && data[0] && data[0]['originalDataString']) {
                            let originalData = JSON.parse(data[0]['originalDataString'])
                            this.formModel = Object.assign(originalData, data[0]) || {}
                        }
                    }
                })
            }
            listFormDetailsRequest(this.formConfig.formKey).then(response => {
                console.info(response)
                if (response.code == 200) {
                    let data = response.data
                    let form = data.form || {}
                    this.questionTitle = form.name || ''
                    this.description = form.description || ''
                    let listForm = []
                    for (let item of data.formItems) {
                        listForm.push(item.scheme)
                    }
                    this.formConfig.fields = listForm

                    if (data.formLogic) {
                        this.formConfig.logicShowRule = data.formLogic.scheme
                        this.refreshLogicShowRule()
                    }
                }
            })
            this.formConfig.disabled = false
        },
        refreshLogicShowRule() {
            for (let logicShowRuleItem of this.formConfig.logicShowRule) {
                let conditionList = logicShowRuleItem['conditionList']
                for (let index = 0; index < this.formConfig.fields.length; index++) {
                    let fieldItem = this.formConfig.fields[index]
                    // 将配置了逻辑的表单不显示
                    if (logicShowRuleItem.formItemId == fieldItem.vModel) {
                        fieldItem.logicShowField = true
                    }
                    for (let conditionItem of conditionList) {
                        if (logicShowRuleItem.formItemId == fieldItem.vModel) {
                            console.info(this.formModel[conditionItem.formItemId])
                            if (this.formModel[conditionItem.formItemId]) {
                                if (conditionItem.expression == 'eq' && this.formModel[conditionItem.formItemId] === conditionItem.optionValue) {
                                    fieldItem.logicShowField = false
                                } else if (conditionItem.expression == 'ne' && this.formModel[conditionItem.formItemId] !== conditionItem.optionValue) {
                                    // 显示
                                    fieldItem.logicShowField = false
                                }
                            }
                        }
                    }
                }
            }
            this.bindReLoad()
        },
        queryProjectSettingStatus() {
            // 是否能进入填写
            getWriteSettingStatusRequest({
                formKey: this.formConfig.formKey,
                wxOpenId: this.wxUserInfo ? this.wxUserInfo.openid : ''
            }).then(res => {
                if (res.msg) {
                    this.writeNotStartPrompt = res.msg
                    this.writeStatus = 0
                }
            })
        },
        getWxAuthorizationUserInfo() {
            // 根据code 获取用户授权信息
            let wxAuthorizationCode = this.wxAuthorizationCode
            return getAuthorizationUserInfo({
                code: wxAuthorizationCode
            }).then(res => {
                if (res.data) {
                    this.wxUserInfo = res.data
                }
            })
        },
        getWxAuthorizationUrl() {
            // 获取微信授权url地址
            getAuthorizationUrl({ url: window.location.href }).then(res => {
                if (res.data) {
                    this.wxAuthorizationUrl = res.data
                }
            })
        },
        queryProjectSetting() {
            // 提交设置
            getSubmitSettingRequest(this.formConfig.formKey).then(res => {
                if (res.data) {
                    this.userFormSetting = this.userFormSetting
                        ? _.assign(this.userFormSetting, res.data)
                        : res.data
                }
            })
            // 填写设置
            getWriteSettingRequest(this.formConfig.formKey).then(res => {
                if (res.data) {
                    this.userFormSetting = this.userFormSetting
                        ? _.assign(this.userFormSetting, res.data)
                        : res.data
                    // 仅在微信环境打开
                    if (res.data && res.data.wxWrite) {
                        // 记录微信用户信息
                        if (res.data.recordWxUser && !this.wxAuthorizationCode) {
                            location.href = this.wxAuthorizationUrl
                        } else {
                            this.onlyWxOpenHandle()
                        }
                    }
                }
            })
        },
        openPublicResultHandle() {
            let formKey = this.formConfig.formKey
            this.$router.replace({
                path: '/project/public/result',
                query: { formKey }
            })
        },
        // 点击提交按钮
        bindSubmitBtn() {
            this.$refs.generateForm.$refs.elForm.validate(valid => {
                console.info(valid)
                if (valid) {
                    console.info('bindSubmitBtn')
                    this.submitForm(() => {
                        this.writeStatus = 2
                        if (this.userFormSetting.submitJump) {
                            setTimeout(() => {
                                window.location.replace(this.userFormSetting.submitJumpUrl)
                            }, 1000)
                        }
                    })
                } else {
                    console.log('submit error')
                }
            })
        },
        bindReLoad() {
            // 移除组件
            this.logic = false
            // 在组件移除后，重新渲染组件
            // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
            this.$nextTick(() => {
                this.logic = true
            })
        },
        // 提交表单数据给后端
        submitForm(callback) {
            const formData = this.$refs.generateForm.formModel
            _.keys(formData).forEach(key => {
                if (key.indexOf('-') === -1) {
                    delete formData[key]
                }
            })
            console.info(formData)
            // url携带参数
            const businessJson = JSON.stringify(this.$route.query)
            // 完成时间
            let inActiveTime = document.getElementById('inActiveTime').innerText
            createPublicFormResultRequest({
                completeTime: inActiveTime,
                formKey: this.formConfig.formKey,
                submitOs: ua.os.name,
                submitBrowser: ua.browser.name,
                submitUa: ua,
                wxUserInfo: this.wxUserInfo,
                wxOpenId: this.wxUserInfo ? this.wxUserInfo.openid : '',
                originalData: formData,
                businessJson: businessJson
            }).then(() => {
                if (callback) {
                    callback()
                }
            })
        },
        // 内容更新
        bindFormChange() {
            if (this.formConfig.logicShowRule) {
                this.formModel = this.$refs.generateForm.formModel
                this.refreshLogicShowRule()
            }
            // url携带参数
            const businessJson = this.$route.query
            console.info(businessJson)
            // 问卷反馈不自动提交
            if (businessJson.questionnaireType && businessJson.questionnaireType === 'Feedback') {
                console.info('Feedback')
            } else {
                this.submitChangeForm()
            }
        },
        // 自动提交表单数据给后端
        submitChangeForm(callback) {
            console.info('5555', 'ddd')
            const formData = this.$refs.generateForm.formModel
            _.keys(formData).forEach(key => {
                if (key.indexOf('-') === -1) {
                    delete formData[key]
                }
            })
            // url携带参数
            const businessJson = JSON.stringify(this.$route.query)
            // 完成时间
            let inActiveTime = document.getElementById('inActiveTime').innerText
            createPublicFormResultRequest({
                completeTime: inActiveTime,
                formKey: this.formConfig.formKey,
                submitOs: ua.os.name,
                submitBrowser: ua.browser.name,
                submitUa: ua,
                wxUserInfo: this.wxUserInfo,
                wxOpenId: this.wxUserInfo ? this.wxUserInfo.openid : '',
                originalData: formData,
                businessJson: businessJson
            }).then(() => {
                if (callback) {
                    callback()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.write-container {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.project-form-wrapper {
    background-image: url("~@/assets/images/back-image.png");
    background-color: #DAF6F2;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
}

.title-icon-view {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.form-container {
  height: 100%;
}

.icon-view {
  width: 59px;
  height: 59px;
  border-radius: 100px;
  background-color: #0076ff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.success-icon {
  text-align: center;
  color: white;
  font-size: 30px;
}
</style>

<style lang="scss">
@import "@/assets/styles/elementui-mobile.scss";

.project-form-wrapper {
  width: 100%;
  min-height: 100%;
  padding: 20px 0px;
  background-color: rgba(158, 207, 250, 0.3);
}

.project-form {
  margin: 0px auto;
  width: 800px;
  padding: 15px;
  background-repeat: repeat;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.project-form .describe-html img {
  max-width: 780px;
  margin: 0px;
}

.project-body::-webkit-scrollbar {
  width: 0 !important;
  background: transparent;
}

.project-body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.logo-img {
  max-height: 120px;
}

.submit-btn-form-item button {
  width: 20%;
}

.support-text {
  color: #545454;
  text-shadow: 0 1px 1px #e9e9e9;
  margin-top: 20px;
}
.submit-btn-form {
    margin: 0 auto;
    width: 80%;
    text-align: center;
}
.submit-btn-form .el-button {
    width: 100%;
    font-size: 20px;
}

@media screen and (max-width: 750px) {
  .project-form {
    width: 90% !important;
      border-radius: 8px;
  }
  .logo-img {
    max-height: 2.94rem;
  }
  .submit-btn-form-item {
    text-align: center;
  }
  .submit-btn-form-item button {
    width: 80%;
  }
  .project-form .describe-html img {
    width: 95vw !important;
  }
    .submit-btn-form {
        margin: 0 auto;
        width: 80%;
        text-align: center;
    }
    .submit-btn-form .el-button {
        width: 100%;
        font-size: 20px;
    }
}
</style>
