var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "fa",
    class: `${_vm.name}`,
    attrs: { "aria-hidden": "true" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }