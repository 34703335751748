<!--
  动态表格 用于批量填入数据
 -->
<template>
    <div :id="value" :name="value" class="form-table-index">
        <el-table
            class="form-table"
            :row-key="record => record.key"
            :data="value"
        >
            <template v-if="isVisible">
                <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                    width="50"
                />
                <template v-for="(option,index) in item.childList">
                    <el-table-column
                        :key="index"
                        :label="item.label"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{ showText(scope.row, option.vModel) }}
                        </template>
                    </el-table-column>
                </template>
                <el-table-column
                    label="操作"
                    align="center"
                    width="300"
                >
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="updateDomain(scope.$index,scope.row)">
                            <i class="el-icon-edit" />修改
                        </el-button>
                        <el-button type="primary" size="mini" @click="copyDomain(scope.row)">
                            <i class="el-icon-copy-document" />复制
                        </el-button>
                        <el-button type="danger" size="mini" @click="removeDomain(scope.$index)">
                            <i class="el-icon-delete" />删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <el-button type="dashed" :disabled="disabled" @click="addDomain">
            <i class="el-icon-circle-plus-outline" />增加
        </el-button>
        <AddOrUpdate v-if="addOrUpdateVisible" ref="addOrUpdate" :fields="item.childList"
                     @submit="handleFormSubmit"
        />
    </div>
</template>

<script>

import AddOrUpdate from './AddOrUpdate'
import mixin from '../mixin'
import {isLabelTag} from '../../GenerateForm/FormItemLabel'

export default {
    name: 'TSubForm',
    components: {
        AddOrUpdate
    },
    mixins: [mixin],
    props: ['models'],
    data() {
        return {
            addOrUpdateVisible: false,
            isVisible: true
        }
    },
    computed: {
        disabled() {
            return this.item.disabled
        }
    },
    watch: {},
    methods: {
        showText(row, key) {
            if (isLabelTag(key)) {
                return row[`${key}label`]
            }
            return row[key]
        },
        removeDomain(index) {
            this.$confirm('确定删除此数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (index !== -1) {
                    this.value.splice(index, 1)
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                        duration: 1000
                    })
                }
            })
        },
        updateDomain(index, data) {
            this.addOrUpdateVisible = true
            data.index = index
            this.$nextTick(() => {
                this.$refs.addOrUpdate.showDialog(data)
            })
        },
        copyDomain(data) {
            this.value.push(data)
        },
        addDomain() {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.showDialog()
            })
        },
        handleFormSubmit(val) {
            if (!this.value) {
                this.value = []
            }
            if (val.index != undefined) {
                this.$set(this.value, val.index, val)
            } else {
                this.value.push(val)
            }
        },
        handleInput() {
            this.$emit('change', this.models)
        }
    }
}
</script>
