<template>
    <div>
        <el-form-item
            v-if="localActiveData.config.fileSize !== undefined"
            label="文件大小"
        >
            <el-input
                v-model.number="localActiveData.config.fileSize"
                placeholder="请输入文件大小"
            >
                <el-select
                    slot="append"
                    v-model="localActiveData.config.sizeUnit"
                    :style="{ width: '66px' }"
                >
                    <el-option label="KB" value="KB" />
                    <el-option label="MB" value="MB" />
                    <el-option label="GB" value="GB" />
                </el-select>
            </el-input>
        </el-form-item>
        <el-form-item
            label="多选文件"
        >
            <el-switch v-model="localActiveData.multiple" />
        </el-form-item>
        <el-form-item
            label="最大文件个数"
        >
            <el-input-number v-model="localActiveData.limit" />
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'ConfigItemImageUpload',
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style scoped>

</style>
