var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.projectItemData.type == "UPLOAD"
      ? _c("div", [
          _vm.getItemValue["files"]
            ? _c(
                "div",
                _vm._l(_vm.getItemValue["files"], function (file) {
                  return _c(
                    "el-link",
                    {
                      key: file,
                      attrs: {
                        href: file.url,
                        target: "_blank",
                        type: "primary",
                      },
                    },
                    [_c("span", [_vm._v(" " + _vm._s(file.fileName))])]
                  )
                }),
                1
              )
            : _c("span", [_vm._v("/")]),
        ])
      : _c("div", [
          _vm._v(" " + _vm._s(_vm.getItemValue ? _vm.getItemValue : "/") + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }