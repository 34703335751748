var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        _vm._b(
          {
            attrs: {
              action: _vm.item.action,
              "before-upload": _vm.beforeUpload,
              "file-list": _vm.changeValue || [],
              "on-preview": _vm.handlePreview,
              "on-remove": _vm.uploadRemove,
              "on-success": _vm.uploadSuccess,
            },
          },
          "el-upload",
          { ..._vm.item },
          false
        ),
        [
          _vm.item.config["list-type"] === "picture-card"
            ? _c("i", { staticClass: "el-icon-plus" })
            : _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-upload",
                    size: "small",
                    type: "primary",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.item.config.buttonText) + " ")]
              ),
          _vm.item.config.showTip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " 只能上传不超过 " +
                      _vm._s(_vm.item.config.fileSize) +
                      _vm._s(_vm.item.config.sizeUnit) +
                      "的" +
                      _vm._s(_vm.item.config.accept) +
                      "文件,最多不超过" +
                      _vm._s(_vm.item.limit) +
                      "个文件 "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }