<template>
    <el-date-picker
        v-model="changeValue"
        v-bind="{...item}"
    />
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TDateTime',
    mixins: [mixin],
    props: ['models'],
    created() {
        // models 存在说明为填写页面 表单设计器中无需填充默认值
        console.log(this.models)
        console.log(this.item.config.defaultNowTime)
        if (this.item.config.defaultNowTime && this.models) {
            this.changeValue = new Date()
        }
    }
}
</script>

<style scoped>

</style>
