var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.config.optionType !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "选项样式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.localActiveData.config.optionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData.config, "optionType", $$v)
                    },
                    expression: "localActiveData.config.optionType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "default" } }, [
                    _vm._v(" 默认 "),
                  ]),
                  _c("el-radio-button", { attrs: { label: "button" } }, [
                    _vm._v(" 按钮 "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("config-item-option", {
        attrs: { "active-data": _vm.localActiveData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }