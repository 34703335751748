<template>
    <div class="rt-container">
        <div class="rt-table">
            <div class="tr">
                <div class="td" />
                <div v-for="(col,index) in table.columns" :key="index" class="td">
                    {{ col.label }}
                </div>
            </div>
            <div v-if="!multiple" class="tbody">
                <el-radio-group v-for="row in table.rows" :key="row.id" v-model="dataValue[row.id]"
                                @change="handleChange"
                >
                    <div class="tr">
                        <div class="td">
                            {{ row.label }}
                        </div>
                        <div v-for="(col,index) in table.columns" :key="index" class="td">
                            <el-radio :label="col.label">
                                {{ '' }}
                            </el-radio>
                        </div>
                    </div>
                </el-radio-group>
            </div>
            <div v-else class="tbody">
                <el-checkbox-group v-for="row in table.rows" :key="row.id" v-model="dataValue[row.id]"
                                   @change="handleChange"
                >
                    <div class="tr">
                        <div class="td">
                            {{ row.label }}
                        </div>
                        <div v-for="col in table.columns" :key="col.id" class="td">
                            <el-checkbox :label="col.label">
                                {{ '' }}
                            </el-checkbox>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'MatrixSelect',
    mixins: [mixin],
    props: {
        table: {
            type: Object,
            default: () => {
            }
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // 矩阵单选默认数据结构 {"1":"选项1","2":"选项1","3":"选项1","1636537741435":"选项1"}
            // 矩阵多选默认数据结构 {"1":["选项1","选项2","选项3"],"2":["选项1","选项2","选项3"],"3":["选项1","选项2","选项3"]}
            dataValue: {}
        }
    },
    watch: {
        table: {
            handler(value) {
                this.initValue()
            },
            deep: true
        },
        multiple: {
            handler(value) {
                this.initValue()
            }
        }
    },
    created() {
        this.initValue()
    },
    methods: {
        // 初始化矩阵value为二维数组
        initValue() {
            let initValue = {}
            // 如果为多选
            if (this.multiple) {
                this.table.rows.forEach((row, index) => {
                    if (this.value[row.id]) {
                        initValue[row.id] = this.value[row.id]
                    } else {
                        initValue[row.id] = new Array()
                    }
                })
            } else {
                initValue = this.value
            }
            this.dataValue = initValue
        },
        handleChange() {
            this.value = this.dataValue
            this.$emit('change', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.rt-container {
  padding: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

    ::-webkit-scrollbar
    {
        height: 4px;
        background-color: #fefefe;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
        border-radius: 1px;
        background-color: #fefefe;
    }
    
    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb
    {
        border-radius: 1px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
        background-color: #eee;
    }
  .rt-table {
    width: 100%;
    font-size: 17px;
    color: #606266;
    background-color: #FFF;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border: 1px solid #EBEEF5;
    border-right: none;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    .tbody {
      display: flex;
      flex-direction: column;
    }

    .tr {
      vertical-align: inherit;
      border-color: inherit;
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-align: stretch;
      align-items: stretch;
    }

    .td {
      flex: 50 0 auto;
      width: 50px;
      padding: 12px 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      overflow-wrap: break-word;
      position: relative;
      border-bottom: 1px solid #EBEEF5;
      border-right: 1px solid #EBEEF5;
      text-align: center;
      vertical-align: middle !important;
      font-size: 17px !important;

    }
  }

}

</style>
