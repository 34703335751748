<template>
    <div>
        <el-form-item label="最大值">
            <el-input-number v-model="localActiveData.max" :min="1" placeholder="最大值" />
        </el-form-item>
        <el-form-item
            v-if="localActiveData['allow-half'] !== undefined"
            label="允许半选"
        >
            <el-switch v-model="localActiveData['allow-half']" />
        </el-form-item>
        <el-form-item
            v-if="localActiveData['show-text'] !== undefined"
            label="辅助文字"
        >
            <el-switch
                v-model="localActiveData['show-text']"
                @change="rateTextChange"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData['show-score'] !== undefined"
            label="显示分数"
        >
            <el-switch
                v-model="localActiveData['show-score']"
                @change="rateScoreChange"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData['show-stops'] !== undefined"
            label="显示间断点"
        >
            <el-switch v-model="localActiveData['show-stops']" />
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'ConfigItemRate',
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    },
    methods: {
        rateTextChange(val) {
            if (val) this.localActiveData['show-score'] = false
        },
        rateScoreChange(val) {
            if (val) this.localActiveData['show-text'] = false
        }

    }
}
</script>

<style scoped>

</style>
