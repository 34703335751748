var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-carousel",
    _vm._l(_vm.getOptions(), function (option, index) {
      return _c(
        "el-carousel-item",
        { key: index },
        [
          _c("p", { staticClass: "text-center" }, [
            _vm._v(" " + _vm._s(_vm.item.label) + " "),
          ]),
          _c("el-image", { attrs: { fit: "contain", src: option.image } }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }