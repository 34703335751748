var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.accept !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "文件类型" } },
            [
              _c(
                "el-select",
                {
                  style: { width: "100%" },
                  attrs: { clearable: "", placeholder: "请选择文件类型" },
                  model: {
                    value: _vm.localActiveData.accept,
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "accept", $$v)
                    },
                    expression: "localActiveData.accept",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "图片", value: "image/*" },
                  }),
                  _c("el-option", {
                    attrs: { label: "视频", value: "video/*" },
                  }),
                  _c("el-option", {
                    attrs: { label: "音频", value: "audio/*" },
                  }),
                  _c("el-option", {
                    attrs: { label: "excel", value: ".xls,.xlsx" },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "word",
                      value:
                        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    },
                  }),
                  _c("el-option", { attrs: { label: "pdf", value: ".pdf" } }),
                  _c("el-option", { attrs: { label: "txt", value: ".txt" } }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.config.fileSize !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "文件大小" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入文件大小" },
                  model: {
                    value: _vm.localActiveData.config.fileSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.localActiveData.config,
                        "fileSize",
                        _vm._n($$v)
                      )
                    },
                    expression: "localActiveData.config.fileSize",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "66px" },
                      attrs: { slot: "append" },
                      slot: "append",
                      model: {
                        value: _vm.localActiveData.config.sizeUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.localActiveData.config, "sizeUnit", $$v)
                        },
                        expression: "localActiveData.config.sizeUnit",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "KB", value: "KB" } }),
                      _c("el-option", { attrs: { label: "MB", value: "MB" } }),
                      _c("el-option", { attrs: { label: "GB", value: "GB" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["list-type"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "列表类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.localActiveData["list-type"],
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "list-type", $$v)
                    },
                    expression: "localActiveData['list-type']",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "text" } }, [
                    _vm._v(" text "),
                  ]),
                  _c("el-radio-button", { attrs: { label: "picture" } }, [
                    _vm._v(" picture "),
                  ]),
                  _c("el-radio-button", { attrs: { label: "picture-card" } }, [
                    _vm._v(" picture-card "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.config.showTip !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "显示提示" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.localActiveData.config.showTip,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData.config, "showTip", $$v)
                  },
                  expression: "localActiveData.config.showTip",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "多选文件" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.localActiveData.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "multiple", $$v)
              },
              expression: "localActiveData.multiple",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文件个数" } },
        [
          _c("el-input-number", {
            model: {
              value: _vm.localActiveData.limit,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "limit", $$v)
              },
              expression: "localActiveData.limit",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }