var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "signature-pad" },
    [
      _c(
        "div",
        { staticClass: "signature-wrapper", on: { click: _vm.handleOpen } },
        [
          _vm.signImageUrl
            ? _c(
                "div",
                { staticClass: "img-wrapper" },
                [
                  _c("img", { attrs: { src: _vm.signImageUrl } }),
                  _c(
                    "el-button",
                    { staticClass: "re-sign", attrs: { type: "text" } },
                    [_vm._v(" 重签 ")]
                  ),
                ],
                1
              )
            : _c("p", [_vm._v(" 点击进行手写签名 ")]),
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { padding: "10px" },
          attrs: { position: "bottom", round: "" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("canvas", {
            ref: "signature_pad_box",
            staticClass: "canvasId",
            staticStyle: { border: "2px dashed #f7f7f7" },
          }),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "danger" },
                  on: { click: _vm.clear },
                },
                [_vm._v(" 清除 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "warning" },
                  on: { click: _vm.undo },
                },
                [_vm._v(" 回撤 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.savePng },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }