var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-setting-view" },
    [
      _c(
        "el-form",
        {
          ref: "notifySettingForm",
          attrs: { model: _vm.notifySettingForm, rules: _vm.settingRules },
        },
        [
          _c("p", { staticClass: "project-setting-title" }, [
            _vm._v(" 通知设置 "),
          ]),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 发邮件提醒我 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.notifySettingForm.emailNotify,
                  callback: function ($$v) {
                    _vm.$set(_vm.notifySettingForm, "emailNotify", $$v)
                  },
                  expression: "notifySettingForm.emailNotify",
                },
              }),
            ],
            1
          ),
          _vm.notifySettingForm.emailNotify
            ? _c(
                "el-form-item",
                { attrs: { prop: "newWriteNotifyEmail" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "多个邮箱用 ; 隔开" },
                    model: {
                      value: _vm.notifySettingForm.newWriteNotifyEmail,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.notifySettingForm,
                          "newWriteNotifyEmail",
                          $$v
                        )
                      },
                      expression: "notifySettingForm.newWriteNotifyEmail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 发微信提醒我 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.notifySettingForm.wxNotify,
                  callback: function ($$v) {
                    _vm.$set(_vm.notifySettingForm, "wxNotify", $$v)
                  },
                  expression: "notifySettingForm.wxNotify",
                },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "project-setting-sub-label sub-label-text" },
            [
              _vm._v(" 需要关注公众号 "),
              _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.openSubNotifyWxDialogHandle },
                },
                [_vm._v(" TDUCK ")]
              ),
            ],
            1
          ),
          _vm.notifySettingForm.newWriteNotifyWx
            ? _c(
                "el-row",
                { attrs: { align: "middle", type: "flex" } },
                [
                  _c("el-col", { attrs: { offset: 3, span: 5 } }, [
                    _c("p", { staticClass: "project-setting-sub-label" }, [
                      _vm._v(" 提醒人： "),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    _vm._l(_vm.subNotifyWxUserList, function (user, i) {
                      return _c(
                        "span",
                        { key: i, staticClass: "sub-user-view" },
                        [
                          _c("i", {
                            staticClass: "el-icon-close sub-user-delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteSubNotifyUserHandle(i)
                              },
                            },
                          }),
                          _c("el-avatar", { attrs: { src: user.headImgUrl } }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "submit-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveSettingHandle },
                },
                [_vm._v(" 保存设置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogSubNotifyVisible,
                title: "微信扫描二维码订阅",
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogSubNotifyVisible = $event
                },
              },
            },
            [
              _c("el-image", {
                staticStyle: { width: "150px", height: "150px" },
                attrs: { src: _vm.subNotifyWxQrCode, fit: "fill" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }