var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "至少应选" } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v(" 至少应选 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content:
                      "至少应选需要和最多可选一起使用，并且小于最多可选，否则将导致多选无法选中",
                    effect: "dark",
                    placement: "top-start",
                  },
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              ),
            ],
            1
          ),
          _c("el-input-number", {
            attrs: {
              min: 0,
              value: _vm.localActiveData.min,
              placeholder: "至少应选",
            },
            on: {
              input: function ($event) {
                return _vm.$set(
                  _vm.localActiveData,
                  "min",
                  $event ? $event : undefined
                )
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最多可选" } },
        [
          _c("el-input-number", {
            attrs: {
              min: 0,
              value: _vm.localActiveData.max,
              placeholder: "最多可选",
            },
            on: {
              input: function ($event) {
                return _vm.$set(
                  _vm.localActiveData,
                  "max",
                  $event ? $event : undefined
                )
              },
            },
          }),
        ],
        1
      ),
      _vm.localActiveData.config.optionType !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "选项样式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.localActiveData.config.optionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData.config, "optionType", $$v)
                    },
                    expression: "localActiveData.config.optionType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "default" } }, [
                    _vm._v(" 默认 "),
                  ]),
                  _c("el-radio-button", { attrs: { label: "button" } }, [
                    _vm._v(" 按钮 "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("config-item-option", {
        attrs: { "active-data": _vm.localActiveData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }