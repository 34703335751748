<template>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" append-to-body>
        <generate-form
            v-if="formConf.fields.length"
            :key="formParseKey"
            ref="generateForm"
            :form-conf="formConf"
            :page-form-model="formModel"
        />
        <el-button @click="dialogFormVisible=false">
            取 消
        </el-button>
        <el-button type="primary" @click="handleUpdate">
            确 定
        </el-button>
    </el-dialog>
</template>

<script>
export default {
    name: 'AddOrUpdate',
    components: {},
    props: {
        fields: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            formParseKey: new Date().getTime(),
            dialogFormVisible: false,
            formModel: {},
            title: '新增',
            formConf: {
                fields: [],
                formKey: '',
                disabled: false,
                span: 24,
                size: 'small',
                labelPosition: 'top',
                labelWidth: 100,
                formRules: 'rules',
                gutter: 15,
                formBtns: false,
                resetBtn: false,
                theme: {
                    backgroundImg: '',
                    showTitle: true,
                    showDescribe: true,
                    showNumber: false,
                    btnsColor: null
                }
            }
        }
    },
    watch: {
        fields: {
            handler: function(val, oldVal) {
                this.formConf.fields = val
            },
            immediate: true
        }
    },
    beforeCreate() {
        this.$options.components.GenerateForm = require('../../GenerateForm/GenerateForm').default
    },
    methods: {
        handleUpdate() {
            this.msgSuccess('保存成功')
            this.dialogFormVisible = false
            this.$emit('submit', this.$refs.generateForm.formModel)
        },
        showDialog(data) {
            if (data) {
                this.formModel = data
                this.title = '修改'
            } else {
                this.formModel = {}
                this.title = '新增'
            }
            this.dialogFormVisible = true
            this.formParseKey += new Date().getTime()
        }
    }
}
</script>

<style scoped>

</style>
