<template>
    <div class="write-container">
        <div class="form-container">
            <div class="project-form-wrapper">
                <div class="project-form">
                    <h4 class="form-name-text" style="text-align: center;" v-html="questionTitle" />
                    <div class="form-description-text" v-html="description" />
                    <generate-form
                        v-if="formConf.fields.length && formModel"
                        :key="formParseKey"
                        ref="generateForm"
                        :logic-trigger-item-list.sync="logicTriggerItemList"
                        :form-conf="formConf"
                        :page-form-model="formModel"
                        style="margin-bottom: 40px"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GenerateForm } from '@/index'
import { geturlparam } from '@/utils'
import {
    viewFormListResultRequest
} from '@/api/project/data'
import {
    listFormDetailsRequest
} from '@/api/project/form'
import {request} from '@/api/request'
import {dbDataConvertForItemJson} from '@/utils/convert'
import _ from 'lodash'
import {getExpression} from '@/utils/expression'

export default {
    name: 'Preview',
    components: {
        GenerateForm
    },
    data() {
        return {
            logicTriggerItemList: [],
            logicShowTriggerRule: {},
            formParseKey: new Date().getTime(),
            formConf: {
                fields: [],
                formKey: '',
                disabled: true,
                span: 24,
                size: 'small',
                labelPosition: 'top',
                labelWidth: 100,
                formRules: 'rules',
                gutter: 15,
                formBtns: false,
                resetBtn: false,
                pageTextShow: false,
                theme: {
                    backgroundImg: '',
                    showTitle: true,
                    showDescribe: true,
                    showNumber: false,
                    btnsColor: null
                }
            },
            formModel: {},
            businessJson: '{}',
            questionTitle: '问卷名',
            description: '为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位 用户的宝贵意见，期待您的参与！现在我们就马上开始吧！'
        }
    },
    created() {
        // 父子组件props获取项目key
        if (this.formConfig && this.formConfig.formKey) {
            this.formConf.formKey = this.formConfig.formKey
            if (this.formConfig.formKind) {
                this.formConf.formKind = this.formConfig.formKind
            }
            // iframe 从路由中尝试获取
        } else if (this.$route.query.key) {
            this.formConf.formKey = this.$route.query.key
            if (this.$route.query.kind) {
                this.formConf.formKind = this.$route.query.kind
            }
            this.formConf.formBtns = true
        }
        this.formConf.size = window.innerWidth < 480 ? 'medium' : 'small'
    },
    async mounted() {

        await this.setFields()
        // 是模板还是用户自定义表单
        let url = `/user/form/details/${this.formConf.formKey}`
        if (this.formConf.formKind === 2) {
            url = `/form/template/details/${this.formConf.formKey}`
        }

        // 请求表单详细数据
        request.get(url).then(res => {
            if (res.data) {
                let data = res.data
                let form = data.form
                this.questionTitle = form.name
                this.description = form.description
                // 处理逻辑表单
                let logicItemList = res.data.formLogic ? res.data.formLogic.scheme : []
                const logicItemMap = new Map()
                logicItemList.forEach(item => {
                    logicItemMap.set(item.formItemId, item)
                    this.logicShowTriggerHandle(item)
                })
                // 主题数据
                if (res.data.userFormTheme) {
                    this.formConf.theme = res.data.userFormTheme
                    const {
                        submitBtnText,
                        showNumber,
                        btnsColor
                    } = res.data.userFormTheme
                    if (submitBtnText) this.formConf.theme.submitBtnText = submitBtnText
                    if (showNumber) this.formConf.theme.showNumber = showNumber
                    if (btnsColor) this.formConf.theme.submitBtnColor = btnsColor
                }
                // 处理表单问题项
                const fields = res.data.formItems.map(item => {
                    const projectItem = dbDataConvertForItemJson(item)
                    // 是否是逻辑控制显示字段
                    projectItem.logicShowField = logicItemMap.get(projectItem.formItemId) !== undefined
                    return projectItem
                })
                if (_.keys(this.formConf.perPageFields).length !== 0) {
                    this.formConf.fields = _.get(this.formConf.perPageFields, 1)
                    this.formConf.formBtns = false
                } else {
                    this.formConf.fields = fields
                }
                if (res.data.form) {
                    this.formConf.title = res.data.form.name
                    document.title = res.data.form.name
                    this.formConf.description = res.data.form.description
                }
                this.formConf.logicShowRule = this.logicShowTriggerRule

                this.startParser = true
            }
        })

    },
    methods: {
        async setFields() {
            let key = this.$route.query.key || this.$route.params.key
            console.info(key)
            this.formConf.formKey = key
            // url携带参数
            const businessJson = JSON.stringify(geturlparam())
            console.info(this.formConf.formKey)
            console.info(businessJson)
            await viewFormListResultRequest(this.formConf.formKey, businessJson).then(response => {
                console.info(response)
                if (response.code == 200) {
                    console.info('我是2', this.formModel)
                    let data = response.data
                    if (data && data[0] && data[0]['originalDataString']) {
                        let originalData = JSON.parse(data[0]['originalDataString'])
                        this.formModel = Object.assign(originalData, data[0]) || {}
                    }
                }
            })
            console.info(this.formModel)
        },
        /**
         * 处理逻辑显示数据
         */
        logicShowTriggerHandle(logicItem) {
            if (!logicItem) {
                return
            }
            // 建立触发关系 该字段值发生变化时 哪些问题需要进行逻辑判断 确定是否显示
            logicItem.conditionList.forEach(item => {
                if (Object.keys(item).length === 0) {
                    return
                }
                let rules = this.logicShowTriggerRule[item.formItemId]
                if (!rules) {
                    rules = []
                }
                rules.push({
                    // 被触发显示的字段的ID
                    triggerFormItemId: logicItem.formItemId,
                    //  显示逻辑表达式
                    logicExpression: getExpression(logicItem.conditionList, logicItem.expression)
                })
                this.logicShowTriggerRule[item.formItemId] = rules
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.write-container {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.project-form-wrapper {
    background-image: url("~@/assets/images/back-image.png");
    background-color: #DAF6F2;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
}

.title-icon-view {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.form-container {
  height: 100%;
}

.icon-view {
  width: 59px;
  height: 59px;
  border-radius: 100px;
  background-color: #0076ff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.success-icon {
  text-align: center;
  color: white;
  font-size: 30px;
}
</style>

<style lang="scss">
@import "../../../../assets/styles/elementui-mobile";

.project-form-wrapper {
  width: 100%;
  min-height: 100%;
  padding: 20px 0px;
  background-color: rgba(158, 207, 250, 0.3);
}

.project-form {
  margin: 0px auto;
  width: 800px;
  padding: 15px;
  background-repeat: repeat;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.project-form .describe-html img {
  max-width: 780px;
  margin: 0px;
}

.project-body::-webkit-scrollbar {
  width: 0 !important;
  background: transparent;
}

.project-body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.logo-img {
  max-height: 120px;
}

.submit-btn-form-item button {
  width: 20%;
}

.support-text {
  color: #545454;
  text-shadow: 0 1px 1px #e9e9e9;
  margin-top: 20px;
}

.form-description-text {
    padding: 6px 10px;
    border: 1px dashed transparent;
    margin: 0
}

@media screen and (max-width: 750px) {
    .form-description-text {
        font-size: 14px;
    }
    .el-form-item__label {
        font-size: 16px;
    }

    .el-checkbox__label {
        font-size: 16px;
    }
    .el-radio__label {
        font-size: 16px;
    }
    .el-input--medium {
        font-size: 16px;
    }
    .el-form-item__content {
        font-size: 16px;
    }
  .project-form {
      width: 90% !important;
      border-radius: 8px;
  }
  .logo-img {
    max-height: 2.94rem;
  }
  .submit-btn-form-item {
    text-align: center;
  }
  .submit-btn-form-item button {
    width: 80%;
  }
  .project-form .describe-html img {
    width: 95vw !important;
  }
}
</style>
