var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-setting-view" },
    [
      _c("p", { staticClass: "project-setting-title" }, [_vm._v(" 回收设置 ")]),
      _c(
        "el-form",
        {
          ref: "writeSettingForm",
          attrs: { model: _vm.writeSettingForm, rules: _vm.settingRules },
        },
        [
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 只在微信中填写 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.writeSettingForm.wxWrite,
                  callback: function ($$v) {
                    _vm.$set(_vm.writeSettingForm, "wxWrite", $$v)
                  },
                  expression: "writeSettingForm.wxWrite",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 定时收集表单 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.writeSettingForm.timedCollection,
                  callback: function ($$v) {
                    _vm.$set(_vm.writeSettingForm, "timedCollection", $$v)
                  },
                  expression: "writeSettingForm.timedCollection",
                },
              }),
            ],
            1
          ),
          _vm.writeSettingForm.timedCollection
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-row",
                        { attrs: { align: "middle", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  align: "center",
                                  placeholder: "收集开始时间",
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value:
                                    _vm.writeSettingForm
                                      .timedCollectionBeginTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.writeSettingForm,
                                      "timedCollectionBeginTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "writeSettingForm.timedCollectionBeginTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  align: "center",
                                  placeholder: "收集结束时间",
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value:
                                    _vm.writeSettingForm.timedCollectionEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.writeSettingForm,
                                      "timedCollectionEndTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "writeSettingForm.timedCollectionEndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "timedNotEnabledPromptText",
                        rules: [
                          {
                            required: true,
                            message: "请输入未启用提示语",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入未启用提示语" },
                        model: {
                          value: _vm.writeSettingForm.timedNotEnabledPromptText,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.writeSettingForm,
                              "timedNotEnabledPromptText",
                              $$v
                            )
                          },
                          expression:
                            "writeSettingForm.timedNotEnabledPromptText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "timedDeactivatePromptText",
                        rules: [
                          {
                            required: true,
                            message: "请输入停用后提示语",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入停用后提示语" },
                        model: {
                          value: _vm.writeSettingForm.timedDeactivatePromptText,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.writeSettingForm,
                              "timedDeactivatePromptText",
                              $$v
                            )
                          },
                          expression:
                            "writeSettingForm.timedDeactivatePromptText",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 定时定量表单 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.writeSettingForm.timedQuantitativeCollection,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.writeSettingForm,
                      "timedQuantitativeCollection",
                      $$v
                    )
                  },
                  expression: "writeSettingForm.timedQuantitativeCollection",
                },
              }),
            ],
            1
          ),
          _vm.writeSettingForm.timedQuantitativeCollection
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-row",
                        { attrs: { align: "middle", type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  align: "center",
                                  placeholder: "收集开始时间",
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value:
                                    _vm.writeSettingForm
                                      .timedCollectionBeginTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.writeSettingForm,
                                      "timedCollectionBeginTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "writeSettingForm.timedCollectionBeginTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  align: "center",
                                  placeholder: "收集结束时间",
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value:
                                    _vm.writeSettingForm.timedCollectionEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.writeSettingForm,
                                      "timedCollectionEndTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "writeSettingForm.timedCollectionEndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "timedNotEnabledPromptText",
                        rules: [
                          {
                            required: true,
                            message: "请输入未启用提示语",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入未启用提示语" },
                        model: {
                          value: _vm.writeSettingForm.timedNotEnabledPromptText,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.writeSettingForm,
                              "timedNotEnabledPromptText",
                              $$v
                            )
                          },
                          expression:
                            "writeSettingForm.timedNotEnabledPromptText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "timedDeactivatePromptText",
                        rules: [
                          {
                            required: true,
                            message: "请输入停用后提示语",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入停用后提示语" },
                        model: {
                          value: _vm.writeSettingForm.timedDeactivatePromptText,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.writeSettingForm,
                              "timedDeactivatePromptText",
                              $$v
                            )
                          },
                          expression:
                            "writeSettingForm.timedDeactivatePromptText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "timedQuantitativeQuantity",
                        rules: [
                          {
                            required: true,
                            message: "请输入定量表单填写数量",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入定量表单填写数量" },
                        model: {
                          value: _vm.writeSettingForm.timedQuantitativeQuantity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.writeSettingForm,
                              "timedQuantitativeQuantity",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "writeSettingForm.timedQuantitativeQuantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "timedEndPromptText",
                        rules: [
                          {
                            required: true,
                            message: "请输入收集完成提示语",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入收集完成提示语" },
                        model: {
                          value: _vm.writeSettingForm.timedEndPromptText,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.writeSettingForm,
                              "timedEndPromptText",
                              _vm._n($$v)
                            )
                          },
                          expression: "writeSettingForm.timedEndPromptText",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [
                _vm._v(" 每个微信仅填写一次 "),
              ]),
              _c("el-switch", {
                on: {
                  change: () => {
                    _vm.writeSettingForm.recordWxUser = true
                    _vm.writeSettingForm.wxWrite = true
                  },
                },
                model: {
                  value: _vm.writeSettingForm.wxWriteOnce,
                  callback: function ($$v) {
                    _vm.$set(_vm.writeSettingForm, "wxWriteOnce", $$v)
                  },
                  expression: "writeSettingForm.wxWriteOnce",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c(
                "p",
                { staticClass: "label" },
                [
                  _vm._v(" 每人限制填写1次 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: "根据IP地址限制填写",
                        effect: "dark",
                        placement: "top-start",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-warning" })]
                  ),
                ],
                1
              ),
              _c("el-switch", {
                model: {
                  value: _vm.writeSettingForm.everyoneWriteOnce,
                  callback: function ($$v) {
                    _vm.$set(_vm.writeSettingForm, "everyoneWriteOnce", $$v)
                  },
                  expression: "writeSettingForm.everyoneWriteOnce",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [
                _vm._v(" 每人每天限制填写1次 "),
              ]),
              _c("el-switch", {
                model: {
                  value: _vm.writeSettingForm.everyoneDayWriteOnce,
                  callback: function ($$v) {
                    _vm.$set(_vm.writeSettingForm, "everyoneDayWriteOnce", $$v)
                  },
                  expression: "writeSettingForm.everyoneDayWriteOnce",
                },
              }),
            ],
            1
          ),
          _vm.writeSettingForm.everyoneDayWriteOnce ||
          _vm.writeSettingForm.everyoneWriteOnce
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "writeOncePromptText",
                    rules: [
                      {
                        required: true,
                        message: "请输入重复填写后提示语",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入重复填写后提示语" },
                    model: {
                      value: _vm.writeSettingForm.writeOncePromptText,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.writeSettingForm,
                          "writeOncePromptText",
                          _vm._n($$v)
                        )
                      },
                      expression: "writeSettingForm.writeOncePromptText",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [
                _vm._v(" 记录微信用户个人信息 "),
              ]),
              _c("el-switch", {
                on: {
                  change: (val) => {
                    if (!_vm.writeSettingForm.wxWrite && val) {
                      _vm.writeSettingForm.wxWrite = true
                    }
                  },
                },
                model: {
                  value: _vm.writeSettingForm.recordWxUser,
                  callback: function ($$v) {
                    _vm.$set(_vm.writeSettingForm, "recordWxUser", $$v)
                  },
                  expression: "writeSettingForm.recordWxUser",
                },
              }),
            ],
            1
          ),
          _c("el-row", [
            _c("p", { staticClass: "project-setting-sub-label" }, [
              _vm._v(" * 开启后将会授权登录，提供微信昵称、性别、城市信息 "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "submit-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveSettingHandle },
                },
                [_vm._v(" 保存设置 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }