var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "status-container" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 404，联系一下开发者 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }