<template>
    <div>
        <el-form-item
            v-if="localActiveData.prepend !== undefined"
            label="前缀"
        >
            <el-input
                v-model="localActiveData.prepend"
                placeholder="请输入前缀"
            />
        </el-form-item>
        <el-form-item
            v-if=" localActiveData.__slot__ && localActiveData.append !== undefined "
            label="后缀"
        >
            <el-input
                v-model="localActiveData.append"
                placeholder="请输入后缀"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData['prefix-icon'] !== undefined"
            label="前图标"
        >
            <el-input
                v-model="localActiveData['prefix-icon']"
                placeholder="请输入前图标名称"
            >
                <el-button
                    slot="append"
                    icon="el-icon-thumb"
                    @click="openIconsDialog('prefix-icon')"
                >
                    选择
                </el-button>
            </el-input>
        </el-form-item>
        <el-form-item
            v-if="localActiveData['suffix-icon'] !== undefined"
            label="后图标"
        >
            <el-input
                v-model="localActiveData['suffix-icon']"
                placeholder="请输入后图标名称"
            >
                <el-button
                    slot="append"
                    icon="el-icon-thumb"
                    @click="openIconsDialog('suffix-icon')"
                >
                    选择
                </el-button>
            </el-input>
        </el-form-item>
        <el-form-item
            v-if="localActiveData.autosize !== undefined"
            label="最小行数"
        >
            <el-input-number
                v-model="localActiveData.autosize.minRows"
                :min="1"
                placeholder="最小行数"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData.autosize !== undefined"
            label="最大行数"
        >
            <el-input-number
                v-model="localActiveData.autosize.maxRows"
                :min="1"
                placeholder="最大行数"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData.maxlength !== undefined"
            label="最多输入"
        >
            <el-input
                v-model="localActiveData.maxlength"
                placeholder="请输入字符长度"
            >
                <template slot="append">
                    个字符
                </template>
            </el-input>
        </el-form-item>
        <el-form-item
            v-if="localActiveData['show-word-limit'] !== undefined"
            label="输入统计"
        >
            <el-switch v-model="localActiveData['show-word-limit']" />
        </el-form-item>
        <div
            v-if="localActiveData.config.dataType"
            class="reg-item"
        >
            <el-form-item v-if="localActiveData.config.dataType" label="反馈类型">
                <el-select v-model="localActiveData.config.dataType.type" size="mini" @change="dataTypeChange">
                    <el-option v-for="option in dataTypeList" :key="option.value" :value="option.value" :label="option.label" />
                </el-select>
            </el-form-item>
            <el-form-item v-if="localActiveData.config.dataType.type" label="错误提示" style="margin-bottom: 0">
                <el-input v-model="localActiveData.config.dataType.message" placeholder="请输入错误提示" />
            </el-form-item>
        </div>
        <icons-dialog
            :current="localActiveData[currentIconModel]"
            :visible.sync="iconsVisible"
            @select="setIcon"
        />
    </div>
</template>

<script>
import IconsDialog from '../IconsDialog'

export default {
    name: 'ConfigItemInput',
    components: {
        IconsDialog
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData,
            dataTypeList: [
                {
                    value: null,
                    label: '无校验'
                },
                {
                    value: 'string',
                    label: '字符串'
                }, {
                    value: 'number',
                    label: '数字'
                }, {
                    value: 'boolean',
                    label: '布尔值'
                }, {
                    value: 'integer',
                    label: '整数'
                }, {
                    value: 'float',
                    label: '小数'
                }, {
                    value: 'url',
                    label: 'URL地址'
                }, {
                    value: 'email',
                    label: '邮箱地址'
                }, {
                    value: 'phone',
                    label: '手机号'
                }, {
                    value: 'identity',
                    label: '身份证号'
                }, {
                    value: 'hex',
                    label: '十六进制'
                }
            ],
            iconsVisible: false,
            currentIconModel: null
        }
    },
    methods: {
        dataTypeChange(value) {
            this.localActiveData.config.dataType.message = `请输入正确的${this.dataTypeList.find(item => item.value === value).label}`
        },
        openIconsDialog(model) {
            this.iconsVisible = true
            this.currentIconModel = model
        },
        setIcon(val) {
            this.localActiveData[this.currentIconModel] = val
        }
    }
}
</script>

<style scoped>

</style>
