var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "account-validate-container" }, [
    _vm.bindEmailErr
      ? _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "align-items": "center",
            },
          },
          [
            _c("el-alert", {
              attrs: {
                title: "绑定失败",
                type: "error",
                closable: false,
                center: true,
                description:
                  "绑定邮箱失败，可能是超出绑定时间或者是该邮箱已被绑定等，请稍后重试！",
                "show-icon": "",
              },
            }),
            _c(
              "el-button",
              {
                staticStyle: { width: "50%" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ path: "/home" })
                  },
                },
              },
              [_vm._v("返回首页")]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "align-items": "center",
            },
          },
          [
            _c("el-alert", {
              attrs: {
                title: "绑定成功",
                type: "success",
                closable: false,
                center: true,
                description: "你现在可以通过邮箱登录",
                "show-icon": "",
              },
            }),
            _c(
              "el-button",
              {
                staticStyle: { width: "50%" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ path: "/home" })
                  },
                },
              },
              [_vm._v("返回首页")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }