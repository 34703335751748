<template>
    <div>
        <el-upload
            v-bind="{ ...item }"
            :action="item.action"
            :before-upload="beforeUpload"
            :file-list="changeValue || []"
            :on-preview="handlePreview"
            :on-remove="uploadRemove"
            :on-success="uploadSuccess"
        >
            <i
                v-if="item.config['list-type'] === 'picture-card'"
                class="el-icon-plus"
            />
            <el-button v-else icon="el-icon-upload" size="small" type="primary">
                {{ item.config.buttonText }}
            </el-button>
            <div v-if="item.config.showTip" slot="tip" class="el-upload__tip">
                只能上传不超过 {{ item.config.fileSize }}{{ item.config.sizeUnit }}的{{
                    item.config.accept
                }}文件,最多不超过{{ item.limit }}个文件
            </div>
        </el-upload>
    </div>
</template>

<script>
import uploadMixin from '../uploadMixin'
import mixin from '../mixin'

export default {
    name: 'TUpload',
    mixins: [uploadMixin, mixin],
    methods: {
        // 上传成功
        uploadSuccess(response, file, fileList) {
            let oldFileList = this.changeValue
            if (!oldFileList) {
                oldFileList = []
            }
            oldFileList.push({ name: file.name, url: response.data })
            this.changeValue = oldFileList
        },
        handlePreview(file) {
            window.open(file.url)
        },
        // 上传成功
        uploadRemove(file, fileList) {
            this.changeValue = fileList.map(item => ({
                name: item.name,
                url: item.response.data
            }))
        }
    }
}
</script>

<style scoped></style>
