<template>
    <div v-html="content" />
</template>

<script>
export default {
    name: 'DescText',
    props: {
        content: {
            type: String,
            default: ''
        }
    },
    methods: {}
}
</script>
