<template>
    <div class="rt-container">
        <div class="rt-table">
            <table v-if="dataValue" class="el-table el-table--border" align="center">
                <tbody>
                    <tr>
                        <td width="100" />
                        <td v-for="(col,index) in table.columns" :key="index">
                            {{ col.label }}
                        </td>
                    </tr>
                    <tr v-for="(row,rIndex) in table.rows" :key="rIndex">
                        <td>
                            {{ row.label }}
                        </td>
                        <td v-for="(col,cIndex) in table.columns" :key="cIndex" width="100">
                            <el-input v-model="dataValue[row.id][col.id]" @input.native="handleChange($event,rIndex,cIndex)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'MatrixInput',
    mixins: [mixin],
    props: {
        table: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            dataValue: []
        }
    },
    watch: {
        table: {
            handler(value) {
                this.initValue()
            },
            deep: true
        }
    },
    created() {
        this.initValue()
    },
    methods: {
        // 初始化矩阵value为二维数组
        initValue() {
            let initValue = {}
            this.table.rows.forEach(row => {
                initValue[row.id] = {}
                this.table.columns.forEach(col => {
                    if (this.value[row.id] && this.value[row.id][col.id]) {
                        initValue[row.id][col.id] = this.value[row.id][col.id]
                    } else {
                        initValue[row.id][col.id] = null
                    }
                })
            })
            this.dataValue = initValue
        },
        handleChange() {
            this.value = this.dataValue
            this.$emit('change', this.dataValue)
        }
    }
}
</script>

<style lang="scss" scoped>

.rt-container {
  padding: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  .rt-table {
    width: 750px;
    .el-table {
        ::-webkit-scrollbar
        {
            height: 4px;
            background-color: #fefefe;
        }
        /*定义滚动条轨道 内阴影+圆角*/
        ::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
            border-radius: 3px;
            background-color: #fefefe;
        }
        
        /*定义滑块 内阴影+圆角*/
        ::-webkit-scrollbar-thumb
        {
            border-radius: 3px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
            background-color: #eee;
        }
    }
  }

  th, td {
    text-align: center;
    vertical-align: middle !important;
      font-size: 17px;
  }

  .el-input {
    width: 70%;
  }
}

</style>
