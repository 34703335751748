<template>
    <div class="phone-number-container">
        <el-input
            v-model="inputValue"
            class="input-with-select"
            maxlength="11"
            placeholder="点击验证手机号" readonly @click.native="openDialogHandle"
        >
            <i slot="prefix" class="el-input__icon el-icon-mobile" />
            <el-button slot="append" icon="el-icon-message" @click="openDialogHandle" />
        </el-input>
        <van-popup v-model="open" position="bottom" round style="padding: 20px">
            <el-form ref="form" :model="phoneValidateForm" :rules="rules">
                <el-form-item label="手机号" prop="phoneNumber">
                    <el-input v-model="phoneValidateForm.phoneNumber" class="input" maxlength="11" prefix-icon=" el-icon-mobile "
                              show-word-limit
                    />
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <el-input v-model="phoneValidateForm.code" class="input" prefix-icon="el-icon-message">
                        <el-button
                            slot="append"
                            :disabled="phoneValidateCodeBtn"
                            type="primary"
                            @click="sendValidateMsgHandle"
                        >
                            {{ phoneValidateCodeBtnText }}
                        </el-button>
                    </el-input>
                </el-form-item>
                <el-form-item class="text-center" style="margin-top: 40px">
                    <el-button @click="open = false">
                        取 消
                    </el-button>
                    <el-button type="primary" @click="validateCodeHandle">
                        确 定
                    </el-button>
                </el-form-item>
            </el-form>
        </van-popup>
    </div>
</template>

<script>
import { Popup } from 'vant'
import 'vant/lib/popup/style'
import mixin from './mixin'

export default {
    name: 'MobilePhoneVerification',
    components: {
        [Popup.name]: Popup
    },
    mixins: [mixin]
}
</script>
<style lang="scss" scoped>
</style>
