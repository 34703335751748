var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _vm.formConf.fields.length
        ? _c("generate-form", {
            key: _vm.formParseKey,
            ref: "generateForm",
            attrs: {
              "form-conf": _vm.formConf,
              "page-form-model": _vm.formModel,
            },
          })
        : _vm._e(),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              _vm.dialogFormVisible = false
            },
          },
        },
        [_vm._v(" 取 消 ")]
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleUpdate } },
        [_vm._v(" 确 定 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }