// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/back-image.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".write-container[data-v-491ec8a4] {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  width: 100%;\n  overflow-x: hidden;\n}\n.project-form-wrapper[data-v-491ec8a4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-color: #DAF6F2;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  background-position: top;\n}\n.title-icon-view[data-v-491ec8a4] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-line-pack: center;\n      align-content: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  height: 100%;\n  width: 100%;\n}\n.form-container[data-v-491ec8a4] {\n  height: 100%;\n}\n.icon-view[data-v-491ec8a4] {\n  width: 59px;\n  height: 59px;\n  border-radius: 100px;\n  background-color: #0076ff;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-line-pack: center;\n      align-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.success-icon[data-v-491ec8a4] {\n  text-align: center;\n  color: white;\n  font-size: 30px;\n}", ""]);
// Exports
module.exports = exports;
