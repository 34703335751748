var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.createProjectLoading,
          expression: "createProjectLoading",
        },
      ],
      staticClass: "template-preview-container",
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("el-page-header", {
            attrs: { content: "模板详情" },
            on: {
              back: function ($event) {
                return _vm.$router.back(-1)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "template-preview-content" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "template-scrollbar" },
            [
              _vm.formConfig.formKey
                ? _c("biz-project-form", {
                    attrs: { "form-config": _vm.formConfig },
                    on: { submit: _vm.submitForm },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "use-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.createProjectByTemplate },
                },
                [_vm._v(" 使用该模板 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }