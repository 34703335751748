var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-form-wrapper", style: _vm.getBackgroundStyle },
    [
      _c(
        "div",
        { staticClass: "project-form" },
        [
          _c("div", { style: { textAlign: _vm.getFormTheme.logoPosition } }, [
            _c("img", {
              staticClass: "logo-img",
              attrs: { src: _vm.getFormTheme.logoImg },
            }),
          ]),
          _vm.getFormTheme.headImgUrl
            ? _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.getFormTheme.headImgUrl, fit: "scale-down" },
              })
            : _vm._e(),
          _vm.getFormTheme.showTitle
            ? _c(
                "h4",
                {
                  staticClass: "form-name-text",
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v(" " + _vm._s(_vm.formConf.title) + " ")]
              )
            : _vm._e(),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getFormTheme.showDescribe,
                expression: "getFormTheme.showDescribe",
              },
            ],
            staticClass: "form-name-text describe-html",
            domProps: { innerHTML: _vm._s(_vm.formConf.description) },
          }),
          _vm.questionMode ? _c("el-divider") : _vm._e(),
          _vm.startParser
            ? _c("parser", {
                key: _vm.parserKey,
                attrs: {
                  "form-conf": _vm.tempFormConf,
                  "logic-trigger-item-list": _vm.logicTriggerItemList,
                  "page-form-model": _vm.pageFormModel,
                },
                on: {
                  "update:logicTriggerItemList": function ($event) {
                    _vm.logicTriggerItemList = $event
                  },
                  "update:logic-trigger-item-list": function ($event) {
                    _vm.logicTriggerItemList = $event
                  },
                  "update:pageFormModel": function ($event) {
                    _vm.pageFormModel = $event
                  },
                  "update:page-form-model": function ($event) {
                    _vm.pageFormModel = $event
                  },
                  next: _vm.nextPage,
                  prev: _vm.prevPage,
                  submit: _vm.submitForm,
                  changeForm: _vm.changeForm,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      false
        ? _c("p", { staticClass: "text-center support-text" }, [
            _vm._v(" " + _vm._s(_vm.getDevSupport) + "提供技术支持 "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }