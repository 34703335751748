<template>
    <div>
        <div v-if="localActiveData.table.rows">
            <el-divider>题目</el-divider>
            <draggable
                :animation="340"
                :list="localActiveData.table.rows"
                group="selectItem"
                handle=".option-drag"
            >
                <div
                    v-for="(item, index) in localActiveData.table.rows"
                    :key="index"
                    class="select-item"
                >
                    <div class="select-line-icon option-drag">
                        <i class="el-icon-s-operation" />
                    </div>
                    <el-input
                        v-model="item.label"
                        placeholder="选项名"
                        size="small"
                    />
                    <div
                        class="close-btn select-line-icon"
                        @click="localActiveData.table.rows.splice(index, 1)"
                    >
                        <i class="el-icon-remove-outline" />
                    </div>
                </div>
            </draggable>
            <div style="margin-left: 20px;margin-bottom: 10px">
                <el-button
                    icon="el-icon-circle-plus-outline"
                    style="padding-bottom: 0"
                    type="text"
                    @click="addSelectItem(localActiveData.table.rows)"
                >
                    添加选项
                </el-button>
                <el-button
                    icon="el-icon-document-copy"
                    style="padding-bottom: 0"
                    type="text"
                    @click="openBatchEditDialogHandle('rows')"
                >
                    批量编辑
                </el-button>
            </div>
        </div>
        <div v-if="localActiveData.table.columns">
            <el-divider>选项</el-divider>
            <draggable
                :animation="340"
                :list="localActiveData.table.columns"
                group="selectItem"
                handle=".option-drag"
            >
                <div
                    v-for="(item, index) in localActiveData.table.columns"
                    :key="index"
                    class="select-item"
                >
                    <div class="select-line-icon option-drag">
                        <i class="el-icon-s-operation" />
                    </div>
                    <el-input
                        v-model="item.label"
                        placeholder="选项名"
                        size="small"
                    />
                    <div
                        class="close-btn select-line-icon"
                        @click="localActiveData.table.columns.splice(index, 1)"
                    >
                        <i class="el-icon-remove-outline" />
                    </div>
                </div>
            </draggable>
            <div style="margin-left: 20px;margin-bottom: 10px">
                <el-button
                    icon="el-icon-circle-plus-outline"
                    style="padding-bottom: 0"
                    type="text"
                    @click="addSelectItem(localActiveData.table.columns)"
                >
                    添加选项
                </el-button>
                <el-button
                    icon="el-icon-document-copy"
                    style="padding-bottom: 0"
                    type="text"
                    @click="openBatchEditDialogHandle('columns')"
                >
                    批量编辑
                </el-button>
            </div>
        </div>
        <el-dialog
            title="批量编辑"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span class="desc-text">每行对应一个选项</span>
            <el-input v-model="batchItemValue" type="textarea" :autosize="{ minRows: 20, maxRows: 5000}"
                      placeholder="选项1 选项2  选项3 "
            />
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="batchEditSelectItems">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'

export default {
    name: 'MatrixOption',
    components: {
        draggable
    },
    props: ['activeData'],
    data() {
        return {
            batchItemValue: '',
            batchDataKey: '',
            dialogVisible: false,
            localActiveData: this.activeData
        }
    },
    methods: {
        addSelectItem(data) {
            data.push({
                id: new Date().getTime(),
                label: ''
            })
        },
        openBatchEditDialogHandle(key) {
            this.batchDataKey = key
            let data = this.localActiveData.table[key]
            this.batchItemValue = _.join(data.map(item => item.label), '\n')
            this.dialogVisible = true
        },
        async batchEditSelectItems() {
            const arrStrs = this.batchItemValue
            const arr = arrStrs.split('\n')
            // 获取原来的下标 避免导致id变化
            let oldData = this.localActiveData.table[this.batchDataKey]
            let labels = oldData.map(item => item.label)
            if (arr.length > 0) {
                const options = []
                for (let index = 0; index < arr.length; index++) {
                    const element = arr[index]
                    if (element.length > 0) {
                        let oIndex = labels.indexOf(element)
                        options.push({
                            id: oIndex > -1 ? oldData[oIndex].id : new Date().getTime() + index,
                            label: element
                        })
                    }
                }
                this.$set(this.localActiveData.table, this.batchDataKey, options)
                this.dialogVisible = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  align-items: center;

  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }

  & .el-input + .el-input {
    margin-left: 4px;
  }
}

.select-item + .select-item {
  margin-top: 4px;
}

.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}

.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}

.option-drag {
  cursor: move;
}
</style>
