var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-container" },
    [
      _c("el-card", { staticClass: "member-box-card" }, [
        _c("div", { staticClass: "member-info-view" }, [
          _c("p", { staticClass: "title pl-10" }, [_vm._v("个人信息")]),
          _vm.userInfo
            ? _c("div", { staticClass: "account-info-view" }, [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { width: "80" },
                        },
                        [_vm._v("昵称：")]
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(" " + _vm._s(_vm.userInfo.name) + " "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.editNameDialogVisible = true
                                },
                              },
                            },
                            [_vm._v(" 修改 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { width: "80" },
                        },
                        [_vm._v("账号：")]
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(" " + _vm._s(_vm.userInfo.email) + " "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.emailDialogVisible = true
                                },
                              },
                            },
                            [_vm._v("绑定")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { width: "80" },
                        },
                        [_vm._v("密码：")]
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(" ****** "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.pwdDialogVisible = true
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { width: "80" },
                        },
                        [_vm._v("手机号：")]
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(" " + _vm._s(_vm.userInfo.phoneNumber) + " "),
                          _vm.userInfo.phoneNumber
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.phoneDialogVisible = true
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.phoneDialogVisible = true
                                    },
                                  },
                                },
                                [_vm._v("绑定")]
                              ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "account-avatar-view" },
                  [
                    _c("my-upload", {
                      attrs: {
                        field: "file",
                        width: 300,
                        height: 300,
                        url: _vm.getUploadUrl(),
                        headers: _vm.getUploadHeader(),
                        "img-format": "png",
                      },
                      on: { "crop-upload-success": _vm.cropUploadSuccess },
                      model: {
                        value: _vm.showUploadAvatar,
                        callback: function ($$v) {
                          _vm.showUploadAvatar = $$v
                        },
                        expression: "showUploadAvatar",
                      },
                    }),
                    _c("el-avatar", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: _vm.userInfo.avatar },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showUploadAvatar = true
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "margin-top": "5px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            nativeOn: {
                              click: function ($event) {
                                _vm.showUploadAvatar = true
                              },
                            },
                          },
                          [_vm._v("更换头像")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          false
            ? _c("p", { staticClass: "title pl-10" }, [_vm._v("第三方账号")])
            : _vm._e(),
          false
            ? _c("div", { staticClass: "account-info-view" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "30px",
                      display: "flex",
                      "flex-direction": "row",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "account-icon-view" },
                      [
                        _c("font-icon", {
                          staticClass: "fab fa-weixin icon",
                          style: {
                            color: _vm.userInfo.wxName ? "#3F9F3F" : "",
                          },
                        }),
                        _vm.userInfo.wxName
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.userInfo.wxName) + "(已绑定) "
                              ),
                            ])
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: { click: _vm.bindWxHandle },
                              },
                              [_vm._v("绑定")]
                            ),
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              title: "微信扫描二维码绑定",
                              width: "400px",
                              center: "",
                              visible: _vm.bindWxDialogVisible,
                            },
                            on: {
                              "update:visible": function ($event) {
                                _vm.bindWxDialogVisible = $event
                              },
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "150px",
                                height: "150px",
                                display: "block",
                                margin: "0 auto",
                              },
                              attrs: { src: _vm.bindWxQrcode, fit: "fill" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "account-icon-view" },
                      [
                        _c("font-icon", {
                          staticClass: "fa fa-qq icon",
                          style: {
                            color: _vm.userInfo.qqName ? "#078DF0" : "",
                          },
                        }),
                        _vm.userInfo.qqName
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.userInfo.qqName) + "(已绑定) "
                              ),
                            ])
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectUrl(
                                      _vm.qqLoginAuthorizeUrl
                                    )
                                  },
                                },
                              },
                              [_vm._v("绑定")]
                            ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "account-icon-view" },
                      [
                        _c("font-icon", { staticClass: "fa fa-weibo icon" }),
                        _vm.userInfo.wbName
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.userInfo.wbName) + "(已绑定) "
                              ),
                            ])
                          : _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("绑定"),
                            ]),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改用户名",
                visible: _vm.editNameDialogVisible,
                width: "450px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editNameDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "updateNameForm",
                  attrs: {
                    model: _vm.userInfoForm,
                    rules: _vm.userInfoRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "新用户名", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.userInfoForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfoForm, "name", $$v)
                          },
                          expression: "userInfoForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: () => {
                          this.$refs["updateNameForm"].validateField(
                            "name",
                            (err) => {
                              if (!err) {
                                this.editNameDialogVisible = false
                                this.updateUserHandle()
                              }
                            }
                          )
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改密码",
                visible: _vm.pwdDialogVisible,
                width: "450px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.pwdDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "updatePassWordForm",
                  staticStyle: { width: "300px" },
                  attrs: {
                    model: _vm.userPwdForm,
                    rules: _vm.userPwdRules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "输入旧密码", prop: "oldPassword" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入旧密码",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.userPwdForm.oldPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.userPwdForm, "oldPassword", $$v)
                          },
                          expression: "userPwdForm.oldPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "输入新密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入新密码",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.userPwdForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.userPwdForm, "password", $$v)
                          },
                          expression: "userPwdForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "重复输入密码", prop: "repeatPassword" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请重复输入密码",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.userPwdForm.repeatPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.userPwdForm, "repeatPassword", $$v)
                          },
                          expression: "userPwdForm.repeatPassword",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: () => {
                          this.pwdDialogVisible = false
                          this.updateUserPwdHandle()
                        },
                      },
                    },
                    [_vm._v("完 成")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改邮箱",
                visible: _vm.emailDialogVisible,
                width: "450px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.emailDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "updateEmailForm",
                  staticStyle: { width: "80%" },
                  attrs: {
                    model: _vm.userInfoForm,
                    rules: _vm.userInfoRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.userInfoForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfoForm, "email", $$v)
                          },
                          expression: "userInfoForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: () => {
                          this.pwdDialogVisible = false
                          this.sendUpdateEmail()
                        },
                      },
                    },
                    [_vm._v("发送验证邮件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改手机号",
                visible: _vm.phoneDialogVisible,
                width: "450px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.phoneDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "updatePhoneForm",
                  attrs: {
                    model: _vm.userInfoForm,
                    rules: _vm.userInfoRules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phoneNumber" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号" },
                        model: {
                          value: _vm.userInfoForm.phoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfoForm, "phoneNumber", $$v)
                          },
                          expression: "userInfoForm.phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "输入验证码", prop: "code" } },
                    [
                      _c("el-input", {
                        staticStyle: { display: "inline-block", width: "60%" },
                        attrs: { placeholder: "请输入验证码" },
                        model: {
                          value: _vm.userInfoForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfoForm, "code", $$v)
                          },
                          expression: "userInfoForm.code",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click",
                            },
                          ],
                          staticStyle: {
                            display: "inline-block",
                            width: "40%",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.sendUpdatePhoneNumber.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.phoneValidateCodeBtnText) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: () => {
                          this.phoneDialogVisible = false
                          this.updateUserPhoneHandle()
                        },
                      },
                    },
                    [_vm._v("完 成")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }