var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "write-container" }, [
    _c("div", { staticClass: "form-container" }, [
      _c("div", { staticClass: "project-form-wrapper" }, [
        _c(
          "div",
          { staticClass: "project-form" },
          [
            _c("h4", {
              staticClass: "form-name-text",
              staticStyle: { "text-align": "center" },
              domProps: { innerHTML: _vm._s(_vm.questionTitle) },
            }),
            _c("div", {
              staticClass: "form-description-text",
              domProps: { innerHTML: _vm._s(_vm.description) },
            }),
            _vm.formConf.fields.length && _vm.formModel
              ? _c("generate-form", {
                  key: _vm.formParseKey,
                  ref: "generateForm",
                  staticStyle: { "margin-bottom": "40px" },
                  attrs: {
                    "logic-trigger-item-list": _vm.logicTriggerItemList,
                    "form-conf": _vm.formConf,
                    "page-form-model": _vm.formModel,
                  },
                  on: {
                    "update:logicTriggerItemList": function ($event) {
                      _vm.logicTriggerItemList = $event
                    },
                    "update:logic-trigger-item-list": function ($event) {
                      _vm.logicTriggerItemList = $event
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }