var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "write-container" }, [
    _c("h1", {
      staticStyle: { display: "none" },
      attrs: { id: "inActiveTime" },
    }),
    _vm.writeStatus == 0
      ? _c(
          "div",
          {},
          [
            _c("el-result", {
              attrs: {
                icon: "error",
                title: _vm.writeNotStartPrompt,
                "sub-title": _vm.writeNotStartPrompt,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.writeStatus == 1
      ? _c("div", { staticClass: "form-container" }, [
          _c("div", { staticClass: "write-container" }, [
            _c("div", { staticClass: "form-container" }, [
              _c("div", { staticClass: "project-form-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "project-form" },
                  [
                    _c("h4", {
                      staticClass: "form-name-text",
                      staticStyle: { "text-align": "center" },
                      domProps: { innerHTML: _vm._s(_vm.questionTitle) },
                    }),
                    _c("div", {
                      staticClass: "form-description-text",
                      domProps: { innerHTML: _vm._s(_vm.description) },
                    }),
                    _vm.logic && _vm.formConfig.fields.length && _vm.formModel
                      ? _c("generate-form", {
                          key: _vm.formParseKey,
                          ref: "generateForm",
                          staticStyle: { "margin-bottom": "40px" },
                          attrs: {
                            "form-conf": _vm.formConfig,
                            "page-form-model": _vm.formModel,
                            "start-parser": false,
                          },
                          on: { "on-change": _vm.bindFormChange },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "submit-btn-form" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.bindSubmitBtn },
                          },
                          [_vm._v(" 提交 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.writeStatus == 2
      ? _c(
          "div",
          { staticClass: "title-icon-view" },
          [
            _c("el-result", {
              attrs: {
                icon: "success",
                title: _vm.userFormSetting.promptText
                  ? _vm.userFormSetting.promptTextContent
                  : "提交成功！",
              },
            }),
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _vm.userFormSetting.promptImg
                  ? _c("el-image", {
                      attrs: {
                        src: _vm.userFormSetting.promptImgUrl,
                        fit: "cover",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }