<template>
    <el-cascader
        v-model="changeValue"
        :options="getOptions()"
        v-bind="{...item}"
    />
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TCascader',
    mixins: [mixin],
    data() {
        return {
        }
    }
}
</script>

<style scoped>

</style>
