var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      class: _vm.fullscreen ? "t-dialog--fullscreen" : "t-dialog",
      attrs: {
        "close-on-click-modal": false,
        fullscreen: _vm.fullscreen,
        visible: _vm.dialogVisible,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "t__dialog__header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("span", { staticClass: "el-dialog__title" }, [
            _vm._v(_vm._s(_vm.dialogTitle)),
          ]),
          _c("div", { staticClass: "t__dialog__menu" }, [
            _c("i", {
              staticClass: "el-dialog__close",
              class: _vm.fullscreen ? "el-icon-news" : "el-icon-full-screen",
              on: { click: _vm.handleFullScreen },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "文本导入", name: "textImport" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: { minRows: 29, maxRows: 29 },
                              placeholder: "请将准备好的内容安装格式粘贴到这里",
                              type: "textarea",
                            },
                            on: { change: _vm.handleSplitContent },
                            model: {
                              value: _vm.content,
                              callback: function ($$v) {
                                _vm.content = $$v
                              },
                              expression: "content",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "right",
                          attrs: { offset: 2, span: 10 },
                        },
                        [
                          _c(
                            "el-scrollbar",
                            [
                              _vm.formConf.fields.length
                                ? _c("generate-form", {
                                    key: _vm.formParseKey,
                                    ref: "generateForm",
                                    staticStyle: { "margin-bottom": "40px" },
                                    attrs: {
                                      "form-conf": _vm.formConf,
                                      "page-form-model": {},
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "导入提示", name: "importHelp" } },
                [
                  _c("el-alert", {
                    staticClass: "m10",
                    attrs: { closable: false, type: "success" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("div", [
                              _c("p", [
                                _vm._v(
                                  " 1. 在题目前面使用中文【】添加题型标识，标识同提醒组件名，未标识的题目系统无法识别；"
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " 2. 目前可识别的题型标识包括：【单选框组】、【多选框组】、【下拉选择】、【单行文本】、【多行文本】、【日期时间】、【评分】、【图片选择】、【文字描述】等基础组件；"
                                ),
                              ]),
                              _c("p", [
                                _vm._v(" 3. 不同选项之间通过换行区分 ；"),
                              ]),
                              _c("p", [
                                _vm._v(" 4. 请勿在选项与选项之间空行；"),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "t-dialog__footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleImport } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }