import {getRequest, postRequest} from './baseRequest'

// 获取项目详情
export const queryProject = formKey => getRequest(`/user/form/${formKey}`)

// 更新项目
export const updateProject = data => postRequest('/user/form/update', data)

// 创建项目表单项
export const createProjectItem = data => postRequest('/user/form/item/create', data)
// 创建项目表单项
export const batchCreateProjectItem = data => postRequest('/user/form/item/batch/create', data)

// 更新项目表单项
export const updateProjectItem = data => postRequest('/user/form/item/update', data)

// 删除项目表单项
export const deleteProjectItem = data => postRequest('/user/form/item/delete', data)

// 查询项目表单项
export const queryProjectItemList = formKey => getRequest('/user/form/item/list', formKey)
// 排序项目表单项
export const sortProjectItem = data => postRequest('/user/form/item/sort', data)
// 表单逻辑项
export const queryFormLogic = params => getRequest('/user/form/logic', params)
// 停止发布表单
export const stopPublishFormRequest = data => postRequest('/user/form/stop', data)

// 查询部门员工列表
export const getEmpTreeRequest = params => getRequest('/organization/empTrees', params)

// 查询部门列表
export const getDeptTreeRequest = params => getRequest('/organization/deptTrees', params)
export default {
    queryProject,
    updateProject,
    createProjectItem,
    updateProjectItem,
    deleteProjectItem,
    sortProjectItem
}
