<template>
    <div>
        <el-form-item label="最大值">
            <el-input-number v-model="localActiveData.max" placeholder="最大输入值" />
        </el-form-item>
        <el-form-item label="最小值">
            <el-input-number v-model="localActiveData.min" placeholder="最小输入值" />
        </el-form-item>
        <el-form-item label="步长">
            <el-input-number v-model="localActiveData.step" placeholder="输入每次增加的数值" />
        </el-form-item>
    </div>
</template>

<script>

export default {
    name: 'ConfigItemSlider',
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style scoped>

</style>
