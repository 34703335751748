<template>
    <div class="horizontal-input">
        <span v-for="(item,index) in inputArray" :key="index">
            {{ item.text }}
            <el-input v-if="item.haveInput" v-model="value[item.index]" style="width: 150px" @change="handleChange" />
        </span>
    </div>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'HorizontalInput',
    mixins: [mixin],
    props: {
        input: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            inputArray: ''
        }
    },
    watch: {
        input: {
            handler(val) {
                this.initInputValueArr()
            },
            deep: true
        }
    },
    created() {
        this.initInputValueArr()
    },
    methods: {
        initInputValueArr() {
            let array = this.input.split('$input')
            let inputArr = []
            let tempInput = this.input
            array.forEach((text, index) => {
                let tIndex = tempInput.indexOf('$input')
                inputArr.push({
                    text: text,
                    index: index,
                    haveInput: tIndex !== -1
                })
                // 减去$input 的长度
                tempInput = tempInput.substr(tIndex + 5, tempInput.length - (tIndex + 5))
            })
            this.inputArray = inputArr
        },
        handleChange(value) {
            this.$emit('change', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #606266 !important;
  border-radius: 0px !important;
}

.horizontal-input {
  color: #606266;
  padding: 10px;
}
</style>
