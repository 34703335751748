export default {
    props: ['value', 'item'],
    data() {
        return {

        }
    },
    computed: {},
    created() {

    },
    watch: {},
    mounted() {

    },
    methods: {
        beforeUpload(file) {
            let sizeUnitNum = 1
            // 文件大小判断
            switch (this.item.config.sizeUnit) {
                case 'KB':
                    sizeUnitNum = 1024
                    break
                case 'MB':
                    sizeUnitNum = 1024 * 1024
                    break
                case 'GB':
                    sizeUnitNum = 1024 * 1024 * 1024
                    break
            }
            let totalSize = this.item.config.fileSize * sizeUnitNum
            if (file.size > totalSize) {
                this.$message.error(`上传图片大小不能超过${this.item.config.fileSize}${this.item.config.sizeUnit}`)
                return false
            }
            // if (this.item.accept) {
            //   console.log(file.type)
            //   let isAccept = new RegExp(this.item.accept).test(file.type)
            //   if (!isAccept) {
            //     this.$message.error(`应该选择${this.item.accept}类型的文件`)
            //     return false
            //   }
            // }
            return true
        }

    }
}
