<template>
    <div>
        <el-checkbox-group
            v-model="changeValue"
            v-bind="{...item}"
        >
            <template v-if="item['config'].optionType!=='button1'">
                <div v-for="(option) in getOptions()" :key="option.value" style="width: 100%">
                    <el-checkbox
                        :label="option.value" :style="{display: item['config'].inline ? 'inline-block' : 'block'}"
                    >
                        {{ option.label }}
                    </el-checkbox>
                    <el-input v-if="option.value===0" v-model="otherInputValue" style="margin-left: 25px" class="other-input" size="mini" />
                </div>
            </template>
            <template v-else>
                <el-checkbox-button
                    v-for="(option) in getOptions()"
                    :key="option.value" :label="option.value"
                    :style="{display: item['config'].inline ? 'block' : 'inline-block'}"
                >
                    {{ option.label }}
                </el-checkbox-button>
            </template>
        </el-checkbox-group>
    </div>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TCheckboxGroup',
    mixins: [mixin],
    props: ['models'],
    data() {
        return {
            otherInputValue: '',
            localModels: this.models
        }
    },
    watch: {
        otherInputValue: {
            deep: true,
            handler(val) {
                this.localModels[`${this.item.vModel}other`] = val
                this.$emit('other-input-change', this.changeValue, this.item)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-checkbox {
  min-height: 28px !important;
  line-height: 28px !important;
}

.other-input {
  margin-left: 10px;
  width: 50%;
}

::v-deep .el-checkbox__label {
  width: 90% !important;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: inline-block;
  vertical-align: middle;
}
</style>
