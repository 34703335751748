<template>
    <div class="status-container">
        <p class="title"> 404，联系一下开发者 </p>
    </div>
</template>
<script>
export default {
    name: '404',
    data() {
        return {}
    },
    mounted() {

    },
    methods: {}
}
</script>
<style lang="scss" scoped>
.status-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    justify-items: center;
    .title {
        color: #1890ff;
        font-size: 84px;
        text-align: left;
    }
}

</style>
