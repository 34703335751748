<template>
    <div>
        <el-form-item
            label="选项分隔符"
        >
            <el-input
                v-model="localActiveData.separator"
                placeholder="请输入选项分隔符"
            />
        </el-form-item>

        <!--          <el-FormItem-item-->
        <!--            v-if="localActiveData.config.tag === 'el-cascader'"-->
        <!--            label="任选层级"-->
        <!--          >-->
        <!--            <el-switch v-model="localActiveData.props.props.checkStrictly" />-->
        <!--          </el-FormItem-item>-->
        <el-form-item
            label="是否多选"
        >
            <el-switch v-model="localActiveData.props.props.multiple" />
        </el-form-item>
        <el-form-item
            label="展示全路径"
        >
            <el-switch v-model="localActiveData['show-all-levels']" />
        </el-form-item>
        <el-form-item
            label="可否筛选"
        >
            <el-switch v-model="localActiveData.filterable" />
        </el-form-item>
        <template>
            <el-divider>选项</el-divider>
            <!--            <el-FormItem-item label="数据类型">-->
            <!--              <el-radio-group v-model="localActiveData.config.dataType" size="small">-->
            <!--                <el-radio-button label="dynamic">-->
            <!--                  动态数据-->
            <!--                </el-radio-button>-->
            <!--                <el-radio-button label="static">-->
            <!--                  静态数据-->
            <!--                </el-radio-button>-->
            <!--              </el-radio-group>-->
            <!--            </el-FormItem-item>-->

            <!--            <template v-if="localActiveData.config.dataType === 'dynamic'">-->
            <!--              <el-FormItem-item label="接口地址">-->
            <!--                <el-input-->
            <!--                  v-model="localActiveData.config.url"-->
            <!--                  :title="localActiveData.config.url"-->
            <!--                  placeholder="请输入接口地址"-->
            <!--                  clearable-->
            <!--                >-->
            <!--                  <el-select-->
            <!--                    slot="prepend"-->
            <!--                    v-model="localActiveData.config.method"-->
            <!--                    :style="{width: '85px'}"-->
            <!--                  >-->
            <!--                    <el-option label="get" value="get" />-->
            <!--                    <el-option label="post" value="post" />-->
            <!--                    <el-option label="put" value="put" />-->
            <!--                    <el-option label="delete" value="delete" />-->
            <!--                  </el-select>-->
            <!--                </el-input>-->
            <!--              </el-FormItem-item>-->
            <!--              <el-FormItem-item label="数据位置">-->
            <!--                <el-input v-model="localActiveData.config.dataKey" placeholder="请输入标签键名" />-->
            <!--              </el-FormItem-item>-->
            <!--              <el-FormItem-item label="标签键名">-->
            <!--                <el-input v-model="localActiveData.props.props.label" placeholder="请输入标签键名" />-->
            <!--              </el-FormItem-item>-->
            <!--              <el-FormItem-item label="值键名">-->
            <!--                <el-input v-model="localActiveData.props.props.value" placeholder="请输入值键名" />-->
            <!--              </el-FormItem-item>-->
            <!--              <el-FormItem-item label="子级键名">-->
            <!--                <el-input v-model="localActiveData.props.props.children" placeholder="请输入子级键名" />-->
            <!--              </el-FormItem-item>-->
            <!--            </template>-->

            <!-- 级联选择静态树 -->
            <el-tree
                v-if="localActiveData.config.dataType === 'static'"
                :data="localActiveData.config.options"
                :expand-on-click-node="false"
                draggable="true"
                node-key="id"
            >
                <div slot-scope="{ node, data }" class="custom-tree-node">
                    <span>{{ node.label }}</span>
                    <span class="node-operation">
                        <i class="el-icon-plus" title="添加" @click="append(data)" />
                        <i class="el-icon-delete" title="删除" @click="remove(node, data)" />
                    </span>
                </div>
            </el-tree>
            <div
                v-if="localActiveData.config.dataType === 'static'"
                style="margin-left: 20px"
            >
                <el-button
                    icon="el-icon-circle-plus-outline"
                    style="padding-bottom: 0"
                    type="text"
                    @click="addTreeItem"
                >
                    添加
                </el-button>
            </div>
            <el-divider />
            <treeNode-dialog
                :visible.sync="dialogVisible"
                title="添加选项"
                @commit="addNode"
            />
        </template>
    </div>
</template>

<script>
import TreeNodeDialog from '../TreeNodeDialog'

export default {
    name: 'ConfigItemCascader',
    components: {
        TreeNodeDialog
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData,
            dialogVisible: false
        }
    },
    methods: {
        addNode(data) {
            this.currentNode.push(data)
        },
        addTreeItem() {
            this.dialogVisible = true
            this.currentNode = this.localActiveData.config.options
        },
        append(data) {
            if (!data.children) {
                this.$set(data, 'children', [])
            }
            this.dialogVisible = true
            this.currentNode = data.children
        },
        remove(node, data) {
            this.localActiveData.config.defaultValue = [] // 避免删除时报错
            const { parent } = node
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
        }
    }
}
</script>

<style scoped>

</style>
