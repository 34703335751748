var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "signature-pad", attrs: { id: "signature-pad" } },
    [
      _c(
        "div",
        {
          staticClass: "signature-pad--body",
          staticStyle: { "min-height": "300px", width: "100%" },
        },
        [
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.signImageUrl,
                expression: "!signImageUrl",
              },
            ],
            ref: "signature_pad_box",
            staticClass: "canvasId",
            staticStyle: { border: "2px dashed #f7f7f7" },
          }),
          _vm.signImageUrl
            ? _c("img", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.signImageUrl },
              })
            : _vm._e(),
        ]
      ),
      _c("p", { staticClass: "desc-text" }, [
        _vm._v(' 请在上面区域完成签名 然后点击确"确认"按钮 '),
      ]),
      !_vm.signImageUrl
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "danger" },
                  on: { click: _vm.clear },
                },
                [_vm._v(" 清除 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", type: "warning" },
                  on: { click: _vm.undo },
                },
                [_vm._v(" 回撤 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.savePng },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.signImageUrl
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.reSign },
                },
                [_vm._v(" 重签 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }