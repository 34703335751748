<template>
    <div>
        <el-input ref="main"
                  v-model="address"
                  :clearable="disabled"
                  :disabled="disabled"
                  placeholder="请选择地址"
                  @clear="handleClear"
                  @click.native="handleShow"
        />

        <el-dialog
            :visible.sync="box"
            append-to-body
            title="选择位置"
            width="80%"
            @close="handleClose"
        >
            <div v-if="box">
                <el-input id="map__input"
                          v-model="formattedAddress"
                          :readonly="disabled"
                          class="input-map-content-input"
                          clearable
                          placeholder="输入关键字选取地点"
                          @clear="clear"
                />
                <div>
                    <div id="map__container"
                         class="input-map-content-container"
                         tabindex="0"
                    />
                    <div id="map__result"
                         class="input-map-content-result"
                    />
                </div>
            </div>
            <span slot="footer"
                  class="dialog-footer"
            >
                <el-button v-if="!(disabled )"
                           icon="el-icon-check"
                           type="primary"
                           @click="handleSubmit"
                >确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import mixin from './mixin'
export default {
    name: 'InputMap',
    mixins: [mixin]
}
</script>
<style scoped>

::v-deep .amap-marker-img {
  width: 25px !important;
  height: 34px !important;
}

::v-deep .input-map-marker {
  position: absolute !important;
  top: -20px !important;
  right: -118px !important;
  color: #fff !important;
  padding: 4px 10px !important;
  -webkit-box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  white-space: nowrap;
  font-size: 12px;
  background-color: #25a5f7 !important;
  border-radius: 3px;
}

.input-map-content-container {
  width: 100%;
  height: 450px;
}

.input-map-content-result {
  display: block !important;
  position: absolute;
  top: 0;
  right: -8px;
  width: 250px;
  height: 450px;
  overflow-y: auto;
}

.input-map-content-input {
  margin-bottom: 10px;
}

::v-deep .el-dialog__headerbtn {
  z-index: 999;
}
</style>
