var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "是否多选" } },
        [
          _c("el-switch", {
            on: { change: _vm.handleChange },
            model: {
              value: _vm.localActiveData.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "multiple", $$v)
              },
              expression: "localActiveData.multiple",
            },
          }),
        ],
        1
      ),
      _c("matrix-option", { attrs: { "active-data": _vm.localActiveData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }