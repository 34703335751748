import { getRequest, postRequest } from '../../../api/baseRequest'

export default {
    props: {
        value: null
    },
    data() {
        return {
            phoneValidateCodeBtnText: '发送验证码',
            phoneValidateCodeBtn: false,
            open: false,
            inputValue: null,
            phoneValidateForm: {
                phoneNumber: '',
                code: ''
            },
            rules: {
                phoneNumber: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入手机号'
                    },
                    {
                        pattern: /^(?:0|86|\+86)?1[3456789]\d{9}$/,
                        message: '请输入正确的手机号'
                    }
                ],
                code: {
                    required: true,
                    trigger: 'blur',
                    message: '请输入验证码'
                }
            }
        }
    },
    methods: {
        openDialogHandle() {
            this.open = true
            this.phoneValidateForm = {
                phoneNumber: '',
                code: ''
            }
        },
        validateCodeHandle() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    // 调接口 验证成功
                    postRequest('/form/common/phone/code/check', this.phoneValidateForm)
                        .then(res => {
                            this.inputValue = res.data
                            this.open = false
                            this.$emit('input', res.data)
                        })
                } else {
                    return false
                }
            })
        },
        sendValidateMsgHandle() {
            this.$refs.form.validateField('phoneNumber', err => {
                if (!err) {
                    this.phoneValidateCodeBtn = true
                    getRequest(`/form/common/phone/code?phoneNumber=${this.phoneValidateForm.phoneNumber}`)
                        .then(() => {
                            this.msgSuccess('验证码发送成功，5分钟内有效')
                            this.phoneValidateCodeBtn = true
                            let count = 60
                            const timer = setInterval(() => {
                                count--
                                this.phoneValidateCodeBtnText = `${count}s后重新发送`
                                if (count === 0) {
                                    this.phoneValidateCodeBtnText = '发送验证码'
                                    this.phoneValidateCodeBtn = false
                                    clearInterval(timer)
                                }
                            }, 1000)
                        })
                }
            })
        }
    }
}
