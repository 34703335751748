var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        { staticClass: "el-page-header" },
        [
          _vm.projectData
            ? _c("h5", [_vm._v(" " + _vm._s(_vm.projectData.name) + " ")])
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.openFormHandle } },
            [_vm._v(" 访问表单 ")]
          ),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              staticClass: "public-result-table",
              attrs: { data: _vm.projectResultList },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "public-table-expand",
                            attrs: { "label-position": "left" },
                          },
                          _vm._l(_vm.projectItemList, function (item) {
                            return _c(
                              "el-form-item",
                              { key: item.id, attrs: { label: item.label } },
                              [
                                _c("result-item", {
                                  attrs: {
                                    "field-item-id": item.formItemId,
                                    "project-item-data": item,
                                    "result-data": props.row,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交序号", prop: "serialNumber" },
              }),
              _c("el-table-column", {
                attrs: { label: "提交地址", prop: "submitAddress" },
              }),
              _c("el-table-column", {
                attrs: { label: "提交时间", prop: "createTime" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }