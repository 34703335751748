var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "选项分隔符" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入选项分隔符" },
            model: {
              value: _vm.localActiveData.separator,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "separator", $$v)
              },
              expression: "localActiveData.separator",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否多选" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.localActiveData.props.props.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData.props.props, "multiple", $$v)
              },
              expression: "localActiveData.props.props.multiple",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "展示全路径" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.localActiveData["show-all-levels"],
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "show-all-levels", $$v)
              },
              expression: "localActiveData['show-all-levels']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "可否筛选" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.localActiveData.filterable,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "filterable", $$v)
              },
              expression: "localActiveData.filterable",
            },
          }),
        ],
        1
      ),
      [
        _c("el-divider", [_vm._v("选项")]),
        _vm.localActiveData.config.dataType === "static"
          ? _c("el-tree", {
              attrs: {
                data: _vm.localActiveData.config.options,
                "expand-on-click-node": false,
                draggable: "true",
                "node-key": "id",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c("div", { staticClass: "custom-tree-node" }, [
                        _c("span", [_vm._v(_vm._s(node.label))]),
                        _c("span", { staticClass: "node-operation" }, [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { title: "添加" },
                            on: {
                              click: function ($event) {
                                return _vm.append(data)
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { title: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.remove(node, data)
                              },
                            },
                          }),
                        ]),
                      ])
                    },
                  },
                ],
                null,
                false,
                1415067482
              ),
            })
          : _vm._e(),
        _vm.localActiveData.config.dataType === "static"
          ? _c(
              "div",
              { staticStyle: { "margin-left": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "padding-bottom": "0" },
                    attrs: {
                      icon: "el-icon-circle-plus-outline",
                      type: "text",
                    },
                    on: { click: _vm.addTreeItem },
                  },
                  [_vm._v(" 添加 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("el-divider"),
        _c("treeNode-dialog", {
          attrs: { visible: _vm.dialogVisible, title: "添加选项" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            commit: _vm.addNode,
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }