var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rt-container" }, [
    _c("div", { staticClass: "rt-table" }, [
      _vm.dataValue
        ? _c(
            "table",
            {
              staticClass: "el-table el-table--border",
              attrs: { align: "center" },
            },
            [
              _c(
                "tbody",
                [
                  _c(
                    "tr",
                    [
                      _c("td", { attrs: { width: "100" } }),
                      _vm._l(_vm.table.columns, function (col, index) {
                        return _c("td", { key: index }, [
                          _vm._v(" " + _vm._s(col.label) + " "),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._l(_vm.table.rows, function (row, rIndex) {
                    return _c(
                      "tr",
                      { key: rIndex },
                      [
                        _c("td", [_vm._v(" " + _vm._s(row.label) + " ")]),
                        _vm._l(_vm.table.columns, function (col, cIndex) {
                          return _c(
                            "td",
                            { key: cIndex, attrs: { width: "100" } },
                            [
                              _c("el-input", {
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.handleChange(
                                      $event,
                                      rIndex,
                                      cIndex
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.dataValue[row.id][col.id],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataValue[row.id], col.id, $$v)
                                  },
                                  expression: "dataValue[row.id][col.id]",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }