<template>
    <el-col :span="item.config.span">
        <el-form-item
            :label="getItemLabel"
            :label-width="item.config.labelWidth ? `${item.config.labelWidth}px` : null" :prop="item.vModel"
        >
            <!--特殊处理组件 二次包装的组件-->
            <template v-if="componentList.includes(item.typeId)">
                <component
                    :is="getComponentName(item)"
                    v-model="dataModel"
                    :item="item"
                    :models="models"
                />
            </template>
            <template v-else-if="['RADIO', 'CHECKBOX'].includes(item.typeId)">
                <component
                    :is="getComponentName(item)"
                    v-model="dataModel"
                    :item="item"
                    :models.sync="models"
                    @other-input-change="otherInputChange"
                />
            </template>
            <!--  OCR组件-->
            <template v-else-if="['OCR'].includes(item.typeId)">
                <component
                    :is="getComponentName(item)"
                    v-model="dataModel"
                    :item="item"
                    :models="models"
                    @ocr-success="(val)=>{$emit('ocr-success',val)}"
                />
            </template>
            <!--       分页-->
            <template v-else-if="['PAGINATION'].includes(item.typeId)">
                <component
                    :is="getComponentName(item)"
                    v-bind="{...item}"
                    :page-text-show="pageTextShow"
                    @next="(page)=>{$emit('next',page)}"
                    @prev="(page)=>{$emit('prev',page)}"
                />
            </template>
            <template v-else>
                <!--    其他无特殊处理组件-->
                <component
                    :is="getComponentName(item)"
                    v-model="dataModel"
                    v-bind="{...item}"
                />
            </template>
        </el-form-item>
    </el-col>
</template>

<script>
import '../../components/FormItem/autoRegister.js'
import {handleChangeLabel} from './FormItemLabel'
import {isMobile} from '../../utils'

export default {
    name: 'GenerateFormItem',
    props: ['pageTextShow', 'item', 'models', 'rules', 'index', 'seqNo'],
    data() {
        return {
            componentList: ['SELECT', 'IMAGE_CAROUSEL', 'IMAGE_UPLOAD', 'UPLOAD', 'CASCADER', 'IMAGE_SELECT', 'INPUT', 'DATE', 'SUB_FORM', 'RATE', 'SLIDER', 'NUMBER'],
            config: this.item.config,
            dataModel: this.models[this.item.vModel]
        }
    },
    computed: {
        getItemLabel() {
            if (!this.item.config.showLabel) {
                return ''
            }
            let label = ''
            if (this.seqNo != null) {
                label = this.seqNo + '：'
            }
            return label + this.item.config.label
        }
    },
    watch: {
        dataModel: {
            deep: true,
            handler(val) {
                // eslint-disable-next-line vue/no-mutating-props
                this.models[this.item.vModel] = val
                handleChangeLabel(this.models, this.item, val)
                this.$emit('update:models', {
                    ...this.models,
                    [this.item.vModel]: val
                })
                this.$emit('input-change', val, this.item.vModel, this.item)
            }
        },
        models: {
            deep: true,
            handler(val) {
                this.dataModel = val[this.item.vModel]
            }
        }
    },
    created() {
    // console.log(this.item.config)
    },
    methods: {
        // RADIO CHECKBOX 其他输入框输入
        otherInputChange(value, item) {
            handleChangeLabel(this.models, item, value)
        },
        getComponentName(item) {
            // 是否是移动端环境
            if (isMobile() && item.config.mobileTag) {
                return item.config.mobileTag
            }
            return item.config.tag
        }
    }
}
</script>
