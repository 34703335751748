var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.ocrType !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "识别内容" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.localActiveData.ocrType,
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "ocrType", $$v)
                    },
                    expression: "localActiveData.ocrType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "通用文字", value: "GENERAL" },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "mt5",
                  attrs: { icon: "el-icon-edit" },
                  on: { click: _vm.handleOcrRule },
                },
                [_vm._v(" 设置识别规则 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "识别规则设置",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", { staticClass: "text-danger" }, [
            _vm._v(
              "识别内容和表单字段对应的关系如下存储, 识别完成之后将按照规则将数据填充到表单字段内"
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-edit" },
                  on: { click: _vm.handleCreateFields },
                },
                [_vm._v(" 创建默认存储字段 ")]
              ),
            ],
            1
          ),
          _vm.localActiveData.fieldMapping &&
          Object.keys(_vm.localActiveData.fieldMapping).length
            ? _c(
                "div",
                _vm._l(
                  Object.keys(_vm.getOcrFields(_vm.localActiveData.ocrType)),
                  function (key) {
                    return _c("div", { key: key }, [
                      _c(
                        "div",
                        { staticClass: "m5" },
                        [
                          _c("el-tag", [
                            _vm._v(
                              _vm._s(
                                _vm.getOcrFields(_vm.localActiveData.ocrType)[
                                  key
                                ]["label"]
                              )
                            ),
                          ]),
                          _vm._v(" 存储到 "),
                          Object.keys(_vm.localActiveData.fieldMapping)
                            ? _c("el-tag", [
                                _vm._v(
                                  " " + _vm._s(_vm.getFormField(key)) + " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }