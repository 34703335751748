<template>
    <div>
        <el-form-item
            v-if="localActiveData['start-placeholder'] !== undefined"
            label="开始占位"
        >
            <el-input
                v-model="localActiveData['start-placeholder']"
                placeholder="请输入占位提示"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData['end-placeholder'] !== undefined"
            label="结束占位"
        >
            <el-input
                v-model="localActiveData['end-placeholder']"
                placeholder="请输入占位提示"
            />
        </el-form-item>
        <el-tooltip effect="dark" content="默认时间为当时填写的时间" placement="top">
            <el-form-item>
                <span slot="label">
                    默认当时
                    <i class="el-icon-question" />
                </span>
                <el-switch v-model="localActiveData.config['defaultNowTime']" />
            </el-form-item>
        </el-tooltip>
        <el-form-item
            v-if="localActiveData.type !== undefined "
            label="时间类型"
        >
            <el-select
                v-model="localActiveData.type"
                :style="{ width: '100%' }"
                placeholder="请选择时间类型"
                @change="dateTypeChange"
            >
                <el-option
                    v-for="(item, index) in dateTypeOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </el-form-item>
        <el-form-item v-if="localActiveData.format !== undefined" label="时间格式">
            <el-input
                :value="localActiveData.format"
                placeholder="请输入时间格式"
                @input="setTimeValue($event)"
            />
        </el-form-item>
    </div>
</template>

<script>
const dateTimeFormat = {
    date: 'yyyy-MM-dd',
    week: 'yyyy 第 WW 周',
    month: 'yyyy-MM',
    year: 'yyyy',
    datetime: 'yyyy-MM-dd HH:mm:ss',
    daterange: 'yyyy-MM-dd',
    monthrange: 'yyyy-MM',
    datetimerange: 'yyyy-MM-dd HH:mm:ss'
}
export default {
    name: 'ConfigItemDate',
    components: {
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData,
            dateTypeOptions: [
                {
                    label: '日(date)',
                    value: 'date'
                },
                {
                    label: '周(week)',
                    value: 'week'
                },
                {
                    label: '月(month)',
                    value: 'month'
                },
                {
                    label: '年(year)',
                    value: 'year'
                },
                {
                    label: '日期时间(datetime)',
                    value: 'datetime'
                }
            ]
        }
    },
    methods: {
        dateTypeChange(val) {
            this.setTimeValue(dateTimeFormat[val], val)
        },
        setTimeValue(val, type) {
            const valueFormat = type === 'week' ? dateTimeFormat.date : val
            this.$set(this.localActiveData.config, 'defaultValue', null)
            this.$set(this.localActiveData, 'value-format', valueFormat)
            this.$set(this.localActiveData, 'format', val)
        }
    }
}
</script>

<style scoped>

</style>
