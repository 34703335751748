<template>
    <el-dialog
        :class="fullscreen?'t-dialog--fullscreen':'t-dialog'"
        :close-on-click-modal="false"
        :fullscreen="fullscreen"
        :visible.sync="dialogVisible"
        width="70%"
    >
        <div slot="title"
             class="t__dialog__header"
        >
            <span class="el-dialog__title">{{ dialogTitle }}</span>
            <div class="t__dialog__menu">
                <i :class="fullscreen?'el-icon-news':'el-icon-full-screen'"
                   class="el-dialog__close"
                   @click="handleFullScreen"
                />
            </div>
        </div>
        <div class="body">
            <el-tabs v-model="activeName">
                <el-tab-pane label="文本导入" name="textImport">
                    <el-row>
                        <el-col :span="12">
                            <el-input
                                v-model="content"
                                :autosize="{ minRows: 29, maxRows: 29}"
                                placeholder="请将准备好的内容安装格式粘贴到这里"
                                type="textarea"
                                @change="handleSplitContent"
                            />
                        </el-col>
                        <el-col :offset="2" :span="10" class="right">
                            <el-scrollbar>
                                <generate-form
                                    v-if="formConf.fields.length"
                                    :key="formParseKey"
                                    ref="generateForm"
                                    :form-conf="formConf"
                                    :page-form-model="{}"
                                    style="margin-bottom: 40px"
                                />
                            </el-scrollbar>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="导入提示" name="importHelp">
                    <el-alert
                        :closable="false"
                        class="m10"
                        type="success"
                    >
                        <template #title>
                            <div>
                                <p> 1. 在题目前面使用中文【】添加题型标识，标识同提醒组件名，未标识的题目系统无法识别；</p>
                                <p> 2. 目前可识别的题型标识包括：【单选框组】、【多选框组】、【下拉选择】、【单行文本】、【多行文本】、【日期时间】、【评分】、【图片选择】、【文字描述】等基础组件；</p>
                                <p> 3. 不同选项之间通过换行区分 ；</p>
                                <p> 4. 请勿在选项与选项之间空行；</p>
                            </div>
                        </template>
                    </el-alert>
                </el-tab-pane>
            </el-tabs>
        </div>
        <span slot="footer" class="t-dialog__footer">
            <el-button @click="dialogVisible=false">取 消</el-button>
            <el-button type="primary" @click="handleImport">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

import _ from 'lodash'
import {basicComponents} from '../GenerateForm/config'
import GenerateForm from '../GenerateForm/GenerateForm'
import {formItemConvertData} from '../../utils/convert'
import {batchCreateProjectItem} from '../../api/indexForm'

export default {
    name: 'ImportFormItem',
    components: {
        GenerateForm
    },
    props: {
        formKey: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            activeName: 'textImport',
            content: '【单选框组】您经常创建什么类型的项目？\n' +
                '问卷调研\n' +
                '满意度调查\n' +
                '投票评选\n' +
                '表单报名\n' +
                '测评考试',
            dialogVisible: false,
            dialogTitle: '导入题目',
            fullscreen: false,
            formConf: {
                fields: [],
                labelPosition: 'top',
                formKey: 'temp001'
            },
            formParseKey: ''
        }
    },
    watch: {
        content() {
            this.handleSplitContent()
        }
    },
    created() {
        this.handleSplitContent()
    },
    methods: {
        showDialog() {
            this.dialogVisible = true
        },
        handleSplitContent: function() {
            let fields = []
            let items = this.content.split(/【/g)
            for (let i = 0; i < items.length; i++) {
                let item = items[i]
                if (!item) {
                    continue
                }
                let options = item.split('\n')
                if (options) {
                    // 第一行为标题
                    let title = _.replace(options[0], /.*?】/g, '')
                    // 类型
                    let type = options[0].substr(0, options[0].indexOf('】'))
                    // 查找问题结构定义
                    let fieldConfig = _.find(basicComponents, function(o) {
                        return o.config.label === type
                    })
                    if (!fieldConfig) {
                        continue
                    }
                    let tempFieldConfig = JSON.parse(JSON.stringify(fieldConfig))
                    if (tempFieldConfig.config.options) {
                        // 去除第一个元素
                        let removeFirstArr = _.drop(options)
                        let itemOptions = []
                        for (let j = 0; j < removeFirstArr.length; j++) {
                            if (removeFirstArr[j]) {
                                itemOptions.push({
                                    label: removeFirstArr[j],
                                    value: new Date().getTime() + j
                                })
                            }
                        }
                        tempFieldConfig.config.options = itemOptions
                    }
                    tempFieldConfig.config.formId = `${_.toLower(tempFieldConfig.typeId)}-${new Date().getTime() + i}`
                    tempFieldConfig.config.label = title
                    tempFieldConfig.vModel = `${tempFieldConfig.formId}`
                    fields.push(tempFieldConfig)
                }
            }
            this.formConf.fields = fields
            this.formParseKey = 'import-preview' + new Date()
        },
        handleFullScreen() {
            this.fullscreen = !this.fullscreen
        },
        handleImport() {
            let itemList = this.formConf.fields.map(item => {
                console.log(item)
                return formItemConvertData(item, this.formKey)
            })
            console.log(itemList)
            batchCreateProjectItem(itemList).then(res => {
                this.msgSuccess('保存成功')
                this.$emit('success')
                this.dialogVisible = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.body {
  padding: 5px;
  height: 100%;
}

::v-deep .el-dialog__body {
  padding: 0px !important;
  margin-bottom: 0px !important;
  overflow-y: hidden !important;
}

.right {
  height: 700px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px 40px;
}

</style>

