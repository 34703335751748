import _ from 'lodash'
import {getComponentsObj} from '../components/GenerateForm/config'

/**
 * 表单json转换为后台需要的对象
 * @param item
 */
export function formItemConvertData(item, formKey) {
    const data = {
        type: item.typeId,
        formItemId: item.config.formId,
        label: item.config.label,
        // 转换字符串 防止报错
        defaultValue: _.isObject(item.config.defaultValue) ? JSON.stringify(item.config.defaultValue) : item.config.defaultValue,
        required: item.config.required,
        placeholder: item.placeholder,
        regList: item.config.regList,
        showLabel: item.config.showLabel,
        span: item.config.span,
        displayType: item.config.displayType,
        scheme: item,
        formKey
    }
    return data
}

/**
 * 后台存储的数据转换为elementui表单需要的Json
 * @param data
 */
export function dbDataConvertForItemJson(data) {
    const {
        required,
        placeholder
    } = data
    if (required && !placeholder) { // 必填项目验证未填默认提示语
        data.placeholder = '此题为必填项目'
    }
    const jsonItem = data.scheme
    jsonItem.dId = data.id
    jsonItem.sort = data.sort
    jsonItem.typeId = data.type
    jsonItem.displayType = data.displayType
    jsonItem.config.span = data.span
    jsonItem.config.formId = data.formItemId
    jsonItem.config.label = data.label
    jsonItem.config.required = data.required
    jsonItem.config.regList = data.regList
    jsonItem.config.showLabel = data.showLabel
    jsonItem.config.defaultValue = data.defaultValue
    if (isJSON(data.defaultValue)) {
        // 转成json对象 不然是String 组件会报错
        jsonItem.config.defaultValue = JSON.parse(data.defaultValue)
    } else if (data.defaultValue && isNumber(data.defaultValue)) {
        // 转换成数字 不然默认选中可能会失效
        jsonItem.config.defaultValue = _.toNumber(data.defaultValue)
    }
    // url加载全部那前端最新的 避免发生接口修改 数据库里的数据为旧的接口 导致请求失败
    let config = getComponentsObj()[data.type]
    if (config) {
        jsonItem.action = config.action
    }
    // 结束
    // 文件上传不同项目地址区分 动态修改上传地址
    if (jsonItem.action && _.endsWith(jsonItem.action, 'upload/')) {
        jsonItem.action += data.formKey
    }
    jsonItem.regList = data.regList
    jsonItem.placeholder = data.placeholder
    jsonItem.formItemId = data.formItemId
    jsonItem.vModel = data.formItemId
    // key 用来重新渲染组件
    jsonItem.key = data.formItemId + new Date().getTime() + data.id
    return jsonItem
}

function isNumber(val) {
    const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
    const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
        return true
    }
    return false
}

function isJSON(str) {
    if (typeof str === 'string') {
        try {
            const obj = JSON.parse(str)
            if (typeof obj === 'object' && obj) {
                return true
            }
            return false
        } catch (e) {
            console.log(`error：${str}!!!${e}`)
            return false
        }
    }
    return false
}
