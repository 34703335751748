<template>
    <div>
        <el-form-item
            label="至少应选"
        >
            <span slot="label">
                至少应选
                <el-tooltip class="item" content="至少应选需要和最多可选一起使用，并且小于最多可选，否则将导致多选无法选中" effect="dark" placement="top-start">
                    <i class="el-icon-warning" />
                </el-tooltip>
            </span>
            <el-input-number
                :min="0"
                :value="localActiveData.min"
                placeholder="至少应选"
                @input="$set(localActiveData, 'min', $event ? $event : undefined)"
            />
        </el-form-item>
        <el-form-item
            label="最多可选"
        >
            <el-input-number
                :min="0"
                :value="localActiveData.max"
                placeholder="最多可选"
                @input="$set(localActiveData, 'max', $event ? $event : undefined)"
            />
        </el-form-item>
        <el-form-item
            v-if="localActiveData.config.optionType !== undefined"
            label="选项样式"
        >
            <el-radio-group v-model="localActiveData.config.optionType">
                <el-radio-button label="default">
                    默认
                </el-radio-button>
                <el-radio-button label="button">
                    按钮
                </el-radio-button>
            </el-radio-group>
        </el-form-item>
        <config-item-option :active-data="localActiveData" />
    </div>
</template>

<script>
import ConfigItemOption from './option'

export default {
    name: 'ConfigItemCheckbox',
    components: {
        ConfigItemOption
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style scoped>

</style>
