var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "home-container" },
        [
          _c(
            "el-header",
            { staticClass: "header-container", attrs: { height: "38" } },
            [
              _c("div", [
                _c("img", {
                  staticClass: "header-logo-img",
                  attrs: { src: require("@/assets/images/logo.png") },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ path: "/" })
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "right-header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-end",
                        trigger: "click",
                        width: "150",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "user-person-menu" },
                        [
                          _c("div", [
                            _vm.getUserInfo
                              ? _c("p", { staticClass: "nick-name" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.getUserInfo.name) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("el-divider"),
                          _c(
                            "div",
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "person-menu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: "/home/member",
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("font-icon", {
                                    staticClass: "fab fa-get-pocket",
                                  }),
                                  _vm._v(" 我的账户 "),
                                ],
                                1
                              ),
                              _c("el-divider"),
                              _c(
                                "p",
                                {
                                  staticClass: "person-menu-item",
                                  on: { click: _vm.logoutHandle },
                                },
                                [
                                  _c("font-icon", {
                                    staticClass: "fas fa-sign-out",
                                  }),
                                  _vm._v(" 退出登录 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [
                          _vm.getUserInfo
                            ? _c("img", {
                                staticClass: "user-avatar",
                                attrs: { src: _vm.getUserInfo.avatar },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-container",
            { staticClass: "content-container" },
            [
              _c(
                "el-aside",
                { attrs: { width: "280px" } },
                [
                  _c(
                    "el-card",
                    { staticClass: "menu-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.createBlankTemplate },
                        },
                        [_vm._v(" 新建项目 ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "menu-view" },
                        _vm._l(_vm.menuList, function (menu) {
                          return _c(
                            "div",
                            {
                              key: menu.route,
                              class:
                                _vm.defaultActiveMenu == menu.route
                                  ? "menu-item-active menu-item"
                                  : "menu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.menuClickHandle(menu)
                                },
                              },
                            },
                            [
                              _c("font-icon", { class: menu.icon }),
                              _c("span", [_vm._v(_vm._s(menu.name))]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-container",
                { staticClass: "scroll-container" },
                [
                  _c(
                    "el-container",
                    { staticClass: "view-container" },
                    [
                      _c(
                        "el-main",
                        [
                          _c("router-view"),
                          _c("el-footer", [
                            _c("div", { staticClass: "about-container" }, [
                              _c("span", { staticClass: "desc-text" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://www.tduckcloud.com",
                                      target: "view_window",
                                    },
                                  },
                                  [_vm._v("问卷系统")]
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }