<template>
    <div>
        <el-input-number v-if="item&&item.config.dataType&&['integer','float','number'].includes(item.config.dataType.type)" v-model.number="changeValue" v-bind="{...item}" />
        <el-input
            v-else
            v-model="changeValue"
            v-bind="{...item}"
        >
            <template v-if="item.prepend" slot="prepend">
                {{ item.prepend }}
            </template>
            <template v-if="item.append" slot="append">
                {{ item.append }}
            </template>
        </el-input>
    </div>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TInput',
    mixins: [mixin]

}
</script>

<style scoped>

</style>
