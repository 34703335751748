export default {
    props: ['value', 'item'],
    data() {
        return {
            config: null,
            changeValue: this.value
        }
    },
    computed: {},
    created() {
        if (this.item) {
            this.config = this.item.config
        }
    },
    watch: {
        changeValue(val) {
            this.$emit('input', val)
        },
        value(val) {
            this.changeValue = val
        }
    },
    mounted() {

    },
    methods: {
        getOptions() {
            return this.item.config.options
        }

    }
}
