var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { class: _vm.className, attrs: { span: _vm.config.span } },
    [
      _vm.currentItem.typeId === "SUB_FORM"
        ? [
            _c(
              "div",
              {
                staticClass: "batch-box",
                on: {
                  click: function ($event) {
                    return _vm.emitEvent("activeItem", _vm.currentItem)
                  },
                },
              },
              [
                _c("div", { staticClass: "batch-label" }, [
                  _vm._v(" 子表单 "),
                  _c("span", { staticClass: "desc-text" }, [
                    _vm._v("需拖拽组件到这里子表单生效"),
                  ]),
                ]),
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "draggable-box",
                      attrs: {
                        id: _vm.currentItem.formId,
                        list: _vm.currentItem.childList,
                        move: _vm.handleMove,
                        draggable: ".drag-move",
                        tag: "div",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange($event, _vm.currentItem)
                        },
                      },
                    },
                    "draggable",
                    {
                      group: _vm.insertAllowed ? "componentsGroup" : "",
                      ghostClass: "moving",
                      animation: 180,
                      handle: ".drag-move",
                    },
                    false
                  ),
                  [
                    _c(
                      "transition-group",
                      { staticClass: "list-main", attrs: { tag: "div" } },
                      _vm._l(
                        _vm.currentItem.childList,
                        function (child, currentIndex) {
                          return _c(
                            "div",
                            {
                              key: child.vModel,
                              staticClass: "drag-move",
                              class:
                                _vm.activeId === child.config.formId
                                  ? "active-from-item"
                                  : "",
                              on: {
                                click: (event) => {
                                  _vm.activeChildItem(
                                    event,
                                    child,
                                    currentIndex,
                                    _vm.currentItem
                                  )
                                },
                              },
                            },
                            [
                              _c("form-item", {
                                key: child.renderKey,
                                staticClass: "form-item",
                                attrs: {
                                  "current-item": child,
                                  index: currentIndex,
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "drawing-item-copy",
                                  attrs: { title: "复制" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyChildItem(child)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-copy-document",
                                  }),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "drawing-item-delete",
                                  attrs: { title: "删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteChildItem(_vm.index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "drawing-item-copy",
                attrs: { title: "复制" },
                on: {
                  click: function ($event) {
                    return _vm.emitEvent(
                      "copyItem",
                      _vm.currentItem,
                      _vm.drawingList
                    )
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-copy-document" })]
            ),
            _c(
              "span",
              {
                staticClass: "drawing-item-delete",
                attrs: { title: "删除" },
                on: {
                  click: function ($event) {
                    return _vm.emitEvent(
                      "deleteItem",
                      _vm.currentItem,
                      _vm.drawingList
                    )
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-delete" })]
            ),
          ]
        : [
            _c("form-item", {
              key: _vm.currentItem.renderKey,
              staticClass: "form-item",
              attrs: { "current-item": _vm.currentItem, index: _vm.index },
              nativeOn: {
                click: function ($event) {
                  return _vm.emitEvent("activeItem", _vm.currentItem)
                },
              },
            }),
            _c(
              "span",
              {
                staticClass: "drawing-item-copy",
                attrs: { title: "复制" },
                on: {
                  click: function ($event) {
                    return _vm.emitEvent(
                      "copyItem",
                      _vm.currentItem,
                      _vm.drawingList
                    )
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-copy-document" })]
            ),
            _c(
              "span",
              {
                staticClass: "drawing-item-delete",
                attrs: { title: "删除" },
                on: {
                  click: function ($event) {
                    return _vm.emitEvent(
                      "deleteItem",
                      _vm.currentItem,
                      _vm.drawingList
                    )
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-delete" })]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }