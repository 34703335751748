var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.config.fileSize !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "文件大小" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入文件大小" },
                  model: {
                    value: _vm.localActiveData.config.fileSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.localActiveData.config,
                        "fileSize",
                        _vm._n($$v)
                      )
                    },
                    expression: "localActiveData.config.fileSize",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "66px" },
                      attrs: { slot: "append" },
                      slot: "append",
                      model: {
                        value: _vm.localActiveData.config.sizeUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.localActiveData.config, "sizeUnit", $$v)
                        },
                        expression: "localActiveData.config.sizeUnit",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "KB", value: "KB" } }),
                      _c("el-option", { attrs: { label: "MB", value: "MB" } }),
                      _c("el-option", { attrs: { label: "GB", value: "GB" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "多选文件" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.localActiveData.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "multiple", $$v)
              },
              expression: "localActiveData.multiple",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大文件个数" } },
        [
          _c("el-input-number", {
            model: {
              value: _vm.localActiveData.limit,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "limit", $$v)
              },
              expression: "localActiveData.limit",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }