var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-setting-view" },
    [
      _c(
        "el-form",
        {
          ref: "submitSettingForm",
          attrs: { model: _vm.submitSettingForm, rules: _vm.settingRules },
        },
        [
          _c("p", { staticClass: "project-setting-title" }, [
            _vm._v(" 提交设置 "),
          ]),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 显示提示图片 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.submitSettingForm.promptImg,
                  callback: function ($$v) {
                    _vm.$set(_vm.submitSettingForm, "promptImg", $$v)
                  },
                  expression: "submitSettingForm.promptImg",
                },
              }),
            ],
            1
          ),
          _vm.submitSettingForm.promptImg
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c(
                        "el-image",
                        {
                          staticClass: "submit-prompt-img",
                          attrs: {
                            src: _vm.submitSettingForm.promptImgUrl,
                            fit: "cover ",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-upload",
                    {
                      ref: "logoUpload",
                      attrs: {
                        action: _vm.getUploadUrl,
                        headers: _vm.getUploadHeader,
                        "on-success": _vm.uploadSubmitPromptHandle,
                        "show-file-list": false,
                        accept:
                          ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            type: "text",
                          },
                          slot: "trigger",
                        },
                        [_vm._v(" 请上传提示图片 * ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 显示提示文字 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.submitSettingForm.promptText,
                  callback: function ($$v) {
                    _vm.$set(_vm.submitSettingForm, "promptText", $$v)
                  },
                  expression: "submitSettingForm.promptText",
                },
              }),
            ],
            1
          ),
          _vm.submitSettingForm.promptText
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "promptTextContent",
                    rules: [
                      {
                        required: true,
                        message: "请输入提示内容",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "提交成功 !" },
                    model: {
                      value: _vm.submitSettingForm.promptTextContent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitSettingForm,
                          "promptTextContent",
                          $$v
                        )
                      },
                      expression: "submitSettingForm.promptTextContent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "setting-item" },
            [
              _c("p", { staticClass: "label" }, [_vm._v(" 跳转网页地址 ")]),
              _c("el-switch", {
                model: {
                  value: _vm.submitSettingForm.submitJump,
                  callback: function ($$v) {
                    _vm.$set(_vm.submitSettingForm, "submitJump", $$v)
                  },
                  expression: "submitSettingForm.submitJump",
                },
              }),
            ],
            1
          ),
          _vm.submitSettingForm.submitJump
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "submitJumpUrl",
                    rules: [
                      {
                        required: true,
                        message: "请输入跳转地址",
                        trigger: "blur",
                      },
                      {
                        type: "url",
                        message: "请输入正确的url地址",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "https://demo.tduckapp.com" },
                    model: {
                      value: _vm.submitSettingForm.submitJumpUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitSettingForm, "submitJumpUrl", $$v)
                      },
                      expression: "submitSettingForm.submitJumpUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "submit-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveSettingHandle },
                },
                [_vm._v(" 保存设置 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }