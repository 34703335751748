var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        _vm._b(
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              action: _vm.item.action,
              "before-upload": _vm.beforeUpload,
              "file-list": _vm.changeValue,
              "on-progress": _vm.handleProgress,
              "on-exceed": _vm.handleExceed,
              "on-success": _vm.uploadSuccess,
              "list-type": "picture-card",
            },
            scopedSlots: _vm._u([
              {
                key: "file",
                fn: function ({ file }) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-image", {
                        staticClass: "el-upload-list__item-thumbnail",
                        attrs: {
                          "preview-src-list": [file.url],
                          src: file.url,
                        },
                      }),
                      _c("div", { staticClass: "actions" }, [
                        !_vm.$parent.form.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                },
              },
            ]),
          },
          "el-upload",
          { ..._vm.item },
          false
        ),
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }