import FormDesign from './components/FormDesign/index.vue'
import BizProjectForm from './components/BizProjectForm/index.vue'
import GenerateFormContainer from './components/GenerateForm/index.vue'
import GenerateForm from './components/GenerateForm/GenerateForm.vue'
import GenerateConfig from './components/GenerateForm/config.js'
import { formItemConvertData, dbDataConvertForItemJson } from './utils/convert.js'
import { ACCESS_TOKEN, BASE_URL, ACCESS_TOKEN_NAME } from './api/request.js'

FormDesign.install = function(Vue) {
    Vue.component(FormDesign.name, FormDesign)
}
// 最基础表单组件
GenerateForm.install = function(Vue) {
    Vue.component(GenerateForm.name, GenerateForm)
}
// 表单组件容器 包含分页 主题等业务
GenerateFormContainer.install = function(Vue) {
    Vue.component(FormDesign.name, FormDesign)
}
// 业务表单 包含数据处理等逻辑 填写限制等
BizProjectForm.install = function(Vue) {
    Vue.component(BizProjectForm.name, BizProjectForm)
}
const install = function(Vue, opts = {}) {
    Vue.use(FormDesign)
    Vue.use(GenerateForm)
    Vue.use(GenerateFormContainer)
    Vue.use(BizProjectForm)
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export {
    install,
    FormDesign,
    GenerateForm,
    BizProjectForm
}

export default {
    install,
    FormDesign,
    GenerateForm,
    BizProjectForm,
    GenerateConfig,
    GenerateFormContainer,
    convert: {
        formItemConvertData,
        dbDataConvertForItemJson
    },
    constant: {
        BASE_URL,
        ACCESS_TOKEN_NAME,
        ACCESS_TOKEN
    }
}
