<template>
    <div class="">
        <el-input
            v-model="value"
            :disabled="disabled"
            readonly="readonly"
            :placeholder="placeholder"
            read-only
            style="cursor: pointer;"
            @click.native="showPicker"
        />
        <van-popup v-model="visible" round position="bottom">
            <van-cascader
                v-model="cascaderValue"
                :options="options"
                title="点击选择所在地区"
                :field-names="fieldNames"
                @close="visible = false"
                @finish="handleConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import {
    regionData
} from './data'
import { Cascader, Popup } from 'vant'
import 'vant/lib/popup/style'
import 'vant/lib/cascader/style'

export default {
    name: 'MobileProvinceCity',
    components: {
        [Popup.name]: Popup,
        [Cascader.name]: Cascader
    },
    props: {
        placeholder: null,
        value: null
    },
    data() {
        return {
            showValue: '',
            disabled: false,
            visible: false,
            options: regionData,
            fieldNames: {
                text: 'label',
                value: 'label',
                children: 'children'
            },
            cascaderValue: ''
        }
    },
    beforeCreate() {
        const meta = document.createElement('meta')
        meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        meta.name = 'viewport'
        document.getElementsByTagName('head')[0].appendChild(meta)
    },
    methods: {
        /** 显示 Picker */
        showPicker() {
            this.visible = true
        },
        /** 处理确认选择 */
        handleConfirm({ selectedOptions }) {
            this.visible = false
            this.value = selectedOptions.map(option => option.label)
            this.showValue = this.value.join('/')
            this.$emit('input', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/vant";
</style>
