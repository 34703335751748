<template>
    <el-slider v-model="changeValue"
               v-bind="{...item}"
    />
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TSlider',
    mixins: [mixin],
    data() {
        return {
        }
    },
    methods: {
        handleChange(val) {

        }
    }
}
</script>

<style scoped>

</style>
