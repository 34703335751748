var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "最大值" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "最大输入值" },
            model: {
              value: _vm.localActiveData.max,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "max", $$v)
              },
              expression: "localActiveData.max",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最小值" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "最小输入值" },
            model: {
              value: _vm.localActiveData.min,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "min", $$v)
              },
              expression: "localActiveData.min",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "步长" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "输入每次增加的数值" },
            model: {
              value: _vm.localActiveData.step,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "step", $$v)
              },
              expression: "localActiveData.step",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }