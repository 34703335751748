// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1632795697691");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1632795697691");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1632795697691");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: \"iconfont\"; /* Project id 2839103 */\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\r\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\r\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n[class^=\"tduck-\"], [class*=\" tduck-\"] {\r\n  font-family: \"iconfont\" !important;\r\n  font-size: 17px;\r\n  font-style: normal;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\n}\n.tduck-taiyang:before {\r\n  content: \"\\e64c\";\n}\n.tduck-biaoqing-weixiao:before {\r\n  content: \"\\e611\";\n}\n.tduck-dianzan:before {\r\n  content: \"\\ec7f\";\n}\n.tduck-aixin:before {\r\n  content: \"\\e8ab\";\n}\n.tduck-star:before {\r\n  content: \"\\e9a1\";\n}\n.tduck-moonyueliang:before {\r\n  content: \"\\e6dd\";\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
