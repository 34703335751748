<template>
    <p />
</template>

<script>
export default {
    name: 'ConfigItemSubForm'
}
</script>

<style scoped>

</style>
