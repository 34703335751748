var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "theme-container" },
    [
      _c(
        "div",
        { staticClass: "left-container" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "left-scrollbar-container" },
            [
              _c("p", { staticClass: "theme-title" }, [_vm._v(" 外观主题 ")]),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "theme-prompt-text" }, [
                      _vm._v("风格"),
                    ]),
                  ]),
                  _vm._l(_vm.styleList, function (item) {
                    return _c("el-col", { key: item.key, attrs: { span: 3 } }, [
                      _c(
                        "span",
                        {
                          staticClass: "style-btn",
                          class: {
                            "style-btn-active": _vm.activeStyle == item.key,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.activeStyleHandle(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.label) + " ")]
                      ),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "theme-prompt-text" }, [
                      _vm._v("颜色"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "span",
                      {
                        staticClass: "style-btn",
                        class: { "style-btn-active": _vm.activeColor == "" },
                        on: {
                          click: function ($event) {
                            return _vm.activeColorHandle("")
                          },
                        },
                      },
                      [_vm._v("全部")]
                    ),
                  ]),
                  _vm._l(_vm.colorList, function (c) {
                    return _c("el-col", {
                      key: c,
                      staticClass: "color-btn",
                      class: { "style-btn-active": _vm.activeColor == c },
                      style: { backgroundColor: c },
                      attrs: { span: 3 },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.activeColorHandle(c)
                        },
                      },
                    })
                  }),
                ],
                2
              ),
              _c(
                "el-row",
                _vm._l(_vm.themeList, function (t) {
                  return _c(
                    "el-col",
                    {
                      key: t.id,
                      staticClass: "theme-img-view",
                      attrs: { span: 8 },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.activeThemeHandle(t)
                        },
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          class: {
                            "head-list-view-select": _vm.activeTheme.id == t.id,
                          },
                        },
                        [
                          _c("el-image", {
                            staticClass: "head-list-img",
                            class: {
                              "head-list-img-active":
                                _vm.activeTheme.id == t.id,
                            },
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: t.headImgUrl, fit: "cover" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pre-view", {
        key: _vm.projectFormKey,
        attrs: { "form-key": _vm.formKey },
      }),
      _c(
        "div",
        { staticClass: "right-container" },
        [
          _c("p", { staticClass: "right-title" }, [_vm._v(" 外观设置 ")]),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("添加logo"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    on: {
                      change: function ($event) {
                        return _vm.cleanFormSave("logoImg")
                      },
                    },
                    model: {
                      value: _vm.showSettings.logoSetting,
                      callback: function ($$v) {
                        _vm.$set(_vm.showSettings, "logoSetting", $$v)
                      },
                      expression: "showSettings.logoSetting",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showSettings.logoSetting
            ? _c(
                "el-row",
                { attrs: { align: "middle", type: "flex" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("span", { staticClass: "option-line-sub-title" }, [
                      _vm._v("logo设置"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _vm.userFormTheme.logoImg
                      ? _c("img", {
                          staticStyle: { width: "30px", height: "30px" },
                          attrs: { src: _vm.userFormTheme.logoImg },
                        })
                      : _vm._e(),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { offset: 6, span: 8 } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "logoUpload",
                          attrs: {
                            action: _vm.getUploadUrl,
                            headers: _vm.getUploadHeader,
                            "on-success": _vm.uploadLogoHandle,
                            "show-file-list": false,
                            accept:
                              ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "trigger",
                                size: "small",
                                type: "text",
                              },
                              slot: "trigger",
                            },
                            [_vm._v(" 上传Logo ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showSettings.logoSetting
            ? _c(
                "el-row",
                { attrs: { align: "middle", type: "flex" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("span", { staticClass: "option-line-sub-title" }, [
                      _vm._v("logo位置"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.saveUserTheme },
                          model: {
                            value: _vm.userFormTheme.logoPosition,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormTheme, "logoPosition", $$v)
                            },
                            expression: "userFormTheme.logoPosition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v(" 左对齐 "),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "center" } },
                            [_vm._v(" 居中 ")]
                          ),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v(" 右对齐 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("头图设置"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    on: { change: () => _vm.cleanFormSave("headImgUrl") },
                    model: {
                      value: _vm.showSettings.headImgSetting,
                      callback: function ($$v) {
                        _vm.$set(_vm.showSettings, "headImgSetting", $$v)
                      },
                      expression: "showSettings.headImgSetting",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showSettings.headImgSetting
            ? _c(
                "el-row",
                { attrs: { align: "middle", type: "flex" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("span", { staticClass: "option-line-sub-title" }, [
                      _vm._v("上传图片"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _vm.userFormTheme.headImgUrl
                      ? _c("img", {
                          staticStyle: { width: "30px", height: "30px" },
                          attrs: { src: _vm.userFormTheme.headImgUrl },
                        })
                      : _vm._e(),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { offset: 6, span: 8 } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "logoUpload",
                          attrs: {
                            action: _vm.getUploadUrl,
                            headers: _vm.getUploadHeader,
                            "on-success": _vm.uploadHeadImgHandle,
                            "show-file-list": false,
                            accept:
                              ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "trigger",
                                size: "small",
                                type: "text",
                              },
                              slot: "trigger",
                            },
                            [_vm._v(" 上传 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("背景设置"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    on: {
                      change: () => {
                        _vm.userFormTheme.backgroundImg = ""
                        _vm.userFormTheme.backgroundColor = ""
                        _vm.saveUserTheme()
                      },
                    },
                    model: {
                      value: _vm.showSettings.backgroundSetting,
                      callback: function ($$v) {
                        _vm.$set(_vm.showSettings, "backgroundSetting", $$v)
                      },
                      expression: "showSettings.backgroundSetting",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showSettings.backgroundSetting
            ? _c(
                "el-row",
                [
                  _c(
                    "el-row",
                    { attrs: { align: "middle", type: "flex" } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("span", { staticClass: "option-line-sub-title" }, [
                          _vm._v("背景类型"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { spvan: 18 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: {
                                change: () => {
                                  _vm.userFormTheme.backgroundImg = ""
                                  _vm.userFormTheme.backgroundColor = ""
                                },
                              },
                              model: {
                                value: _vm.showSettings.backgroundType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.showSettings,
                                    "backgroundType",
                                    $$v
                                  )
                                },
                                expression: "showSettings.backgroundType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "color" } },
                                [_vm._v(" 颜色 ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "img" } },
                                [_vm._v(" 图片 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showSettings.backgroundSetting &&
          _vm.showSettings.backgroundType == "color"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-row",
                    { attrs: { align: "middle", type: "flex" } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("span", { staticClass: "option-line-sub-title" }, [
                          _vm._v("选择颜色"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { spvan: 18 } },
                        [
                          _c("el-color-picker", {
                            attrs: { size: "mini" },
                            on: { change: _vm.saveUserTheme },
                            model: {
                              value: _vm.userFormTheme.backgroundColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userFormTheme,
                                  "backgroundColor",
                                  $$v
                                )
                              },
                              expression: " userFormTheme.backgroundColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showSettings.backgroundType == "img"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-row",
                    { attrs: { align: "middle", type: "flex" } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("span", { staticClass: "option-line-sub-title" }, [
                          _vm._v("选择图片"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { spvan: 18 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.getUploadUrl,
                                headers: _vm.getUploadHeader,
                                "on-success": _vm.uploadBackgroundHandle,
                                "show-file-list": false,
                                accept:
                                  ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "small",
                                    type: "text",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v(" 上传背景 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("按钮设置"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.showSettings.btnSetting,
                      callback: function ($$v) {
                        _vm.$set(_vm.showSettings, "btnSetting", $$v)
                      },
                      expression: "showSettings.btnSetting",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showSettings.btnSetting
            ? _c(
                "el-row",
                [
                  _c(
                    "el-row",
                    { attrs: { align: "middle", type: "flex" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("span", { staticClass: "option-line-sub-title" }, [
                          _vm._v("按钮提示文字"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { spvan: 10 } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: { placeholder: "请输入内容", size: "mini" },
                            on: { change: _vm.saveUserTheme },
                            model: {
                              value: _vm.userFormTheme.submitBtnText,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userFormTheme,
                                  "submitBtnText",
                                  $$v
                                )
                              },
                              expression: "userFormTheme.submitBtnText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("显示标题"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    on: { change: _vm.saveUserTheme },
                    model: {
                      value: _vm.userFormTheme.showTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.userFormTheme, "showTitle", $$v)
                      },
                      expression: "userFormTheme.showTitle",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("显示描述"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    on: { change: _vm.saveUserTheme },
                    model: {
                      value: _vm.userFormTheme.showDescribe,
                      callback: function ($$v) {
                        _vm.$set(_vm.userFormTheme, "showDescribe", $$v)
                      },
                      expression: "userFormTheme.showDescribe",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "option-line-view",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "option-line-title" }, [
                  _vm._v("显示序号"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { offset: 8, span: 8 } },
                [
                  _c("el-switch", {
                    on: { change: _vm.saveUserTheme },
                    model: {
                      value: _vm.userFormTheme.showNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.userFormTheme, "showNumber", $$v)
                      },
                      expression: "userFormTheme.showNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }