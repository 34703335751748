<template>
    <div>
        <el-form-item
            label="描述文本"
        >
            <tinymce
                :id="localActiveData.formId"
                :key="localActiveData.formId"
                v-model="localActiveData.content"
                placeholder="请输入描述文本"
            />
        </el-form-item>
    </div>
</template>

<script>
import tinymce from '../../tinymce/index'
export default {
    name: 'ConfigItemDescText',
    components: {
        tinymce
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style scoped>

</style>
