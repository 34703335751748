<template>
    <div>
        <el-divider>填空内容</el-divider>
        <el-input
            id="inputText"
            ref="inputText"
            v-model="activeData.input"
            type="textarea"
            :rows="2"
            placeholder="请输入填空内容"
        />
        <div style="margin-left: 20px;margin-bottom: 10px">
            <el-button
                icon="el-icon-circle-plus-outline"
                style="padding-bottom: 0"
                type="text"
                @mousedown.native="handleInsertInput"
            >
                插入填空
            </el-button>
        </div>
    </div>
</template>

<script>
import mixin from './mixin'

export default {
    name: 'ConfigItemHorizontalInput',
    mixins: [mixin],
    data() {
        return {}
    },
    methods: {
        handleInsertInput(event) {
            event.preventDefault()
            this.insertInportTxt('$input')
        },
        insertInportTxt(insertTxt) {
            let elInput = document.querySelector('#inputText')
            let startPos = elInput.selectionStart
            let endPos = elInput.selectionEnd
            if (startPos === undefined || endPos === undefined) return
            let txt = elInput.value
            let result = txt.substring(0, startPos) + insertTxt + txt.substring(endPos)
            elInput.value = result
            elInput.focus()
            elInput.selectionStart = startPos + insertTxt.length
            elInput.selectionEnd = startPos + insertTxt.length
            this.activeData.input = result
        }
    }
}
</script>

<style scoped>

</style>
