<template>
    <div>
        <el-input ref="main"
                  v-model="address"
                  :clearable="disabled"
                  :disabled="disabled"
                  placeholder="请选择地址"
                  @clear="handleClear"
                  @click.native="handleShow"
        />
        <van-popup v-model="box" position="bottom" :style="{ height: '100%' }">
            <div v-if="box">
                <el-input id="map__input"
                          v-model="formattedAddress"
                          :readonly="disabled"
                          class="input-map-content-input"
                          clearable
                          placeholder="输入关键字选取地点"
                          @clear="clear"
                >
                    <el-button slot="append" icon="el-icon-check" @click="handleSubmit" />
                </el-input>
                <div>
                    <div id="map__container"
                         class="input-map-content-container"
                         tabindex="0"
                    />
                    <div id="map__result"
                         class="input-map-content-result"
                    />
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>

import {Popup} from 'vant'
import 'vant/lib/popup/style'
import 'vant/lib/cascader/style'
import mixin from './mixin'

export default {
    name: 'MobileInputMap',
    components: {
        [Popup.name]: Popup
    },
    mixins: [mixin]
}
</script>
<style scoped>

::v-deep .amap-marker-img {
  width: 25px !important;
  height: 34px !important;
}

::v-deep .input-map-marker {
  position: absolute !important;
  top: -20px !important;
  right: -118px !important;
  color: #fff !important;
  padding: 4px 10px !important;
  -webkit-box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  white-space: nowrap;
  font-size: 12px;
  background-color: #25a5f7 !important;
  border-radius: 3px;
}

.input-map-content-container {
  width: 99vw;
  height: 450px;
  margin: 0 auto;
}

.input-map-content-input {
  margin-bottom: 10px;
  margin-top: 5px;
}

</style>
