var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-container" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "create-header-container" },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        staticClass: "width80",
                        attrs: { placeholder: "请输入模板名称" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.queryTemplatePage.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.queryParams.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "name", $$v)
                          },
                          expression: "queryParams.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "search-template-btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.queryTemplatePage },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-menu",
            {
              staticStyle: { "background-color": "transparent" },
              attrs: {
                "default-active": _vm.queryParams.type,
                mode: "horizontal",
              },
              on: {
                select: (index) => {
                  _vm.queryParams.type = index
                  _vm.queryTemplatePage()
                },
              },
            },
            [
              _c("el-menu-item", { attrs: { "`index`": null } }, [
                _vm._v(" 全部 "),
              ]),
              _vm._l(_vm.templateTypeList, function (item, index) {
                return _c(
                  "el-menu-item",
                  { key: index, attrs: { index: item.id.toString() } },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "project-grid-container" }, [
        _c(
          "div",
          { staticClass: "project-grid-view" },
          [
            _c(
              "div",
              {
                staticClass: "project-template-view",
                staticStyle: { display: "flex", "align-items": "center" },
                on: { click: _vm.createBlankTemplate },
              },
              [_vm._m(0)]
            ),
            _vm._l(_vm.templateList, function (template) {
              return _c(
                "div",
                { key: template.id, staticClass: "project-template-view" },
                [
                  _c("img", {
                    staticStyle: { width: "150px", height: "133px" },
                    attrs: {
                      src: template.coverImg
                        ? template.coverImg
                        : require("@/assets/images/99d0.png"),
                    },
                  }),
                  _c("p", { staticClass: "project-template-title" }, [
                    _vm._v(" " + _vm._s(template.name) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "project-template-use-view" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-view", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.toProjectTemplate(template.key)
                            },
                          },
                        },
                        [_vm._v(" 查看 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["project:template:delete"],
                              expression: "['project:template:delete']",
                            },
                          ],
                          attrs: { icon: "el-icon-delete", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(template)
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _vm.total > 10
          ? _c("el-pagination", {
              attrs: {
                "current-page": _vm.queryParams.current,
                "page-size": _vm.queryParams.size,
                total: _vm.total,
                background: "",
                layout: "total, prev, pager, next",
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryParams, "current", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryParams, "current", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.queryParams, "size", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.queryParams, "size", $event)
                },
                "current-change": _vm.queryTemplatePage,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { flex: "1" } }, [
      _c("i", {
        staticClass: "el-icon-plus",
        staticStyle: { "font-size": "40px", "align-items": "center" },
      }),
      _c("p", { staticStyle: { "font-size": "14px" } }, [
        _vm._v("创建一个空白模板"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }