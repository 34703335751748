<template>
    <div>
        <el-form-item
            v-if="localActiveData.config.tag === 'el-select'"
            label="能否搜索"
        >
            <el-switch v-model="localActiveData.filterable" />
        </el-form-item>
        <el-form-item
            v-if="localActiveData.config.tag === 'el-select'"
            label="是否多选"
        >
            <el-switch v-model="localActiveData.multiple" @change="multipleChange" />
        </el-form-item>
        <config-item-option :active-data="localActiveData" />
    </div>
</template>

<script>
import ConfigItemOption from './option'

export default {
    name: 'ConfigItemSelect',
    components: { ConfigItemOption },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    },
    methods: {
        multipleChange(val) {
            this.$set(this.localActiveData.config, 'defaultValue', val ? [] : '')
        }
    }
}
</script>

<style scoped>

</style>
