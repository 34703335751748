<template>
    <el-rate
        v-model="changeValue"
        v-bind="{...item}"
        @change="handleChange"
    />
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TRate',
    mixins: [mixin],
    data() {
        return {
            oldValue: null
        }
    },
    methods: {
        handleChange(val) {
            // 重复点击说明是想取消
            if (this.changeValue === this.oldValue) {
                this.changeValue = 0
            } else {
                this.oldValue = val
            }
        }
    }
}
</script>

<style scoped>

</style>
