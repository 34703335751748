var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-divider", [_vm._v("选项")]),
      _c(
        "draggable",
        {
          attrs: {
            animation: 340,
            list: _vm.activeData.config.options,
            group: "selectItem",
            handle: ".option-drag",
          },
        },
        _vm._l(_vm.activeData.config.options, function (item, index) {
          return _c("div", { key: index, staticClass: "select-item" }, [
            _c("div", { staticClass: "select-line-icon option-drag" }, [
              _c("i", { staticClass: "el-icon-s-operation" }),
            ]),
            _c("div", { staticClass: "width-full" }, [
              _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "选项名", size: "small" },
                    model: {
                      value: item.label,
                      callback: function ($$v) {
                        _vm.$set(item, "label", $$v)
                      },
                      expression: "item.label",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "close-btn select-line-icon",
                      on: {
                        click: function ($event) {
                          return _vm.activeData.config.options.splice(index, 1)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "图片", size: "small" },
                    model: {
                      value: item.image,
                      callback: function ($$v) {
                        _vm.$set(item, "image", $$v)
                      },
                      expression: "item.image",
                    },
                  }),
                  _c(
                    "el-upload",
                    {
                      ref: "logoUpload",
                      refInFor: true,
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        action: _vm.getUploadUrl,
                        headers: _vm.getUploadHeader,
                        "on-progress": _vm.uploadProgressHandle,
                        "on-success": (response, file, fileList) => {
                          item.image = response.data
                          _vm.$set(_vm.activeData.config.options, index, item)
                          _vm.closeUploadProgressHandle()
                        },
                        "show-file-list": false,
                        accept:
                          ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "select-line-icon",
                          attrs: { slot: "trigger" },
                          slot: "trigger",
                        },
                        [_c("i", { staticClass: "el-icon-upload" })]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticStyle: { "margin-left": "20px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.addImageCarouselItem },
            },
            [_vm._v(" 添加选项 ")]
          ),
        ],
        1
      ),
      _c("el-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }