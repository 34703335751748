var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("el-input", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          disabled: _vm.disabled,
          readonly: "readonly",
          placeholder: _vm.placeholder,
          "read-only": "",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.showPicker.apply(null, arguments)
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("van-cascader", {
            attrs: {
              options: _vm.options,
              title: "点击选择所在地区",
              "field-names": _vm.fieldNames,
            },
            on: {
              close: function ($event) {
                _vm.visible = false
              },
              finish: _vm.handleConfirm,
            },
            model: {
              value: _vm.cascaderValue,
              callback: function ($$v) {
                _vm.cascaderValue = $$v
              },
              expression: "cascaderValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }