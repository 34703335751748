var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { attrs: { span: _vm.item.config.span } },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.getItemLabel,
            "label-width": _vm.item.config.labelWidth
              ? `${_vm.item.config.labelWidth}px`
              : null,
            prop: _vm.item.vModel,
          },
        },
        [
          _vm.componentList.includes(_vm.item.typeId)
            ? [
                _c(_vm.getComponentName(_vm.item), {
                  tag: "component",
                  attrs: { item: _vm.item, models: _vm.models },
                  model: {
                    value: _vm.dataModel,
                    callback: function ($$v) {
                      _vm.dataModel = $$v
                    },
                    expression: "dataModel",
                  },
                }),
              ]
            : ["RADIO", "CHECKBOX"].includes(_vm.item.typeId)
            ? [
                _c(_vm.getComponentName(_vm.item), {
                  tag: "component",
                  attrs: { item: _vm.item, models: _vm.models },
                  on: {
                    "update:models": function ($event) {
                      _vm.models = $event
                    },
                    "other-input-change": _vm.otherInputChange,
                  },
                  model: {
                    value: _vm.dataModel,
                    callback: function ($$v) {
                      _vm.dataModel = $$v
                    },
                    expression: "dataModel",
                  },
                }),
              ]
            : ["OCR"].includes(_vm.item.typeId)
            ? [
                _c(_vm.getComponentName(_vm.item), {
                  tag: "component",
                  attrs: { item: _vm.item, models: _vm.models },
                  on: {
                    "ocr-success": (val) => {
                      _vm.$emit("ocr-success", val)
                    },
                  },
                  model: {
                    value: _vm.dataModel,
                    callback: function ($$v) {
                      _vm.dataModel = $$v
                    },
                    expression: "dataModel",
                  },
                }),
              ]
            : ["PAGINATION"].includes(_vm.item.typeId)
            ? [
                _c(
                  _vm.getComponentName(_vm.item),
                  _vm._b(
                    {
                      tag: "component",
                      attrs: { "page-text-show": _vm.pageTextShow },
                      on: {
                        next: (page) => {
                          _vm.$emit("next", page)
                        },
                        prev: (page) => {
                          _vm.$emit("prev", page)
                        },
                      },
                    },
                    "component",
                    { ..._vm.item },
                    false
                  )
                ),
              ]
            : [
                _c(
                  _vm.getComponentName(_vm.item),
                  _vm._b(
                    {
                      tag: "component",
                      model: {
                        value: _vm.dataModel,
                        callback: function ($$v) {
                          _vm.dataModel = $$v
                        },
                        expression: "dataModel",
                      },
                    },
                    "component",
                    { ..._vm.item },
                    false
                  )
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }