<template>
    <el-select
        v-model="changeValue"
        filterable
        v-bind="{...item}"
    >
        <el-option
            v-for="(option, index) in getOptions()"
            :key="option.value + index"
            :value="option.value"
            :label="option.label"
        />
    </el-select>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TSelect',
    mixins: [mixin]
}
</script>

<style scoped>

</style>
