var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        _vm._b(
          {
            model: {
              value: _vm.changeValue,
              callback: function ($$v) {
                _vm.changeValue = $$v
              },
              expression: "changeValue",
            },
          },
          "el-checkbox-group",
          { ..._vm.item },
          false
        ),
        [
          _vm.item["config"].optionType !== "button1"
            ? _vm._l(_vm.getOptions(), function (option) {
                return _c(
                  "div",
                  { key: option.value, staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        style: {
                          display: _vm.item["config"].inline
                            ? "inline-block"
                            : "block",
                        },
                        attrs: { label: option.value },
                      },
                      [_vm._v(" " + _vm._s(option.label) + " ")]
                    ),
                    option.value === 0
                      ? _c("el-input", {
                          staticClass: "other-input",
                          staticStyle: { "margin-left": "25px" },
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.otherInputValue,
                            callback: function ($$v) {
                              _vm.otherInputValue = $$v
                            },
                            expression: "otherInputValue",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              })
            : _vm._l(_vm.getOptions(), function (option) {
                return _c(
                  "el-checkbox-button",
                  {
                    key: option.value,
                    style: {
                      display: _vm.item["config"].inline
                        ? "block"
                        : "inline-block",
                    },
                    attrs: { label: option.value },
                  },
                  [_vm._v(" " + _vm._s(option.label) + " ")]
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }