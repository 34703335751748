var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.action,
            accept: "image/*",
            "before-remove": _vm.beforeRemove,
            "before-upload": _vm.handleBeforeUpload,
            "file-list": _vm.fileList,
            "on-error": _vm.handleError,
            "on-exceed": _vm.handleExceed,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            "show-file-list": false,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-search",
                loading: _vm.loading,
                size: "small",
                type: "primary",
              },
            },
            [_vm._v(" 识别文字 ")]
          ),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(" 只能上传jpg/png文件，且不超过10Mb ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }