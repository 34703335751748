<template>
    <div />
</template>

<script>
export default {
    name: 'ConfigItemDivider',
    props: ['activeData']
}
</script>

<style scoped>

</style>
