var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData["start-placeholder"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "开始占位" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入占位提示" },
                model: {
                  value: _vm.localActiveData["start-placeholder"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "start-placeholder", $$v)
                  },
                  expression: "localActiveData['start-placeholder']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData["end-placeholder"] !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "结束占位" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入占位提示" },
                model: {
                  value: _vm.localActiveData["end-placeholder"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "end-placeholder", $$v)
                  },
                  expression: "localActiveData['end-placeholder']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-tooltip",
        {
          attrs: {
            effect: "dark",
            content: "默认时间为当时填写的时间",
            placement: "top",
          },
        },
        [
          _c(
            "el-form-item",
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(" 默认当时 "),
                _c("i", { staticClass: "el-icon-question" }),
              ]),
              _c("el-switch", {
                model: {
                  value: _vm.localActiveData.config["defaultNowTime"],
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData.config, "defaultNowTime", $$v)
                  },
                  expression: "localActiveData.config['defaultNowTime']",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.localActiveData.type !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "时间类型" } },
            [
              _c(
                "el-select",
                {
                  style: { width: "100%" },
                  attrs: { placeholder: "请选择时间类型" },
                  on: { change: _vm.dateTypeChange },
                  model: {
                    value: _vm.localActiveData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.localActiveData, "type", $$v)
                    },
                    expression: "localActiveData.type",
                  },
                },
                _vm._l(_vm.dateTypeOptions, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.localActiveData.format !== undefined
        ? _c(
            "el-form-item",
            { attrs: { label: "时间格式" } },
            [
              _c("el-input", {
                attrs: {
                  value: _vm.localActiveData.format,
                  placeholder: "请输入时间格式",
                },
                on: {
                  input: function ($event) {
                    return _vm.setTimeValue($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }