var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rt-container" }, [
    _c("div", { staticClass: "rt-table" }, [
      _c(
        "div",
        { staticClass: "tr" },
        [
          _c("div", { staticClass: "td" }),
          _vm._l(_vm.table.columns, function (col, index) {
            return _c("div", { key: index, staticClass: "td" }, [
              _vm._v(" " + _vm._s(col.label) + " "),
            ])
          }),
        ],
        2
      ),
      !_vm.multiple
        ? _c(
            "div",
            { staticClass: "tbody" },
            _vm._l(_vm.table.rows, function (row) {
              return _c(
                "el-radio-group",
                {
                  key: row.id,
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.dataValue[row.id],
                    callback: function ($$v) {
                      _vm.$set(_vm.dataValue, row.id, $$v)
                    },
                    expression: "dataValue[row.id]",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tr" },
                    [
                      _c("div", { staticClass: "td" }, [
                        _vm._v(" " + _vm._s(row.label) + " "),
                      ]),
                      _vm._l(_vm.table.columns, function (col, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "td" },
                          [
                            _c("el-radio", { attrs: { label: col.label } }, [
                              _vm._v(" " + _vm._s("") + " "),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "tbody" },
            _vm._l(_vm.table.rows, function (row) {
              return _c(
                "el-checkbox-group",
                {
                  key: row.id,
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.dataValue[row.id],
                    callback: function ($$v) {
                      _vm.$set(_vm.dataValue, row.id, $$v)
                    },
                    expression: "dataValue[row.id]",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tr" },
                    [
                      _c("div", { staticClass: "td" }, [
                        _vm._v(" " + _vm._s(row.label) + " "),
                      ]),
                      _vm._l(_vm.table.columns, function (col) {
                        return _c(
                          "div",
                          { key: col.id, staticClass: "td" },
                          [
                            _c("el-checkbox", { attrs: { label: col.label } }, [
                              _vm._v(" " + _vm._s("") + " "),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            }),
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }