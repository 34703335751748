import {ACCESS_TOKEN, ACCESS_TOKEN_NAME, BASE_URL} from '../../../api/request'

export default {
    props: ['activeData'],
    data() {
        return {}
    },
    computed: {
        getUploadHeader() {
            const token = localStorage.getItem(ACCESS_TOKEN)
            let tokenName = localStorage.getItem(ACCESS_TOKEN_NAME)
            if (!tokenName) {
                tokenName = 'Authorization'
            }
            let header = {}
            header[tokenName] = token
            return header
        },
        getUploadUrl() {
            // 动态baseURL
            const baseUrl = localStorage.getItem(BASE_URL) || '/tduck-api'
            return `${baseUrl}/user/file/upload`
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        uploadProgressHandle() {
            this.loading = this.$loading({
                lock: true,
                text: '上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        closeUploadProgressHandle() {
            setTimeout(() => {
                this.loading.close()
            }, 100)
        }
    }
}
