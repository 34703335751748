var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c("p", { staticClass: "tips" }, [_vm._v("注册成为问卷系统体验用户~")]),
      _c(
        "el-tabs",
        {
          staticClass: "register-form",
          model: {
            value: _vm.regType,
            callback: function ($$v) {
              _vm.regType = $$v
            },
            expression: "regType",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "手机号注册", name: "regPhone" } },
            [
              _c(
                "el-form",
                {
                  ref: "phoneRegForm",
                  attrs: {
                    model: _vm.accountForm,
                    rules: _vm.phoneRegRules,
                    "label-width": "0px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "phoneNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "prefix-icon": "el-icon-user-solid",
                          autocomplete: "off",
                          placeholder: "请输入手机号",
                        },
                        model: {
                          value: _vm.accountForm.phoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.accountForm, "phoneNumber", $$v)
                          },
                          expression: "accountForm.phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入密码",
                          "show-password": "",
                          "prefix-icon": "el-icon-lock",
                        },
                        model: {
                          value: _vm.accountForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.accountForm, "password", $$v)
                          },
                          expression: "accountForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "code" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "code-input",
                          attrs: {
                            autocomplete: "off",
                            placeholder: "请输入验证码",
                          },
                          model: {
                            value: _vm.accountForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.accountForm, "code", $$v)
                            },
                            expression: "accountForm.code",
                          },
                        },
                        [
                          _c("font-icon", {
                            staticClass: "el-input__icon fa fa-shield",
                            attrs: { slot: "prefix" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-20",
                          attrs: {
                            disabled: _vm.phoneValidateCodeBtn,
                            type: "primary",
                          },
                          on: { click: _vm.sendPhoneCodeHandle },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.phoneValidateCodeBtnText) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "width-full",
                          attrs: { type: "primary" },
                          on: { click: _vm.phoneRegHandle },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "邮箱注册", name: "regEmail" } },
            [
              _c(
                "el-form",
                {
                  ref: "emailRegForm",
                  attrs: {
                    model: _vm.accountForm,
                    rules: _vm.emailRegRules,
                    "label-width": "0px",
                    "status-icon": "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "prefix-icon": "el-icon-user-solid",
                          autocomplete: "off",
                          placeholder: "请输入邮箱",
                        },
                        model: {
                          value: _vm.accountForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.accountForm, "email", $$v)
                          },
                          expression: "accountForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入密码",
                          "prefix-icon": "el-icon-lock",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.accountForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.accountForm, "password", $$v)
                          },
                          expression: "accountForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "code" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "code-input",
                          attrs: {
                            autocomplete: "off",
                            maxlength: "4",
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            placeholder: "请输入验证码",
                          },
                          model: {
                            value: _vm.accountForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.accountForm, "code", $$v)
                            },
                            expression: "accountForm.code",
                          },
                        },
                        [
                          _c("font-icon", {
                            staticClass: "el-input__icon fa fa-shield",
                            attrs: { slot: "prefix" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-20",
                          attrs: {
                            disabled: _vm.emailValidateCodeBtn,
                            type: "primary",
                          },
                          on: { click: _vm.sendEmailCodeHandle },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.emailValidateCodeBtnText) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "width-full",
                          attrs: { type: "primary" },
                          on: { click: _vm.emailRegHandle },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }