<template>
    <div>
        <el-form-item
            label="量级设置"
        >
            <el-input-number v-model="localActiveData.table.level" :min="1" :max="100" label="描述文字" />
        </el-form-item>
        <el-form-item
            v-if="localActiveData.table.copyWriting"
            label="最低分文案"
        >
            <el-input v-model="localActiveData.table.copyWriting.min" placeholder="请输入最低分文案" />
        </el-form-item>
        <el-form-item
            v-if="localActiveData.table.copyWriting"
            label="最高分文案"
        >
            <el-input v-model="localActiveData.table.copyWriting.max" placeholder="请输入最高分文案" />
        </el-form-item>
        <el-form-item
            label="图标"
        >
            <span v-for="icon in iconList" :key="icon" :class="[icon,icon===localActiveData.icon?'selected':'']"
                  @click="handleSelectIcon(icon)"
            />
        </el-form-item>
        <matrix-option :active-data="localActiveData" />
    </div>
</template>
<script>
import MatrixOption from './matrix'

export default {
    name: 'ConfigItemMatrixScale',
    components: {
        MatrixOption
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData,
            iconList: ['tduck-taiyang', 'tduck-biaoqing-weixiao', 'tduck-dianzan', 'tduck-aixin', 'tduck-star', 'tduck-moonyueliang']
        }
    },
    methods: {
        handleSelectIcon(icon) {
            console.log(icon)
            this.$set(this.localActiveData, 'icon', icon)
        }
    }
}
</script>

<style lang="scss" scoped>
[class^="tduck-"], [class*=" tduck-"] {
  margin: 5px;

  &:hover {
    color: rgb(247, 186, 42);
  }
}

.selected {
  color: rgb(247, 186, 42);
}

</style>
