<template>
    <div>
        <el-cascader
            v-model="value"
            :options="options"
            :props="props"
            clearable
            @change="changeHandle"
        />
    </div>
</template>

<script>
import {
    regionData
} from './data'

export default {
    name: 'ProvinceCity',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            options: regionData,
            props: {
                label: 'label',
                value: 'label',
                children: 'children'
            }
        }
    },
    methods: {
        changeHandle(val) {
            this.$emit('input', val)
        }
    }
}
</script>
