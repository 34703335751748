var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "itxst" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v(" 请将左面的选项项拖到右边完成排序 "),
        ]),
        _c(
          "draggable",
          {
            staticClass: "left-draggable",
            attrs: {
              move: _vm.onLeftMove,
              animation: "300",
              "chosen-class": "chosenClass",
              "drag-class": "dragClass",
              "ghost-class": "ghostClass",
              group: "site",
            },
            on: { end: _vm.onEnd, start: _vm.onStart },
            model: {
              value: _vm.changeSortList,
              callback: function ($$v) {
                _vm.changeSortList = $$v
              },
              expression: "changeSortList",
            },
          },
          [
            _c(
              "transition-group",
              { staticClass: "left-draggable-group" },
              _vm._l(_vm.changeSortList, function (item) {
                return _c("div", { key: item.value, staticClass: "item" }, [
                  _c("span", { staticClass: "item-name" }, [
                    _vm._v(" " + _vm._s(item.label) + " "),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("div", { staticClass: "title" }, [_vm._v(" 上下可拖动排序 ")]),
        _c(
          "draggable",
          {
            staticClass: "right-draggable",
            attrs: {
              move: _vm.onRightMove,
              animation: "100",
              "chosen-class": "chosenClass",
              "drag-class": "dragClass",
              "ghost-class": "ghostClass",
              group: "site",
            },
            on: { end: _vm.onEnd, start: _vm.onStart },
            model: {
              value: _vm.changeValue,
              callback: function ($$v) {
                _vm.changeValue = $$v
              },
              expression: "changeValue",
            },
          },
          [
            _c(
              "transition-group",
              { staticClass: "right-draggable-group" },
              _vm._l(_vm.changeValue, function (item, index) {
                return _c("div", { key: item.value, staticClass: "item" }, [
                  _c("span", { staticClass: "seq-num" }, [
                    _vm._v(" " + _vm._s(index + 1) + " "),
                  ]),
                  _c("span", { staticClass: "item-name" }, [
                    _vm._v(" " + _vm._s(item.label) + " "),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }