var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.item.multiple
        ? _c(
            "el-radio-group",
            {
              model: {
                value: _vm.changeValue,
                callback: function ($$v) {
                  _vm.changeValue = $$v
                },
                expression: "changeValue",
              },
            },
            _vm._l(_vm.getOptions(), function (option) {
              return _c(
                "div",
                { key: option.value, staticClass: "img-radio-item" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "image",
                      attrs: {
                        "preview-src-list": [option.image],
                        src: option.image,
                        fit: "scale-down",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  ),
                  _c("el-radio", { attrs: { label: option.value } }, [
                    _vm._v(" " + _vm._s(option.label) + " "),
                  ]),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.changeValue,
                callback: function ($$v) {
                  _vm.changeValue = $$v
                },
                expression: "changeValue",
              },
            },
            _vm._l(_vm.getOptions(), function (option) {
              return _c(
                "div",
                { key: option.value, staticClass: "img-radio-item" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "image",
                      attrs: {
                        "preview-src-list": [option.image],
                        src: option.image,
                        fit: "scale-down",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  ),
                  _c("el-checkbox", { attrs: { label: option.value } }, [
                    _vm._v(" " + _vm._s(option.label) + " "),
                  ]),
                ],
                1
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }