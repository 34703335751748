var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.localActiveData.config.tag === "el-image"
        ? _c(
            "el-form-item",
            { attrs: { label: "图片地址" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入图片url地址" },
                model: {
                  value: _vm.localActiveData.src,
                  callback: function ($$v) {
                    _vm.$set(_vm.localActiveData, "src", $$v)
                  },
                  expression: "localActiveData.src",
                },
              }),
              _c(
                "el-upload",
                {
                  ref: "logoUpload",
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    action: _vm.getUploadUrl,
                    headers: _vm.getUploadHeader,
                    "on-progress": _vm.uploadProgressHandle,
                    "on-success": _vm.handleUploadSuccess,
                    "show-file-list": false,
                    accept:
                      ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "trigger", size: "small", type: "text" },
                      slot: "trigger",
                    },
                    [_vm._v(" 点击上传图片 * ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }