<template>
    <div :style="getBackgroundStyle" class="project-form-wrapper">
        <div class="project-form">
            <div :style="{textAlign:getFormTheme.logoPosition}">
                <img
                    :src="getFormTheme.logoImg" class="logo-img"
                >
            </div>
            <el-image
                v-if="getFormTheme.headImgUrl"
                :src="getFormTheme.headImgUrl"
                fit="scale-down"
                style="width: 100%;"
            />
            <h4 v-if="getFormTheme.showTitle" class="form-name-text" style="text-align: center;">
                {{ formConf.title }}
            </h4>
            <div
                v-show="getFormTheme.showDescribe"
                class="form-name-text describe-html" v-html="formConf.description"
            />
            <el-divider v-if="questionMode" />
            <parser v-if="startParser"
                    :key="parserKey"
                    :form-conf="tempFormConf"
                    :logic-trigger-item-list.sync="logicTriggerItemList"
                    :page-form-model.sync="pageFormModel"
                    @next="nextPage"
                    @prev="prevPage"
                    @submit="submitForm"
                    @changeForm="changeForm"
            />
        </div>
        <p v-if="false" class="text-center support-text">
            {{ getDevSupport }}提供技术支持
        </p>
    </div>
</template>

<script>
import {deepClone} from '../../utils/index'
import Parser from './GenerateForm'
import _ from 'lodash'
import {getFormData} from '../../utils/db'

window.onload = function() {
    document.addEventListener('touchstart', event => {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    })
    document.addEventListener('gesturestart', event => {
        event.preventDefault()
    })
}
export default {
    name: 'GenerateFormContainer',
    components: {
        Parser
    },
    props: {
        // 问卷模式 给tduck问卷使用
        questionMode: {
            type: Boolean,
            default: false
        },
        formConf: {
            type: Object,
            default() {
                return {
                    fields: [],
                    logicShowRule: {}, // 逻辑规则
                    formKey: '', // 项目唯一标识
                    formRef: 'elForm', // 表单 ref名称
                    size: 'small',
                    labelPosition: 'top',
                    labelWidth: 100,
                    formRules: 'rules',
                    gutter: 15,
                    disabled: false,
                    span: 24,
                    formBtns: true, // 提交按钮是否显示
                    resetBtn: false, // 重置按钮是否显示
                    submitBtnText: '提交',
                    submitBtnColor: '#409EFF',
                    unFocusedComponentBorder: true,
                    perPageFields: {}, // 当存在分页功能 每页的数据
                    // 主题相关
                    theme: {
                        headImgUrl: '',
                        logoImg: '',
                        backgroundColor: '',
                        backgroundImg: '',
                        logoPosition: '',
                        showTitle: true,
                        showDescribe: true,
                        showNumber: false// 显示问题行号
                    }
                }
            }
        },
        bFormModel: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            // 默认主题
            defaultTheme: {
                headImgUrl: '',
                logoImg: '',
                backgroundColor: '',
                backgroundImg: '',
                logoPosition: '',
                showTitle: true,
                showDescribe: true
            },
            startParser: true,
            // 临时表单存放表单数据
            pageFormModel: this.bFormModel,
            // 逻辑显示被触发的字段列表
            logicTriggerItemList: [],
            tempFormConf: {},
            // 全部字段 避免分页时fields 为当页字段
            allFields: [],
            parserKey: +new Date()
        }
    },
    computed: {
        getFormTheme() {
            return this.formConf.theme ? this.formConf.theme : this.defaultTheme
        },
        getBackgroundStyle() {
            if (!this.formConf.theme) {
                return {}
            }
            let style = {
                backgroundColor: this.formConf.theme.backgroundColor
            }
            if (this.formConf.theme.backgroundImg) {
                style['background'] = 'url(' + this.formConf.theme.backgroundImg + ')  no-repeat center'
            }
            return style
        },
        getDevSupport() {
            let support = localStorage.getItem('FORM-SUPPORT')
            return support || 'Tduck'
        }
    },
    beforeCreate() {
        // 插入 meta 标签
        const viewport = document.createElement('meta')
        viewport.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        viewport.name = 'viewport'
        document.getElementsByTagName('head')[0].appendChild(viewport)
        document.querySelector('body').className = 'project-body'
    },
    created() {
        this.tempFormConf = deepClone(this.formConf)
        // 分页序号存放
        this.tempFormConf.pageSeqNoObj = {}
        this.allFields = this.tempFormConf.fields
        // 分页取出每页数据合并
        if (_.keys(this.tempFormConf.perPageFields)) {
            let allFields = []
            _.values(this.tempFormConf.perPageFields)
                .forEach((value, index) => {
                    allFields = allFields.concat(value)
                })
            this.allFields = allFields
        }
        // 是否有上次未提交数据
        if (this.formConf.setting && this.formConf.setting.saveNotSubmitStatus) {
            let formModel = getFormData(this.formConf.formKey)
            if (formModel) {
                this.$confirm('检查到有上次未提交的数据，是否继续填写？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.pageFormModel = formModel
                    this.parserKey = +new Date()
                }).catch(() => {
                })
            }
        }
    },
    methods: {
        // 上一页
        prevPage(params) {
            this.tempFormConf.pageSeqNoObj[params.page] = params
            this.tempFormConf.startSeqNo = this.tempFormConf.pageSeqNoObj[params.page - 1].startSeqNo
            this.switchPage(params.page - 1, params)
        },
        nextPage(params) {
            this.tempFormConf.pageSeqNoObj[params.page] = params
            this.tempFormConf.startSeqNo = params.endSeqNo
            this.switchPage(params.page + 1, params)
        },
        switchPage(page, params) {
            const {
                formModel
            } = params
            this.pageFormModel = formModel
            this.tempFormConf.formBtns = _.keys(this.formConf.perPageFields).length === page
            this.tempFormConf.fields = _.get(this.formConf.perPageFields, page)
            this.tempFormConf.currentPage = page
            this.parserKey = +new Date()
        },
        changeForm(data) {
            this.$emit('changeForm', data)
        },
        submitForm(data) {
            const {
                formModel
            } = data
            this.$emit('submit', data)
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/styles/elementui-mobile.scss';

.project-form-wrapper {
  width: 100%;
  min-height: 100%;
  padding: 20px 0px;
  background-color: rgba(158, 207, 250, 0.3);
}

.project-form {
  margin: 0px auto;
  width: 800px;
  padding: 15px;
  background-repeat: repeat;
  background-color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.project-form .describe-html img {
  max-width: 780px;
  margin: 0px;
}

.project-body::-webkit-scrollbar {
  width: 0 !important;
  background: transparent;
}

.project-body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.logo-img {
  max-height: 120px;
}

.submit-btn-form-item button {
  width: 20%;
}

.support-text {
  color: #545454;
  text-shadow: 0 1px 1px #e9e9e9;
  margin-top: 20px;
}

@media screen and (max-width: 750px) {
  .project-form {
    width: 100% !important;
  }
  .logo-img {
    max-height: 2.94rem;
  }
  .submit-btn-form-item {
    text-align: center;
  }
  .submit-btn-form-item button {
    width: 80%;
  }
  .project-form .describe-html img {
    width: 95vw !important;
  }
}
</style>
