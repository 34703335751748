<template>
    <el-radio-group
        v-model="changeValue"
        v-bind="{...item}"
        style="width: 100%"
        @change="handleClick"
    >
        <template v-if="item.config.optionType!=='button'">
            <div v-for="(option) in getOptions()" :key="option.value" style="width: 100%">
                <el-radio
                    :label="option.value"
                    :style="{display: item.config.inline ? 'inline-block' : 'block'}"
                    style="width: 100%"
                >
                    {{ option.label }}
                </el-radio>
                <el-input v-if="option.value===0" v-model="otherInputValue" style="margin-left: 25px" class="other-input" size="mini" />
            </div>
        </template>
        <template v-else>
            <el-radio-button
                v-for="(option, index) in getOptions()"
                :key="option.value + index" :label="option.value"
                :style="{display: item.config.inline ? 'inline-block' : 'block'}"
            >
                {{ option.label }}
            </el-radio-button>
        </template>
    </el-radio-group>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TRadioGroup',
    mixins: [mixin],
    props: ['models'],
    data() {
        return {
            changeValue: this.getChangeValue(),
            oldValue: null,
            otherInputValue: ''
        }
    },
    watch: {
        otherInputValue: {
            deep: true,
            handler(val) {
                this.models[`${this.item.vModel}other`] = val
                this.$emit('other-input-change', this.changeValue, this.item)
            }
        }
    },
    mounted() {
        if (this.models) {
            console.info(this.models[`${this.item.vModel}other`])
            this.otherInputValue =  this.models[`${this.item.vModel}other`] || ''
        }
    },
    methods: {
        getChangeValue() {
            return this.models[`${this.item.vModel}`]
        },
        handleClick(e) {
        }
    }
}
</script>

<style lang="scss" scoped>
.el-radio {
  min-height: 28px !important;
  line-height: 28px !important;
}

.other-input {
  margin-left: 10px;
  width: 50%;
}

::v-deep .el-radio__label {
  width: 90% !important;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: inline-block;
  vertical-align: middle;
}
</style>
