// 表单属性【右面板】
import {getBaseUrl} from '../../api/request'

export const formConf = {
    formRef: 'elForm',
    formModel: 'formData',
    size: 'mini',
    labelPosition: 'top',
    labelWidth: 100,
    formRules: 'rules',
    gutter: 20,
    disabled: false,
    span: 24,
    title: '问卷名称',
    description: '为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位\n'
    + '用户的宝贵意见，期待您的参与！现在我们就马上开始吧！',
    formBtns: true,
    unFocusedComponentBorder: true
}

// 基础组件 【基础组件】
export const basicComponents = [
    {
    // 组件的自定义配置
        typeId: 'INPUT',
        config: {
            label: '单行文本',
            labelWidth: null,
            showLabel: true,
            changeTag: true,
            tag: 't-input',
            tagIcon: 'input',
            defaultValue: undefined,
            required: true,
            span: 24,
            // 正则校验规则
            regList: [],
            prepend: '',
            append: '',
            // 数据类型
            dataType: {
                type: '',
                message: ''
            }
        },
        // 其余的为可直接写在组件标签上的属性
        placeholder: '请输入',
        style: {width: '100%'},
        clearable: true,
        'prefix-icon': '',
        'suffix-icon': '',
        maxlength: null,
        'show-word-limit': false,
        readonly: false,
        disabled: false
    },
    {
    // 组件的自定义配置
        typeId: 'NUMBER',
        config: {
            label: '数字',
            labelWidth: null,
            showLabel: true,
            changeTag: true,
            tag: 't-number',
            tagIcon: 'number',
            defaultValue: undefined,
            required: true,
            span: 24,
            // 正则校验规则
            regList: []
        },
        // 其余的为可直接写在组件标签上的属性
        placeholder: '请输入',
        style: {width: '100%'},
        min: -Infinity,
        max: Infinity,
        step: 1,
        precision: null,
        disabled: false
    },
    {
        typeId: 'TEXTAREA',
        config: {
            label: '多行文本',
            labelWidth: null,
            showLabel: true,
            tag: 'el-input',
            tagIcon: 'textarea',
            defaultValue: undefined,
            required: true,
            span: 24,
            regList: [],
            changeTag: true
        },
        type: 'textarea',
        placeholder: '请输入',
        autosize: {
            minRows: 4,
            maxRows: 4
        },
        style: {width: '100%'},
        maxlength: null,
        'show-word-limit': false,
        readonly: false,
        disabled: false
    },
    {
        typeId: 'SELECT',
        config: {
            label: '下拉选择',
            showLabel: true,
            labelWidth: null,
            tag: 't-select',
            tagIcon: 'select',
            span: 24,
            required: true,
            regList: [],
            changeTag: true,
            // 0 静态数据  1 动态数据
            optionsType: 0,
            dynamicOptions: {
                url: '',
                dataPath: '',
                valueField: '',
                labelField: ''
            },
            options: [{
                label: '选项一',
                value: 1
            }, {
                label: '选项二',
                value: 2
            }]

        },
        placeholder: '请选择',
        style: {width: '100%'},
        clearable: true,
        disabled: false,
        filterable: false,
        multiple: false
    },
    {
        typeId: 'CASCADER',
        config: {
            label: '级联选择',
            url: '',
            method: 'get',
            dataKey: 'list',
            showLabel: true,
            labelWidth: null,
            tag: 't-cascader',
            tagIcon: 'cascader',
            defaultValue: [],
            dataType: 'static',
            span: 24,
            required: true,
            regList: [],
            changeTag: true,
            // 0 静态数据  1 动态数据
            optionsType: 0,
            dynamicOptions: {
                url: '',
                dataPath: '',
                valueField: '',
                labelField: ''
            },
            options: [{
                id: 1,
                value: 1,
                label: '选项1',
                children: [{
                    id: 2,
                    value: 2,
                    label: '选项1-1'
                }]
            }]
        },
        placeholder: '请选择',
        style: {width: '100%'},
        props: {
            props: {
                multiple: false,
                label: 'label',
                value: 'value',
                children: 'children'
            }
        },
        'show-all-levels': true,
        disabled: false,
        clearable: true,
        filterable: false,
        separator: '/'
    },
    {
        typeId: 'RADIO',
        config: {
            label: '单选框组',
            labelWidth: null,
            showLabel: true,
            showRegList: false,
            tag: 't-radio-group',
            tagIcon: 'radio',
            changeTag: true,
            defaultValue: undefined,
            inline: true,
            span: 24,
            optionType: 'default',
            regList: [],
            required: true,
            border: false,
            // 0 静态数据  1 动态数据
            optionsType: 0,
            dynamicOptions: {
                url: '',
                dataPath: '',
                valueField: '',
                labelField: ''
            },
            options: [{
                label: '选项一',
                value: 1
            }, {
                label: '选项二',
                value: 2
            }]
        },
        style: {},
        size: 'medium',
        disabled: false
    },
    {
        typeId: 'CHECKBOX',
        config: {
            label: '多选框组',
            tag: 't-checkbox-group',
            tagIcon: 'checkbox',
            defaultValue: [],
            span: 24,
            showLabel: true,
            inline: false,
            showRegList: false,
            labelWidth: null,
            optionType: 'default',
            required: true,
            regList: [],
            changeTag: true,
            border: false,
            // 0 静态数据  1 动态数据
            optionsType: 0,
            dynamicOptions: {
                url: '',
                dataPath: '',
                valueField: '',
                labelField: ''
            },
            options: [{
                label: '选项一',
                value: 1
            }, {
                label: '选项二',
                value: 2
            }]
        },
        style: {},
        size: 'medium',
        min: null,
        max: null,
        disabled: false
    },
    {
        typeId: 'DATE',
        config: {
            label: '日期时间',
            tag: 't-date-time',
            tagIcon: 'date',
            defaultNowTime: false,
            defaultValue: null,
            showLabel: true,
            labelWidth: null,
            span: 24,
            required: true,
            regList: [],
            changeTag: true
        },
        placeholder: '请选择',
        type: 'date',
        style: {width: '100%'},
        disabled: false,
        clearable: true,
        format: 'yyyy-MM-dd',
        'value-format': 'yyyy-MM-dd',
        readonly: false
    },
    {
        typeId: 'RATE',
        config: {
            label: '评分',
            tag: 't-rate',
            tagIcon: 'rate',
            defaultValue: 0,
            span: 24,
            showLabel: true,
            showRegList: false,
            labelWidth: null,
            required: true,
            regList: [],
            changeTag: true
        },
        style: {},
        max: 5,
        'allow-half': false,
        'show-text': false,
        'show-score': false,
        disabled: false
    },
    {
        typeId: 'IMAGE',
        config: {
            label: '图片展示',
            showLabel: false,
            displayType: true,
            labelWidth: null,
            showDefaultValue: false,
            showRequired: false,
            showClearable: false,
            showRegList: false,
            tag: 'el-image',
            tagIcon: 'image',
            span: 24,
            required: false,
            regList: [],
            changeTag: true,
            error: 'image'
        },
        style: {width: '100%'},
        src: '',
        fit: 'contain',
        alt: ''
    },
    {
        typeId: 'IMAGE_CAROUSEL',
        config: {
            label: '图片轮播',
            showLabel: false,
            displayType: true,
            labelWidth: null,
            defaultValue: null,
            showDefaultValue: false,
            showRequired: false,
            showClearable: false,
            showRegList: false,
            tag: 't-image-carousel',
            tagIcon: 'image-carousel',
            span: 24,
            required: false,
            regList: [],
            changeTag: true,
            options: [{
                label: '文字',
                image: ''
            }]
        },
        style: {width: '100%'}
    },
    {
        typeId: 'IMAGE_SELECT',
        config: {
            label: '图片选择',
            showLabel: true,
            labelWidth: null,
            defaultValue: null,
            showDefaultValue: true,
            showRequired: true,
            showClearable: false,
            showRegList: false,
            tag: 't-image-select',
            tagIcon: 'image-select',
            span: 24,
            required: true,
            regList: [],
            changeTag: true,
            options: [{
                label: '选项1',
                value: 1
            }]
        },
        multiple: false,
        style: {width: '100%'}
    },
    {
        typeId: 'DESC_TEXT',
        config: {
            label: '文字描述',
            defaultValue: '描述文字',
            displayType: true,
            showDefaultValue: false,
            showRequired: false,
            showClearable: false,
            showRegList: false,
            tag: 'desc-text',
            tagIcon: 'text',
            span: 24,
            required: false,
            regList: [],
            changeTag: false
        },
        content: '描述文字',
        style: {width: '100%'}
    },
    {
        typeId: 'DIVIDER',
        config: {
            label: '分割线',
            defaultValue: '',
            displayType: true,
            showLabel: false,
            showDefaultValue: false,
            showRequired: false,
            showClearable: false,
            showRegList: false,
            tag: 'el-divider',
            tagIcon: 'divider',
            span: 24,
            required: false,
            regList: [],
            changeTag: false
        },
        color: '#000000',
        style: {width: '100%'}
    },
    {
        typeId: 'SLIDER',
        config: {
            label: '滑块',
            tag: 't-slider',
            tagIcon: 'slider',
            defaultValue: 0,
            span: 24,
            showLabel: true,
            showRegList: false,
            labelWidth: null,
            required: true,
            regList: [],
            changeTag: true
        },
        style: {},
        max: 100,
        min: 0,
        step: 1
    },
    {
        typeId: 'SORT',
        config: {
            label: '排序',
            tag: 't-sort',
            tagIcon: 'sort',
            defaultValue: [],
            span: 24,
            showLabel: true,
            showRegList: false,
            labelWidth: null,
            required: true,
            regList: [],
            changeTag: true
        },
        sortList: [
            {
                value: 1,
                label: '排序1'
            },
            {
                value: 2,
                label: '排序2'
            }
        ],
        style: {}
    }
]

// 高阶组件 【左面板】
export const advancedComponents = [
    {
        typeId: 'UPLOAD',
        config: {
            label: '上传',
            tag: 't-upload',
            tagIcon: 'upload',
            defaultValue: null,
            showLabel: true,
            showRegList: false,
            labelWidth: null,
            required: true,
            span: 24,
            showTip: false,
            buttonText: '点击上传',
            regList: [],
            changeTag: true,
            fileSize: 10,
            sizeUnit: 'MB'
        },
        action: `${getBaseUrl()}/form/file/upload/`,
        disabled: false,
        accept: '',
        name: 'file',
        'auto-upload': true,
        'list-type': 'text',
        limit: 2,
        multiple: false
    },
    {
        typeId: 'IMAGE_UPLOAD',
        config: {
            label: '图片上传',
            tag: 't-image-upload',
            tagIcon: 'image-upload',
            defaultValue: [],
            showLabel: true,
            showRegList: false,
            labelWidth: null,
            required: true,
            span: 24,
            showTip: false,
            buttonText: '点击上传',
            regList: [],
            changeTag: true,
            fileSize: 10,
            sizeUnit: 'MB'
        },
        action: `${getBaseUrl()}/form/file/upload/`,
        disabled: false,
        accept: 'image/*',
        name: 'file',
        'auto-upload': true,
        limit: 2,
        multiple: false
    },
    {
        typeId: 'PAGINATION',
        config: {
            label: '分页',
            defaultValue: '分页',
            displayType: true,
            showLabel: false,
            showDefaultValue: false,
            showRequired: false,
            showClearable: false,
            showRegList: false,
            tag: 'form-pagination',
            tagIcon: 'page',
            span: 24,
            required: false,
            regList: [],
            changeTag: false
        },
        prev: true,
        currPage: true,
        totalPage: true,
        currPageNum: 1,
        totalPageNum: 1,
        style: {width: '100%'}
    },
    {
        typeId: 'PROVINCE_CITY',
        config: {
            label: '省市联动',
            labelWidth: null,
            showLabel: true,
            changeTag: false,
            tag: 'province-city',
            // 移动端组件
            mobileTag: 'mobile-province-city',
            tagIcon: 'city',
            defaultValue: undefined,
            required: true,
            span: 24,
            showRegList: false,
            regList: []
        },
        placeholder: '请选择省市县',
        style: {width: '100%'}
    },
    {
        typeId: 'INPUT_MAP',
        config: {
            label: '地理位置',
            labelWidth: null,
            showLabel: true,
            changeTag: false,
            mobileTag: 'mobile-input-map',
            tag: 'input-map',
            tagIcon: 'map',
            defaultValue: undefined,
            required: true,
            span: 24,
            showRegList: false,
            regList: []
        },
        placeholder: '请选择位置',
        style: {width: '100%'}
    },
    {
        typeId: 'HORIZONTAL_INPUT',
        config: {
            label: '横向填空',
            labelWidth: null,
            showLabel: true,
            changeTag: false,
            tag: 'horizontal-input',
            showDefaultValue: false,
            tagIcon: 'horizontal-input',
            required: true,
            span: 24,
            defaultValue: [],
            showRegList: false,
            regList: []
        },
        input: '请输入横向填空内容',
        placeholder: '请输入横向填空',
        style: {width: '100%'}
    },
    {
        typeId: 'SUB_FORM',
        config: {
            label: '子表单',
            labelWidth: null,
            showLabel: true,
            changeTag: false,
            tag: 't-sub-form',
            tagIcon: 'sub-form',
            defaultValue: [],
            required: true,
            span: 24,
            showRegList: false,
            regList: []
        },
        childList: [],
        placeholder: '',
        style: {width: '100%'}
    },
    // {
    //     typeId: 'MATRIX_INPUT',
    //     config: {
    //         label: '矩阵填空',
    //         labelWidth: null,
    //         showLabel: true,
    //         changeTag: false,
    //         tag: 'matrix-input',
    //         tagIcon: 'matrix-input',
    //         defaultValue: {},
    //         required: true,
    //         span: 24,
    //         showRegList: false,
    //         regList: []
    //     },
    //     table: {
    //         rows: [
    //             {
    //                 label: '矩阵行1',
    //                 id: 1
    //             },
    //             {
    //                 label: '矩阵行2',
    //                 id: 2
    //             },
    //             {
    //                 label: '矩阵行3',
    //                 id: 3
    //             }
    //         ],
    //         columns: [
    //             {
    //                 label: '填空1',
    //                 id: 1
    //             },
    //             {
    //                 label: '填空2',
    //                 id: 2
    //             },
    //             {
    //                 label: '填空3',
    //                 id: 3
    //             }
    //         ]
    //     },
    //     placeholder: '',
    //     style: {width: '100%'}
    // },
    {
        typeId: 'MATRIX_SELECT',
        config: {
            label: '矩阵选择',
            labelWidth: null,
            showLabel: true,
            changeTag: false,
            tag: 'matrix-select',
            tagIcon: 'matrix-select',
            defaultValue: {},
            required: true,
            span: 24,
            showRegList: false,
            regList: []
        },
        table: {
            rows: [
                {
                    label: '矩阵行1',
                    id: 1
                },
                {
                    label: '矩阵行2',
                    id: 2
                },
                {
                    label: '矩阵行3',
                    id: 3
                }
            ],
            columns: [
                {
                    label: '选项1',
                    id: 1
                },
                {
                    label: '选项2',
                    id: 2
                },
                {
                    label: '选项3',
                    id: 3
                }
            ]
        },
        multiple: false,
        placeholder: '',
        style: {width: '100%'}
    },

    {
        typeId: 'MATRIX_SCALE',
        config: {
            label: '矩阵量表',
            labelWidth: null,
            showLabel: true,
            changeTag: false,
            tag: 'matrix-scale',
            tagIcon: 'matrix-scale',
            defaultValue: {},
            required: true,
            span: 24,
            showRegList: false,
            regList: []
        },
        table: {
            rows: [
                {
                    label: '矩阵行1',
                    id: 1
                },
                {
                    label: '矩阵行2',
                    id: 2
                },
                {
                    label: '矩阵行3',
                    id: 3
                }
            ],
            copyWriting: {
                max: '非常不满意',
                min: '非常满意'
            },
            level: 5
        },
        icon: 'tduck-star',
        placeholder: '',
        style: {width: '100%'}
    }
    // {
    //   typeId: 'USER_SELECT',
    //   config: {
    //     label: '员工选择',
    //     labelWidth: null,
    //     showLabel: true,
    //     changeTag: false,
    //     tag: 't-user-select',
    //     tagIcon: 'user-select',
    //     defaultValue: [],
    //     required: true,
    //     span: 24,
    //     showRegList: false,
    //     regList: []
    //   },
    //   placeholder: '',
    //   style: {width: '100%'}
    // },
    // {
    //   typeId: 'DEPT_SELECT',
    //   config: {
    //     label: '部门选择',
    //     labelWidth: null,
    //     showLabel: true,
    //     changeTag: false,
    //     tag: 't-dept-select',
    //     tagIcon: 'dept-select',
    //     defaultValue: [],
    //     required: true,
    //     span: 24,
    //     showRegList: false,
    //     regList: []
    //   },
    //   placeholder: '',
    //   style: {width: '100%'}
    // },
    // {
    //   typeId: 'OCR',
    //   config: {
    //     label: '文字识别',
    //     labelWidth: null,
    //     showLabel: true,
    //     changeTag: false,
    //     tag: 't-ocr',
    //     tagIcon: 'ocr',
    //     defaultValue: [],
    //     required: false,
    //     span: 24,
    //     showRegList: false,
    //     regList: []
    //   },
    //   ocrType: 'GENERAL',
    //   // 识别字段和表单映射规则
    //   fieldMapping:{},
    //   placeholder: '',
    //   style: {width: '100%'}
    // }
]

export const allComponents = [
    {
        title: '基础组件',
        list: basicComponents
    },
    {
        title: '进阶组件',
        list: advancedComponents
    }
]
export default {
    allComponents
}

export let componentsObj = null

export function getComponentsObj() {
    if (!componentsObj) {
        initComponentsObj()
    }
    return componentsObj
}

function initComponentsObj() {
    componentsObj = {}
    allComponents.forEach(comps => {
        comps.list.forEach(item => {
            componentsObj[item.typeId] = item
        })
    })
}
