<template>
    <div>
        <el-form-item
            v-if="localActiveData.ocrType !== undefined"
            label="识别内容"
        >
            <el-select v-model="localActiveData.ocrType" placeholder="请选择">
                <el-option
                    label="通用文字"
                    value="GENERAL"
                />
            </el-select>
            <el-button class="mt5" icon="el-icon-edit" @click="handleOcrRule">
                设置识别规则
            </el-button>
        </el-form-item>
        <el-dialog
            :visible.sync="dialogVisible"
            title="识别规则设置"
            width="30%"
        >
            <span class="text-danger">识别内容和表单字段对应的关系如下存储, 识别完成之后将按照规则将数据填充到表单字段内</span>
            <div>
                <el-button type="text" icon="el-icon-edit" @click="handleCreateFields">
                    创建默认存储字段
                </el-button>
            </div>
            <div v-if="localActiveData.fieldMapping&&Object.keys(localActiveData.fieldMapping).length">
                <div v-for="key in Object.keys(getOcrFields(localActiveData.ocrType))" :key="key">
                    <div class="m5">
                        <el-tag>{{ getOcrFields(localActiveData.ocrType)[key]['label'] }}</el-tag>
                        存储到
                        <el-tag v-if="Object.keys(localActiveData.fieldMapping)">
                            {{ getFormField(key) }}
                        </el-tag>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import {queryProjectItemList} from '../../../api/indexForm'

export default {
    name: 'ConfigItemOcr',
    components: {},
    props: ['activeData', 'formConf'],
    data() {
        return {
            dialogVisible: false,
            localActiveData: this.activeData,
            // 表单所有字段
            fieldList: [],
            // ocr默认存储字段关系
            ocrFields: {
                'GENERAL': {
                    url: {
                        type: 'IMAGE_UPLOAD',
                        label: '识别文件'
                    },
                    ocrContent: {
                        type: 'INPUT',
                        label: '识别内容'
                    }
                }
            }
        }
    },
    created() {
        this.getAllFormFields()
    },
    methods: {
        // 创建ocr识别存储的字段
        handleCreateFields() {
            this.dialogVisible = false
            this.$emit('add-fields', this.ocrFields[this.localActiveData.ocrType])
        },
        handleOcrRule() {
            this.dialogVisible = true
            this.getAllFormFields()
        },
        getOcrFields(key) {
            return this.ocrFields[key]
        },
        // 获取全部表单字段
        getAllFormFields() {
            queryProjectItemList({key: this.formConf.formKey})
                .then(res => {
                    this.fieldList = res.data
                })
        },
        getFormField(key) {
            let fieldKey = ''
            if (this.localActiveData && this.localActiveData.fieldMapping && this.localActiveData.fieldMapping[key]) {
                fieldKey = this.localActiveData.fieldMapping[key]
            }
            let field = this.fieldList.find(item => item.formItemId == fieldKey)
            return field ? field.label : ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
