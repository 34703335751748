<template>
    <div>
        <el-divider>选项</el-divider>
        <el-radio-group v-model="localActiveData.config.optionsType">
            <el-radio-button :label="0">
                静态数据
            </el-radio-button>
            <el-radio-button :label="1">
                动态数据
            </el-radio-button>
            <!--      <el-radio-button :label="2">-->
            <!--        字典数据-->
            <!--      </el-radio-button>-->
        </el-radio-group>

        <!-- 远程赋值配置 -->
        <div v-if="localActiveData.config.optionsType === 1">
            <el-input v-model="localActiveData.config.dynamicOptions.url" size="mini"
                      placeholder="https://api.tduckcloud.com/xxx.json"
            >
                <template slot="prepend">
                    接口url
                </template>
            </el-input>
            <el-input v-model="localActiveData.config.dynamicOptions.dataPath" size="mini" placeholder="data">
                <template slot="prepend">
                    列表数据jsonPath
                </template>
            </el-input>
            <el-input v-model="localActiveData.config.dynamicOptions.valueField" size="mini" placeholder="value">
                <template slot="prepend">
                    值字段
                </template>
            </el-input>
            <el-input v-model="localActiveData.config.dynamicOptions.labelField" size="mini" placeholder="label">
                <template slot="prepend">
                    标签字段
                </template>
            </el-input>
        </div>
        <div v-if="localActiveData.config.optionsType === 0">
            <draggable
                :animation="340"
                :list="localActiveData.config.options"
                group="selectItem"
                handle=".option-drag"
            >
                <div
                    v-for="(item, index) in localActiveData.config.options"
                    :key="index"
                    class="select-item"
                >
                    <div class="select-line-icon option-drag">
                        <i class="el-icon-s-operation" />
                    </div>
                    <el-input
                        v-model="item.label"
                        placeholder="选项名"
                        size="small"
                    />
                    <div
                        class="close-btn select-line-icon"
                        @click="localActiveData.config.options.splice(index, 1)"
                    >
                        <i class="el-icon-remove-outline" />
                    </div>
                </div>
            </draggable>
            <div style="margin-left: 20px;margin-bottom: 10px">
                <el-button
                    icon="el-icon-circle-plus-outline"
                    style="padding-bottom: 0"
                    type="text"
                    @click="addSelectItem"
                >
                    添加选项
                </el-button>
                <el-button
                    icon="el-icon-document-copy"
                    style="padding-bottom: 0"
                    type="text"
                    @click="openBatchEditDialogHandle"
                >
                    批量编辑
                </el-button>
                <el-button
                    v-if="
                        ['CHECKBOX', 'RADIO'].indexOf(
                            localActiveData.typeId
                        ) > -1
                    "
                    icon="el-icon-circle-plus-outline"
                    style="padding-bottom: 0"
                    type="text"
                    @click="addSelectOtherItem"
                >
                    添加其他
                </el-button>
            </div>
            <el-dialog
                title="批量编辑"
                :visible.sync="dialogVisible"
                width="30%"
            >
                <span class="desc-text">每行对应一个选项</span>
                <el-input v-model="batchItemValue" type="textarea" :autosize="{ minRows: 20, maxRows: 5000}"
                          placeholder="选项1 选项2  选项3 "
                />
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="batchEditSelectItems">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div v-if="localActiveData.config.optionsType === 2">
            <el-form-item label="字典选择" class="mt10">
                <el-select v-model="localActiveData.config.dictOptionType" placeholder="请选择字典">
                    <el-option
                        v-for="item in dictTypeList"
                        :key="item.dictType"
                        :label="item.dictName"
                        :value="item.dictType"
                    />
                </el-select>
            </el-form-item>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
// import {optionselect} from "@/api/system/dict/type"

export default {
    name: 'ConfigItemOption',
    components: {
        draggable
    },
    props: ['activeData'],
    data() {
        return {
            batchItemValue: '',
            dialogVisible: false,
            dictTypeList: [],
            localActiveData: this.activeData
        }
    },
    created() {
    // optionselect().then(res => {
    //   this.dictTypeList = res.data
    // })
    },
    methods: {
        addSelectItem() {
            const lastItem = _.last(this.localActiveData.config.options) || {value: 0}
            const value = lastItem.value !== 0 ? _.max(_.values(this.localActiveData.config.options.map(item => item.value))) + 1 : lastItem.value + 1
            this.localActiveData.config.options.push({
                label: '',
                value: lastItem ? value : 1
            })
        },
        openBatchEditDialogHandle() {
            this.batchItemValue = _.join(this.localActiveData.config.options.map(item => item.label), '\n')
            this.dialogVisible = true
        },
        async batchEditSelectItems() {
            const arrStrs = this.batchItemValue
            const arr = arrStrs.split('\n')
            if (arr.length > 1) {
                const options = []
                for (let index = 0; index < arr.length; index++) {
                    const element = arr[index]
                    if (element) {
                        options.push({
                            label: element,
                            value: index + 1
                        })
                    }
                }
                this.localActiveData.config.options = options
                this.dialogVisible = false
            }
        },
        addSelectOtherItem() {
            const item = this.localActiveData.config.options.find(option => option.value === 0)
            if (item) {
                return
            }
            this.localActiveData.config.options.push({
                label: '其他',
                value: 0
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  align-items: center;

  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }

  & .el-input + .el-input {
    margin-left: 4px;
  }
}

.select-item + .select-item {
  margin-top: 4px;
}

.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}

.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}

.option-drag {
  cursor: move;
}
</style>
