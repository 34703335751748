var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "90px", size: "small" } },
        [
          _vm.activeData.config.componentName !== undefined
            ? _c("el-form-item", { attrs: { label: "组件名" } }, [
                _vm._v(" " + _vm._s(_vm.activeData.config.componentName) + " "),
              ])
            : _vm._e(),
          _vm.activeData.config.label !== undefined &&
          _vm.activeData.config.showLabel !== false
            ? _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    on: { input: _vm.changeRenderKey },
                    model: {
                      value: _vm.activeData.config.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.config, "label", $$v)
                      },
                      expression: "activeData.config.label",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.placeholder !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "占位提示" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入占位提示" },
                    on: { input: _vm.changeRenderKey },
                    model: {
                      value: _vm.activeData.placeholder,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "placeholder", $$v)
                      },
                      expression: "activeData.placeholder",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.config.span !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "表单栅格" } },
                [
                  _c("el-slider", {
                    attrs: { marks: { 12: "" }, max: 24, min: 1 },
                    model: {
                      value: _vm.activeData.config.span,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.config, "span", $$v)
                      },
                      expression: "activeData.config.span",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.config.layout === "rowFormItem" &&
          _vm.activeData.gutter !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "栅格间隔" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "栅格间隔" },
                    model: {
                      value: _vm.activeData.gutter,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "gutter", $$v)
                      },
                      expression: "activeData.gutter",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.config.layout === "rowFormItem" &&
          _vm.activeData.type !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "布局模式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.activeData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "type", $$v)
                        },
                        expression: "activeData.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "default" } }),
                      _c("el-radio-button", { attrs: { label: "flex" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.justify !== undefined && _vm.activeData.type === "flex"
            ? _c(
                "el-form-item",
                { attrs: { label: "水平排列" } },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "100%" },
                      attrs: { placeholder: "请选择水平排列" },
                      model: {
                        value: _vm.activeData.justify,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "justify", $$v)
                        },
                        expression: "activeData.justify",
                      },
                    },
                    _vm._l(_vm.justifyOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.align !== undefined && _vm.activeData.type === "flex"
            ? _c(
                "el-form-item",
                { attrs: { label: "垂直排列" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.activeData.align,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData, "align", $$v)
                        },
                        expression: "activeData.align",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "top" } }),
                      _c("el-radio-button", { attrs: { label: "middle" } }),
                      _c("el-radio-button", { attrs: { label: "bottom" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.vModel !== undefined &&
          _vm.activeData.config.showDefaultValue !== false
            ? _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v(" 默认值 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content:
                              "默认值在编辑器中点选组件设置，无法再此处输入",
                            effect: "dark",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-input",
                    {
                      attrs: {
                        value: _vm.setDefaultValue(
                          _vm.activeData.config.defaultValue
                        ),
                        placeholder: "请输入默认值",
                        readonly: "",
                      },
                      on: { input: _vm.onDefaultValueInput },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-close" },
                        on: { click: _vm.clearDefaultValue },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.config.showLabel !== undefined &&
          _vm.activeData.config.labelWidth !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "显示标签" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.activeData.config.showLabel,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.config, "showLabel", $$v)
                      },
                      expression: "activeData.config.showLabel",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.clearable !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "能否清空" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.activeData.clearable,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "clearable", $$v)
                      },
                      expression: "activeData.clearable",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.readonly !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "是否只读" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.activeData.readonly,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "readonly", $$v)
                      },
                      expression: "activeData.readonly",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "分值" } },
            [
              _c("el-input-number", {
                attrs: { label: "分值", placeholder: "分值输入" },
                model: {
                  value: _vm.activeData.score,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "score", $$v)
                  },
                  expression: "activeData.score",
                },
              }),
            ],
            1
          ),
          _vm.activeData["color"] !== undefined
            ? _c(
                "el-form-item",
                { attrs: { label: "颜色" } },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.activeData["color"],
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "color", $$v)
                      },
                      expression: "activeData['color']",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeData.config.required !== undefined &&
          _vm.activeData.config.showRequired !== false
            ? _c(
                "el-form-item",
                { attrs: { label: "是否必填" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.activeData.config.required,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.config, "required", $$v)
                      },
                      expression: "activeData.config.required",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.getComponent
            ? _c(_vm.getComponent, {
                key: _vm.activeData.vModel,
                tag: "component",
                attrs: {
                  "active-data": _vm.activeData,
                  "form-conf": _vm.formConf,
                },
                on: {
                  "add-fields": (val) => {
                    _vm.$emit("add-fields", val)
                  },
                },
              })
            : _vm._e(),
          Array.isArray(_vm.activeData.config.regList) &&
          _vm.activeData.config.showRegList !== false
            ? [
                _c("el-divider", [_vm._v("正则校验")]),
                _vm._l(_vm.activeData.config.regList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "reg-item" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "close-btn",
                          on: {
                            click: function ($event) {
                              return _vm.activeData.config.regList.splice(
                                index,
                                1
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "表达式" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(" 表达式 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content:
                                      "输入JS正则表达式，如用户名4-20位字母 /^[a-zA-Z]{4,20}$/",
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-warning" })]
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            attrs: { placeholder: "请输入正则" },
                            model: {
                              value: item.pattern,
                              callback: function ($$v) {
                                _vm.$set(item, "pattern", $$v)
                              },
                              expression: "item.pattern",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "错误提示" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入错误提示" },
                            model: {
                              value: item.message,
                              callback: function ($$v) {
                                _vm.$set(item, "message", $$v)
                              },
                              expression: "item.message",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticStyle: { "margin-left": "20px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-circle-plus-outline",
                          type: "text",
                        },
                        on: { click: _vm.addReg },
                      },
                      [_vm._v(" 添加正则 ")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }