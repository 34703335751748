var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-logic-container" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "scrollbar-container" },
        [
          _c(
            "el-row",
            {
              staticClass: "header-row",
              attrs: { align: "middle", justify: "center", type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("p", { staticClass: "logic_title" }, [_vm._v(" 显示逻辑 ")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "top",
                        "popper-class": "question-popper",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(" 符合某项条件，则显示某道题"),
                          _c("br"),
                          _vm._v("点击查看帮助 "),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "show-logic-container" }, [
            !_vm.logicList.length
              ? _c(
                  "div",
                  { staticClass: "not-logic-container" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { offset: 10, span: 5 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: { click: _vm.addLogicHandle },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-plus-outline",
                                }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("添加逻辑"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "logic-item-container" },
                  [
                    _c(
                      "el-row",
                      {
                        attrs: {
                          align: "middle",
                          justify: "center",
                          type: "flex",
                        },
                      },
                      [
                        _c("el-col", { attrs: { offset: 1, span: 10 } }, [
                          _c("p", [
                            _vm._v(
                              "共 " +
                                _vm._s(_vm.logicList.length) +
                                ". 条显示逻辑"
                            ),
                          ]),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { offset: 6, span: 6 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.addLogicHandle },
                              },
                              [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("添加逻辑"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-divider"),
                    _vm._l(_vm.logicList, function (logicItem, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-row",
                            {
                              attrs: {
                                align: "middle",
                                justify: "center",
                                type: "flex",
                              },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { offset: 1, span: 7 } },
                                [
                                  _c("span", { staticClass: "mr-10" }, [
                                    _vm._v(_vm._s(index + 1) + "."),
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: !!logicItem.formItemId,
                                        placeholder: "请选择问题",
                                      },
                                      model: {
                                        value: logicItem.formItemId,
                                        callback: function ($$v) {
                                          _vm.$set(logicItem, "formItemId", $$v)
                                        },
                                        expression: "logicItem.formItemId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.getProjectItemList(
                                        logicItem.formItemId
                                      ),
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.label,
                                            value: item.formItemId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("span", [_vm._v("符合以下")]),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: logicItem.expression,
                                        callback: function ($$v) {
                                          _vm.$set(logicItem, "expression", $$v)
                                        },
                                        expression: "logicItem.expression",
                                      },
                                    },
                                    _vm._l(
                                      _vm.questionOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { offset: 1, span: 9 } }, [
                                _c("span", [_vm._v("条件时显示此问题：")]),
                              ]),
                            ],
                            1
                          ),
                          _vm._l(
                            logicItem.conditionList,
                            function (cItem, cIndex) {
                              return _c(
                                "el-row",
                                {
                                  key: cIndex,
                                  staticClass: "mt-5",
                                  attrs: {
                                    gutter: 20,
                                    align: "middle",
                                    justify: "center",
                                    type: "flex",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { offset: 1, span: 6 } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择题目" },
                                          model: {
                                            value: cItem.formItemId,
                                            callback: function ($$v) {
                                              _vm.$set(cItem, "formItemId", $$v)
                                            },
                                            expression: "cItem.formItemId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.getConditionProjectItemList(
                                            logicItem
                                          ),
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.label,
                                                value: item.formItemId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择条件" },
                                          model: {
                                            value: cItem.expression,
                                            callback: function ($$v) {
                                              _vm.$set(cItem, "expression", $$v)
                                            },
                                            expression: "cItem.expression",
                                          },
                                        },
                                        _vm._l(
                                          _vm.conditionOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择选项" },
                                          model: {
                                            value: cItem.optionValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                cItem,
                                                "optionValue",
                                                $$v
                                              )
                                            },
                                            expression: "cItem.optionValue",
                                          },
                                        },
                                        _vm._l(
                                          _vm.getFormItemOptions(
                                            cItem.formItemId
                                          ),
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 9 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addConditionHandle(
                                                logicItem
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus-outline",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "remove",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeConditionHandle(
                                                logicItem,
                                                index,
                                                cIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-remove-outline",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c("el-divider"),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }