<template>
    <div>
        <el-form-item
            v-if="localActiveData.config.optionType !== undefined"
            label="选项样式"
        >
            <el-radio-group v-model="localActiveData.config.optionType">
                <el-radio-button label="default">
                    默认
                </el-radio-button>
                <el-radio-button label="button">
                    按钮
                </el-radio-button>
            </el-radio-group>
        </el-form-item>
        <config-item-option :active-data="localActiveData" />
    </div>
</template>

<script>
import ConfigItemOption from './option'

export default {
    name: 'ConfigItemRadio',
    components: { ConfigItemOption },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style scoped>

</style>
