<template>
    <div>
        <el-dialog
            v-bind="$attrs"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            v-on="$listeners"
            @open="onOpen"
            @close="onClose"
        >
            <el-row :gutter="0">
                <el-form
                    ref="elForm"
                    :model="formData"
                    :rules="rules"
                    size="small"
                    label-width="100px"
                >
                    <el-col :span="24">
                        <el-form-item
                            label="选项名"
                            prop="label"
                        >
                            <el-input
                                v-model="formData.label"
                                placeholder="请输入选项名"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <!--            <el-form-item-->
                        <!--              label="选项值"-->
                        <!--              prop="value"-->
                        <!--            >-->
                        <!--              <el-input-->
                        <!--                v-model="formData.value"-->
                        <!--                placeholder="请输入选项值"-->
                        <!--                clearable-->
                        <!--              >-->
                        <!--                <el-select-->
                        <!--                  slot="append"-->
                        <!--                  v-model="dataType"-->
                        <!--                  :style="{width: '100px'}"-->
                        <!--                >-->
                        <!--                  <el-option-->
                        <!--                    v-for="(item, index) in dataTypeOptions"-->
                        <!--                    :key="index"-->
                        <!--                    :label="item.label"-->
                        <!--                    :value="item.value"-->
                        <!--                    :disabled="item.disabled"-->
                        <!--                  />-->
                        <!--                </el-select>-->
                        <!--              </el-input>-->
                        <!--            </el-form-item>-->
                    </el-col>
                </el-form>
            </el-row>
            <div slot="footer">
                <el-button
                    type="primary"
                    @click="handelConfirm"
                >
                    确定
                </el-button>
                <el-button @click="close">
                    取消
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {isNumberStr} from '../../utils'
import {getTreeNodeId, saveTreeNodeId} from '../../utils/db'

const id = getTreeNodeId()

export default {
    components: {},
    inheritAttrs: false,
    props: [],
    data() {
        return {
            id,
            formData: {
                label: undefined,
                value: undefined
            },
            rules: {
                label: [
                    {
                        required: true,
                        message: '请输入选项名',
                        trigger: 'blur'
                    }
                ],
                value: [
                    {
                        required: true,
                        message: '请输入选项值',
                        trigger: 'blur'
                    }
                ]
            },
            dataType: 'string',
            dataTypeOptions: [
                {
                    label: '字符串',
                    value: 'string'
                },
                {
                    label: '数字',
                    value: 'number'
                }
            ]
        }
    },
    computed: {},
    watch: {
        // eslint-disable-next-line func-names
        'formData.value': function(val) {
            this.dataType = isNumberStr(val) ? 'number' : 'string'
        },
        id(val) {
            saveTreeNodeId(val)
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        onOpen() {
            this.formData = {
                label: undefined,
                value: undefined
            }
        },
        onClose() {
        },
        close() {
            this.$emit('update:visible', false)
        },
        handelConfirm() {
            this.$refs.elForm.validate(valid => {
                if (!valid) return
                this.formData.id = this.id++
                // + 避免过快重复
                this.formData.value = new Date().getTime() + this.formData.id
                this.$emit('commit', this.formData)
                this.close()
            })
        }
    }
}
</script>
