var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "描述文本" } },
        [
          _c("tinymce", {
            key: _vm.localActiveData.formId,
            attrs: {
              id: _vm.localActiveData.formId,
              placeholder: "请输入描述文本",
            },
            model: {
              value: _vm.localActiveData.content,
              callback: function ($$v) {
                _vm.$set(_vm.localActiveData, "content", $$v)
              },
              expression: "localActiveData.content",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }