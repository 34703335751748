<template>
    <el-carousel>
        <el-carousel-item v-for="(option, index) in getOptions()" :key="index">
            <p class="text-center">
                {{ item.label }}
            </p>
            <el-image fit="contain" :src="option.image" />
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import mixin from '../mixin'

export default {
    name: 'TImageCarousel',
    mixins: [mixin]
}
</script>

<style scoped>

</style>
