import Vue from 'vue'

// 防重复点击(指令实现)
const preventReClick = Vue.directive('preventReClick', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 3000)
            }
        })
    }
})

export default {
    preventReClick
}
