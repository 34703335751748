var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right-board" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "center-tabs",
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "组件属性", name: "field" } }, [
            _c(
              "div",
              { staticClass: "field-box" },
              [
                _c(
                  "el-scrollbar",
                  { staticClass: "right-scrollbar" },
                  [
                    _vm.showField && _vm.activeData
                      ? _c("form-item-config", {
                          attrs: {
                            "active-data": _vm.activeData,
                            "form-conf": _vm.formConf,
                          },
                          on: {
                            "add-fields": _vm.handleAddFields,
                            "data-change": _vm.dataChange,
                          },
                        })
                      : _c("el-empty", {
                          attrs: { description: "暂无选中组件" },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }