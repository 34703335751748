<template>
    <matrix-option :active-data="localActiveData" />
</template>
<script>
import MatrixOption from './matrix'

export default {
    name: 'ConfigItemMatrixInput',
    components: {
        MatrixOption
    },
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
