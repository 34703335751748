var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("textarea", {
    staticStyle: { visibility: "hidden" },
    attrs: { id: _vm.tinymceId },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }