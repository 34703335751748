<template>
    <div>
        <el-form-item
            v-if="localActiveData.config.tag === 'el-image'"
            label="图片地址"
        >
            <el-input
                v-model="localActiveData.src"
                placeholder="请输入图片url地址"
            />
            <el-upload
                ref="logoUpload"
                :action="getUploadUrl"
                :headers="getUploadHeader"
                :on-progress="uploadProgressHandle"
                :on-success="handleUploadSuccess"
                :show-file-list="false"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                style="text-align: center"
            >
                <el-button slot="trigger" size="small" type="text">
                    点击上传图片 *
                </el-button>
            </el-upload>
        </el-form-item>
    </div>
</template>

<script>
import mixin from './mixin'

export default {
    name: 'ConfigItemImage',
    mixins: [mixin],
    props: ['activeData'],
    data() {
        return {
            localActiveData: this.activeData
        }
    },
    methods: {
        handleUploadSuccess(response) {
            this.$set(this.localActiveData, 'src', response.data)
            this.localActiveData.src = response.data
            this.closeUploadProgressHandle()
        }
    }
}
</script>

<style scoped>

</style>
